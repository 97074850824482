<template>
  <div>
    <div class="bank" @click="goLink(link)">
      <img class="img" :src="url" >
<!--       <a :href="link" target="_blank" class="url">{{link}}</a>
 -->  </div>
  </div>
</template>
<script>
export default {
 props:{
   url: String,
   link: String
 },
 methods: {
   goLink(link) {
     window.open(link)
   }
 }
}
</script>
<style lang="scss" scoped>
.bank {
          margin-top: 10px;
          border-radius: 10px;
          border: rgb(232, 232, 232) 1px solid;
          text-align: center;
          cursor: pointer;
          .img {
            width: 90%;
            margin: 5px;
            
            // height: 120px;
            // background-color: deeppink;
          }
          .url {
            display: block;
            width: 90%;
            margin: 0 auto;
            font-size: 16px;
            padding-bottom: 10px;
          }
          a{
            text-align: center;
          }
        }
</style>
