export default {
    // 商务礼仪
    swly: `
<p style=";white-space: normal;">
    <span style=""><span>商务礼仪最基本作用是减灾效应：少出洋相、少丢人、少破坏人际关系</span>,遇到不知事情，最稳妥方式是紧跟或模仿，以静制动。</span>
</p>
<p style="text-align: center;">
<img  src="https://www.yjyculture.com/commonimg/base/etiquetteTraining/1_1.png" />
</p>
<p style=";text-align: justify;white-space: normal">
    <span style=""><span style="">商务礼仪概述：</span> <span style="">在商务活动中，为了体现相互尊重，需要通过一些行为准则去约束人们在商务活动中的方方面面，这其中包括仪表礼仪，言谈举止，书信来往，电话沟通等技巧，从商务活动的场合又可以分为办公礼仪，宴会礼仪，迎宾礼仪等。</span></span>
</p>
<p style=";text-align: center;white-space: normal">
    <img src="https://www.yjyculture.com/commonimg/base/etiquetteTraining/1_2.png" /><span style="">&nbsp;</span>
</p>
<p style=";text-align: justify;white-space: normal">
    <span style="">商务礼仪与公共关系：尊重为本。商务交往中，自尊很重要，尊重别人更重要。</span>
</p>
<p style=";text-align: justify;white-space: normal">
    <span style=""><span style="">商务礼仪的</span>3A原则【基本原则】，是商务礼仪的立足资本，是美国学者布吉尼教授提出来的。3A原则实际上是强调在商务交往中处理人际关系最重要的需要注意的问题。</span>
</p>
<p style=";text-align: justify;white-space: normal">
    <span style="">1、第一原则接受</span><span style="">(</span><span style="">Accept</span><span style="">)</span><span style=""><span style="">对方。告诉我们在商务交往中不能只见到物而忘掉人。强调人的重要性，要注意人际关系的处理，不然就会影响商务交往的效果。</span>3A原则是讲对交往对象尊重的三大途径。第一接受对方，</span><span style="">宽</span><span style="">以</span><span style="">待人</span><span style="">，不要难为对方，让对方难看，客人永远是对的。比如在交谈时有三不准：不要打断别人</span><span style="">;</span><span style="">不要轻易的补充对方</span><span style="">;</span><span style="">不要随意更正对方，因为事物的答案有时不止一个。一般而言，不是原则性的话，要尽量接受对方。</span>
</p>
<p style=";text-align: justify;white-space: normal">
    <span style="">2、第二原则重视</span><span style="">(</span><span style="">Attention</span><span style="">)</span><span style="">对方。欣赏对方。要看到对方的优点，不要专找对方的缺点，更不能当众指正。重视对方的技巧：一是在人际交往中要善于使用尊称，称行政职务，技术职称，凡尊称</span><span style="">;</span><span style="">二是记住对方，比如接过名片要看，记不住时，千万不可张冠李戴。</span>
</p>
<p style=";text-align: justify;white-space: normal">
    <span style="">3、第三原则赞美</span><span style="">(</span><span style="">Admire</span><span style="">)</span><span style="">对方。对交往对象应该给予的一种赞美和肯定，懂得欣赏别人的人实际上是在欣赏自己。赞美对方也有技巧：一是实事求是，不能太夸张，二是适应对方，要夸到点子上。</span>
</p>
<p style=";text-align: center;white-space: normal">
    <img  src="https://www.yjyculture.com/commonimg/base/etiquetteTraining/1_3.png" /><span style="">&nbsp;</span>
</p>
<p style=";text-align: justify;white-space: normal">
    <span style="">商务礼仪使用的目的：</span>
</p>
<p style=";text-align: justify;white-space: normal">
    <span style="">第一、提升个人的素养，</span><span style="">比尔盖茨</span><span style="">讲企业竞争，是员工素质的竞争进而到企业，就是企业形象的竞争，教养体现细节，细节展示素质。</span>
</p>
<p style=";text-align: justify;white-space: normal">
    <span style="">第二、方便我们的个人交往应酬。我们在商业交往中会遇到不同的人，对不同的人如何进行交往这是要讲究艺术的，比如夸奖人也要讲究艺术，不然的话即使是夸人也会让人感到不舒服。</span>
</p>
<p style=";text-align: justify;white-space: normal">
    <span style="">第三、有助于维护企业形象。在商务交往中个人代表整体，个人形象代表企业形象，个人的所作所为，就是本企业的典型活体广告。一举一动、一言一行，此时无声胜有声。</span>
</p>
<p style=";text-align: justify;white-space: normal">
    <span style="">商务礼仪与公共关系：尊重为本。商务交往中，自尊很重要，尊重别人更重要。</span>
</p>
<p style=";text-align: justify;white-space: normal">
    <span style="">&nbsp;</span>
</p>
<p>
    <br/>
</p>`,
// 接待礼仪
jdly: `
<p style=";text-align: justify;white-space: normal">
    <span style="">接待，吹响了商务交往的序曲。礼貌接待，以礼待客，专业术语叫礼宾。是做好接待工作的基本前提。</span>
</p>
<p style=";text-align: center;white-space: normal">
    <img  src="https://www.yjyculture.com/commonimg/base/etiquetteTraining/2_1.png" /><span style="">&nbsp;</span>
</p>
<p style=";text-align: justify;white-space: normal">
    <span style="">&nbsp;</span>
</p>
<p style=";text-align: justify;white-space: normal">
    <span style="">接待礼仪有哪些注意事项呢？</span>
</p>
<p style="margin: 0 0 10px;white-space: normal;text-indent: 28px;line-height: 16px;">
    <span style="color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">一是确定迎送规格。通常遵循身份相当的原则，即主要迎送人与主宾身份相当，当不可能完全对等时，可灵活变通，由职位相当的人或由副职出面。其他迎送人员不宜过多。</span></span>
</p>
<p style="margin: 0 0 10px;white-space: normal;text-indent: 28px;line-height: 16px;">
    <span style="color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">二是掌握到达和离开的时间。准确掌握来宾到达和离开的时间，及早通知全体迎送人员和有关单位。如有变化，应及时通知有关人员。迎接人员应提前到达迎接地点，不能太早，更不能太迟，甚至迟到。送行人员则应在客人离开之前到达送行地点。</span></span>
</p>
<p style="margin: 0 0 10px;white-space: normal;text-indent: 28px;line-height: 16px;">
    <span style="color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">三是适时献上鲜花。迎接普通来宾，一般不需要献花。迎接十分重要的来宾，可以献花。所献之花要用鲜花，并保持花束整洁、鲜艳。忌用菊花、杜鹃花、石竹花、黄色花朵。献花的时间，通常由儿童或女青年在参加迎送的主要领导与主宾握手之后将花献上。可以只献给主宾，也可向所有来宾分别献花。</span></span>
</p>
<p style="margin: 0 0 10px;white-space: normal;text-indent: 28px;line-height: 16px;">
    <span style="color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">四是不同的客人按不同的方式迎接。对大批客人的迎接，可事先准备特定的标志，让客人从远处即可看清；对首次前来，又不认识的客人，应主动打听，并自我介绍；而对比较熟悉的客人，则不必介绍，仅向前握手，互致问候即可。</span></span>
</p>
<p style="margin: 0 0 10px;white-space: normal;text-indent: 28px;line-height: 16px;">
    <span style="color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">五是留下一定时间。客人抵达住处后，不要马上安排活动，要给对方留下一定的时间，然后再安排活动。</span></span>
</p>
<p style="margin: 0 0 10px;white-space: normal;text-indent: 28px;line-height: 16px;;text-align: center;;">
    <img  src="https://www.yjyculture.com/commonimg/base/etiquetteTraining/2_2.png"/><span style="color: rgb(51, 51, 51);letter-spacing: 0;">&nbsp;</span>
</p>
<p style=";text-align: justify;white-space: normal">
    <span style="">接待礼仪又分为以下几个方面：</span>
</p>
<p style="margin: 0 0 10px;white-space: normal;text-indent: 28px;line-height: 16px;">
    <span style="color: rgb(51, 51, 51);letter-spacing: 0;">(一)当面接待</span><span style="color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">礼仪</span></span>
</p>
<p style="margin: 0 0 10px;white-space: normal;text-indent: 28px;line-height: 16px;">
    <span style="color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">上级来访，接待要周到。对领导交待的工作要认真听、记；领导了解情况，要如实回答；如领导是来慰问，要表示诚挚的谢意。领导告辞时，要起身相送，互道</span>&quot;再见&quot;。</span>
</p>
<p style="margin: 0 0 10px;white-space: normal;text-indent: 28px;line-height: 16px;">
    <span style="color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">下级来访，接待要亲切热情。除遵照一般来客礼节接待外，对反映的问题要认真听取，一时解答不了的要客气地回复。来访结束时，要起身相送。</span></span>
</p>
<p style="margin: 0 0 10px;white-space: normal;text-indent: 28px;line-height: 16px;">
    <span style="color: rgb(51, 51, 51);letter-spacing: 0;">(二)电话接待礼仪</span>
</p>
<p style="margin: 0 0 10px;white-space: normal;text-indent: 28px;line-height: 16px;">
    <span style="color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">电话接待的基本要求：</span></span>
</p>
<p style="margin: 0 0 10px;white-space: normal;text-indent: 28px;line-height: 16px;">
    <span style="color: rgb(51, 51, 51);letter-spacing: 0;">(1)电话铃一响，拿起电话机首先自报家门，然后再询问对方来电的意图等。</span>
</p>
<p style="margin: 0 0 10px;white-space: normal;text-indent: 28px;line-height: 16px;">
    <span style="color: rgb(51, 51, 51);letter-spacing: 0;">(2)电话交流要认真理解对方意图，并对对方的谈话作必要的重复和附和，以示对对方的积极反馈。</span>
</p>
<p style="margin: 0 0 10px;white-space: normal;text-indent: 28px;line-height: 16px;">
    <span style="color: rgb(51, 51, 51);letter-spacing: 0;">(3)应备有电话记录本，重要的电话应做记录。</span>
</p>
<p style="margin: 0 0 10px;white-space: normal;text-indent: 28px;line-height: 16px;">
    <span style="color: rgb(51, 51, 51);letter-spacing: 0;">(4)电话内容讲完，应等对方结束谈话再以&quot;再见&quot;为结束语。对方放下话筒之后，自己再轻轻放下，以示对对方的尊敬。</span>
</p>
<p style="margin: 0 0 10px;white-space: normal;text-indent: 28px;line-height: 16px;">
    <span style="color: rgb(51, 51, 51);letter-spacing: 0;">(三)引见时的礼仪</span>
</p>
<p style="margin: 0 0 10px;white-space: normal;text-indent: 28px;line-height: 16px;">
    <span style="color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">到办公室来的客人与领导见面，通常由办公室的工作人员引见、介绍。在引导客人去领导办公室的路途中，工作人员要走在客人左前方数步远的位置，忌把背影留给客人。在陪同客人去见领导的这段时间内，不要只顾闷头走路，可以随机讲一些得体的话或介绍一下本单位的大概情况。</span></span>
</p>
<p style="margin: 0 0 10px;white-space: normal;text-indent: 28px;line-height: 16px;">
    <span style="color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">在进领导办公室之前，要先轻轻叩门，得到允许后方可进入，切不可冒然闯入，叩门时应用手指关节轻叩，不可用力拍打。进入房间后，应先向领导点头致意，再把客人介绍给领导，介绍时要注意措词，应用手示意，但不可用手指指着对方。介绍的顺序一般是把身份低、年纪轻的介绍给身份高、年纪大的；把</span></span><a href="https://baike.baidu.com/item/%E7%94%B7%E5%90%8C%E5%BF%97"><span style="color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">男同志</span></span></a><span style="color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">介绍给女同志；如果有好几位客人同时来访，就要按照职务的高低，按顺序介绍。介绍完毕</span></span><a href="https://baike.baidu.com/item/%E8%B5%B0%E5%87%BA%E6%88%BF%E9%97%B4"><span style="color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">走出房间</span></span></a><span style="color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">时应自然、大方，保持较好的行姿，出门后应回身轻轻把门带上。</span></span>
</p>
<p style="margin: 0 0 10px;white-space: normal;text-indent: 28px;line-height: 16px;">
    <span style="color: rgb(51, 51, 51);letter-spacing: 0;">(四)乘车行路</span>
</p>
<p style="margin: 0 0 10px;white-space: normal;text-indent: 28px;line-height: 16px;">
    <span style="color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">办公室的工作人员在陪同领导及客人乘车外出时要注意：</span></span>
</p>
<p style="margin: 0 0 10px;white-space: normal;text-indent: 28px;line-height: 16px;">
    <span style="color: rgb(51, 51, 51);letter-spacing: 0;">(1)让领导和客人先上，自己后上。</span>
</p>
<p style="margin: 0 0 10px;white-space: normal;text-indent: 28px;line-height: 16px;">
    <span style="color: rgb(51, 51, 51);letter-spacing: 0;">(2)要主动打开车门，并以手示意，待领导和客人坐稳后再关门，一般车的右门为上、为先、为尊，所以应先开右门，关门时切忌用力过猛。</span>
</p>
<p style="margin: 0 0 10px;white-space: normal;text-indent: 28px;line-height: 16px;">
    <span style="color: rgb(51, 51, 51);letter-spacing: 0;">(3)在乘车的座位上很讲究，我国一般是右为上，左为下。陪同客人时，要坐在客人的左边。</span>
</p>
<p style="margin: 0 0 10px;white-space: normal;text-indent: 28px;line-height: 16px;">
    <span style="color: rgb(51, 51, 51);letter-spacing: 0;">(五)递物与接物</span>
</p>
<p style="margin: 0 0 10px;white-space: normal;text-indent: 28px;line-height: 16px;">
    <span style="color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">递物与接物是生活中常用的一种举止。</span></span>
</p>
<p style="margin: 0 0 10px;white-space: normal;text-indent: 28px;line-height: 16px;">
    <span style="color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">礼仪的基本要求就是尊重他人。因此，递物时须用双手，表示对对方的尊重。例如递交名片：双方经介绍相识后，常要互相交换名片。递交名片时，应用双手恭敬地递上，且名片的正面应对着对方。在接受他人名片时也应恭敬地用双手捧接。接过名片后要仔细看一遍或有意识地读一下名片的内容，不可接过名片后看都不看就塞入口袋，或到处乱扔。</span></span>
</p>
<p style="margin: 0 0 10px;white-space: normal;text-indent: 28px;line-height: 16px;">
    <span style="color: rgb(51, 51, 51);letter-spacing: 0;">(六)会议礼仪 会议的通用礼仪，主要有以下几点：</span>
</p>
<p style="margin: 0 0 10px;white-space: normal;text-indent: 28px;line-height: 16px;">
    <span style="color: rgb(51, 51, 51);letter-spacing: 0;">(1)发放会议通知时应阐明目的。</span>
</p>
<p style="margin: 0 0 10px;white-space: normal;text-indent: 28px;line-height: 16px;">
    <span style="color: rgb(51, 51, 51);letter-spacing: 0;">(2)拟发好会议通知。会议通知必须写明开会时间、地点、会议主题及参加者等内容。要提前一定的时间发通知，以便使参加者有所准备。</span>
</p>
<p style="margin: 0 0 10px;white-space: normal;text-indent: 28px;line-height: 16px;">
    <span style="color: rgb(51, 51, 51);letter-spacing: 0;">(3)安排好会场。会场的大小，要根据会议内容和参加者的多少而定。如果会场不易寻找，应在会场附近安设路标以作指点。</span>
</p>
<p style="margin: 0 0 10px;white-space: normal;text-indent: 28px;line-height: 16px;">
    <span style="color: rgb(51, 51, 51);letter-spacing: 0;">(4)开会的时间宜紧凑。开&quot;马拉松&quot;式的长会，往往上面在作长篇报告，下面却在交头接耳呵欠不断。所以，&quot;短小精悍&quot;，有效地利用时间，讨论实质性的问题，应视为开会礼仪中十分重要的一条。</span>
</p>
<p style="margin: 0 0 10px;white-space: normal;text-indent: 28px;line-height: 16px;">
    <span style="color: rgb(51, 51, 51);letter-spacing: 0;">(5)迎送礼仪。凡是一些大型或中型会议，对会议参加者要认真做好迎送工作。一般应在会前组成一个会务组，专门处理有关问题。</span>
</p>
<p style=";text-align: justify;white-space: normal">
    <span style="">&nbsp;</span>
</p>`,
    // 服务礼仪
    fwly: `
    <p style=";text-align: justify;white-space: normal">
        <span style="">&nbsp;</span>
    </p>
    <p style=";text-align: justify;white-space: normal">
        <span style="">服务礼仪是指服务人员在工作岗位上，通过言谈、举止、行为等，对客户表示尊重和友好的行为规范和惯例。服务礼仪的内容相当广泛，涉及服务人员在为服务对象服务过程的方方面面，但就其表现的具体形式类型而言，则主要有服务人员的仪容规范、仪态规范、服饰规范、语言规范和岗位规范等。其中的每个方面，都包含了一系列的具体做法，从而构成了服务礼仪完整的内容体系。</span>
    </p>
    <p style=";text-align: center;white-space: normal">
        <img src="https://www.yjyculture.com/commonimg/base/etiquetteTraining/3_1.png" /><span style="">&nbsp;</span>
    </p>
    <p style=";text-align: justify;white-space: normal">
        <span style="">服务礼仪是一种实用性非常强的礼仪，同其他礼仪门类相比，具有明显的规范性和可操作性的特点。它在仪容、仪态、</span><span style="">服饰</span><span style="">、语言和岗位操作等各个具体问题上，对服务人员到底应该做什么，什么时候做，怎么做，达到什么样的标准；不应该做什么，有哪些特殊要求等都有周详而明确的规定。这些规定构成了服务人员在工作岗位上的行为规范，具有极强的可操作性。</span>
    </p>
    <p style=";text-align: center;white-space: normal">
        <img src="https://www.yjyculture.com/commonimg/base/etiquetteTraining/3_2.png" /><span style="">&nbsp;</span>
    </p>
    <p style=";text-align: justify;white-space: normal">
        <strong><span style="">服务礼仪的准则</span></strong>
    </p>
    <p style=";text-align: justify;white-space: normal">
        <span style="">1、</span><span style="">职业道德</span>
    </p>
    <p style=";text-align: justify;white-space: normal">
        <span style="">　道德是社会学意义上的一个基本概念。不同的社会制度，不同的社会阶层都有不同的道德标准。所谓道德，就是由一定社会的经济基础所决定，以善恶为评价标准，以法律为保障并依靠社会舆论和人们内心信念来维系的、调整人与人、人与社会及社会各成员之间关系的行为规范的总和。</span>
    </p>
    <p style=";text-align: justify;white-space: normal">
        <span style="">职业道德是一般道德在职业行为中的反映，是社会分工的产物。所谓职业道德，就是人们在进行职业活动过程中，一切符合职业要求的心理意识、行为准则和行为规范的总和。它是一种内在的、非强制性的约束机制。是用来调整职业个人、职业主体和社会成员之间关系的行为准则和行为规范。</span>
    </p>
    <p style=";text-align: justify;white-space: normal">
        <span style="">2<span style="">职业</span></span><span style="">素</span><span style="">养</span>
    </p>
    <p style=";text-align: justify;white-space: normal">
        <span style="">职业素养是个很大的概念，专业是第一位的，但是除了专业，敬业和道德是必备的，体现到职场上的就是职业素养；体现在生活中的就是个人素质或者道德修养。</span>
    </p>
    <p style=";text-align: justify;white-space: normal">
        <span style=""><span style="">职业素养是指职业内在的规范和要求</span>,<span style="">是在职业过程中表现出来的综合品质，包含职业道德</span></span><span style="">、</span><span style="">职业技能职业行为</span><span style="">、</span><span style="">职业作风和职业意识等方面</span><span style="">。</span>
    </p>
    <p style=";text-align: justify;white-space: normal">
        <span style=""><span style="">很多企业界人士认为，职业素养至少包含两个重要因素：敬业精神及合作的态度。敬业精神就是在工作中要将自己作为公司的一部分，不管做什么工作一定要做到最好，发挥出实力，对于一些细小的错误一定要及时地更正，敬业不仅仅是吃苦耐劳，更重要的是</span>“用心”去做好公司分配给的每一份工作。态度是职业素养的核心，好的态度比如负责的、积极的，自信的，建设性的，欣赏的，乐于助人等态度是决定成败的关键因素。</span>
    </p>
    <p style=";text-align: justify;white-space: normal">
        <span style=""><span style="">职业素养是人类在社会活动中需要遵守的行为规范。个体行为的总合构成了自身的职业素养，职业素养是内涵，个体行为是外在表象。所以，职业素养是一个人职业生涯成败的关键因素。职业素养量化而成</span>“职商。”英文<span style="">career quotient </span><span style="">简称</span><span style="">CQ</span><span style="">。也可以说一生成败看职商</span></span><span style="">。</span>
    </p>
    <p style=";text-align: justify;white-space: normal">
        <span style="">3、</span><span style="">角色定位</span>
    </p>
    <p style=";text-align: justify;white-space: normal">
        <span style=""><span style="">角色定位</span>(Role Definition):HR<span style="">管理的最高境界在一定的系统环境下</span><span style="">(</span><span style="">包括时间</span><span style="">)</span><span style="">，在一个组合中拥有相对的不可代替性的定位，就是角色定位。 “角色”不一定是一个人，可以是一个群体。当今众多优秀的企业中，未必所有的员工都按照角色定位规划和发展的，但是，它们之所以成为常青树和卓越公司，都具有一个共同的人力资本特征：至少其高层人员的组合必然符合角色定位的原则。如</span><span style="">GE</span><span style="">经历了很多位</span><span style="">CE0</span><span style="">的领导，未必每个人都如韦尔奇，但公司不断发展，因为每个历史阶段，其</span><span style="">CE0</span><span style="">都是按角色定位去挑选的。角色定位在当代西方政体管理中体现得淋漓尽致。其发展趋势是一条向上的射线或波动向上的曲线。</span></span>
    </p>
    <p style=";text-align: justify;white-space: normal">
        <span style="">4、</span><span style="">理解沟通</span>
    </p>
    <p style=";text-align: justify;white-space: normal">
        <span style=""><span style="">沟通是人与人之间、人与群体之间思想与感情的传递和反馈的过程，以求思想达成一致和感情的通畅。</span> <span style="">沟通是为了一个设定的目标，把信息、思想和情感在个人或群体间传递，并且达成共同协议的过程。它三大要素即：</span> 1<span style="">。要有一个明确的目标；②达成共同的协议；③沟通信息、思想和情感。</span></span>
    </p>
    <p style=";text-align: justify;text-indent: 0;white-space: normal">
        <span style="">5、</span><span style="">敬重他人</span>
    </p>
    <p style=";text-align: justify;white-space: normal">
        <span style=""><span style="">「尊重」表达的是某一方（群体</span>or<span style="">个体）面对另一方的关联状态，是人与人（及其他）相互关系中重要的衡量要素，对人的行为与态度起到引导作用，懂得尊重并学会尊重是我们在现代社会环境下所需掌握的重要素养之一。 &nbsp;</span></span>
    </p>
    <p style=";text-align: justify;white-space: normal">
        <span style="">尊重是相互的，只有我们先尊重别人，才能得到别人的尊重。每个体都是平等的，不管对方是谁，不管他生活得有多落魄，身体有怎样的缺陷，都不能成为我们嘲笑别人的理由，维护别人的自尊既是一种维护自己自尊的做法，也是一种有素养的表现。</span>
    </p>
    <p style=";text-align: justify;text-indent: 0;white-space: normal">
        <span style="">6、</span><span style="">首因效应</span>
    </p>
    <p style=";text-align: justify;white-space: normal">
        <span style=""><span style="">首因效应，是指个体在社会认知过程中，通过</span>“第一印象”最先输入的信息对客体以后的认知产生的显著的影响作用，它是由第一印象<span style="">(</span><span style="">首次印象</span><span style="">)</span><span style="">所引起的一种心理倾向，许多人习惯称之为“第一感”。</span></span>
    </p>
    <p style=";text-align: justify;white-space: normal">
        <span style="">首因效应本质上是一种优先效应，当不同的信息结合在一起的时候，人们总是倾向于重视前面的信息。即使人们同样重视了后面的信息，也会认为后面的信息是非本质的、偶然的，人们习惯于按照前面的信息解释后面的信息，即使后面的信息与前面的信息不一致，也会屈从于前面的信息，以形成整体一致的印象。　　</span>
    </p>
    <p style=";text-align: justify;text-indent: 0;white-space: normal">
        <span style="">7、</span><span style="">亲和效应</span>
    </p>
    <p style=";text-align: justify;white-space: normal">
        <span style=""><span style="">人们在人际交往中往往存在一种倾向，即对于自己较为亲近的对象，比如，有共同的血缘、姻缘、地缘、学缘或者业缘关系，有相似的志向、兴趣、爱好、利益，或者是彼此共处于同一团体或同一组织的人，会更加乐于接近。我们通常把这些较为亲近的对象称为</span>“自己人”。</span>
    </p>
    <p style=";text-align: justify;white-space: normal">
        <span style=""><span style="">一个人如果想要让身边的同事、朋友把自己当成</span>“自己人”，除了本无法改变的血缘外，就要懂得与他人的相处之道。主动让别人对自己产生好感，认同并喜欢自己，就需要拿出“亲和力”。只有这样的人才会把周围的人吸引到自己身边来，才会让别人认同自己，把我们当成“自己人”。</span>
    </p>
    <p style=";text-align: center;white-space: normal">
        <img  src="https://www.yjyculture.com/commonimg/base/etiquetteTraining/3_3.png" /><span style="">&nbsp;</span>
    </p>
    <p style=";text-align: justify;white-space: normal">
        <strong><span style="">服务礼仪的作用</span></strong>
    </p>
    <p style=";text-align: justify;white-space: normal">
        <span style="">其一，有助于提高服务人员的自身素质；</span>
    </p>
    <p style=";text-align: justify;white-space: normal">
        <span style="">其二，有助于更好地对服务对象表示尊重；</span>
    </p>
    <p style=";text-align: justify;white-space: normal">
        <span style="">其三，有助于进一步提高服务水平和服务质量；</span>
    </p>
    <p style=";text-align: justify;white-space: normal">
        <span style="">其四，有助于塑造并维护服务企业的整体形象；</span>
    </p>
    <p style=";text-align: justify;white-space: normal">
        <span style="">其五，有助于服务企业创造出更好经济效益和社会效益。</span>
    </p>
    <p style=";text-align: justify;white-space: normal">
        <span style="letter-spacing: 0;">&nbsp;</span>
    </p>`,
    // 物业礼仪
    wyly: `<p style=";text-align: justify;white-space: normal">
    <span style=""><span style="">物业管理行业属于服务业，要将</span>“客户至上。服务第一”作为服务宗旨，为客户服务时要讲究礼貌、礼节。作为一名物业人，一言一行都代表着物业企业的形象，对客户能否进行优质服务直接影响到企业声誉，即使有再好的规章制度，如果对客户服务不周，态度不佳，也会导致公司的信誉下降。业绩不振。总之，讲礼仪是物业公司对每位员工的基本要求，也是体现物业公司服务宗旨的具体表现。本片根据物业公司在实际工作中的礼仪行为规范，介绍了接待服务礼仪、秩序维护礼仪、保洁服务礼仪、维修服务礼仪等物业服务礼仪。</span>
</p>
<p style=";text-align: center;white-space: normal">
    <img src="https://www.yjyculture.com/commonimg/base/etiquetteTraining/4_1.png" /><span style="">&nbsp;</span>
</p>
<p style=";text-align: justify;white-space: normal">
    <span style=""><span style="">作为物业管理人员经常会去业主家中走访处理问题。既讲究实在，又讲究艺术，才能够</span>dao<span style="">取得最佳效果。以下几点希望对你有帮助：</span></span>
</p>
<p style=";text-align: justify;white-space: normal">
    <span style="">　　1<span style="">、见面问候时最好点名道姓。迈进业主家门，你的第一句话可能是：“你好，见到你很高兴。”但这却不如说：“王先生，你好，见到你很高兴。”据测，后者比前者要</span></span><span style="">热情得</span><span style=""><span style="">多。</span> 2<span style="">、若业主没请你坐下，你最好站着。坐下后不应直接掏烟给业主，如业主请你抽烟，你应说：“谢谢。”请记住，切莫把烟灰和火柴头弄到地板上，那是很不得体的。</span></span>
</p>
<p style=";text-align: justify;white-space: normal">
    <span style="">　　3<span style="">、不要急于出示随身带的资料、函等文件。只有在交谈中提及了，并已引起对方兴趣时，才是向业主表达的最好时机。另外，你得事先准备好，针对你去业主家要解决的问题</span><span style="">,</span><span style="">预先要考虑业主可能会提出的问题，在业主提出问题事，应给予</span></span><span style="">详细的解释</span><span style=""><span style="">或说明。</span> 4<span style="">、主动开始谈话，珍惜时间。尽管对方已经了解到你的一些情况和来访目的，你仍有必要主动开口。你可再次对某些问题进行强调和说明。</span></span>
</p>
<p style=";text-align: justify;white-space: normal">
    <span style="">　　5<span style="">、时刻保持相应的热情。在谈话时，你若对某一问题没有倾注足够的热情，那么，业主会马上失去谈这个问题的兴趣。</span></span>
</p>
<p style=";text-align: justify;white-space: normal">
    <span style="">　　6<span style="">、当业主因为某些问题，出现愤怒并难以抑制时，应提早结束此次走访。愤怒会使你失去理解他人和控制自己的客观尺度。它不仅无助于</span></span><span style="">问题的解决</span><span style=""><span style="">，反而会把事情搞得更糟。</span> 7<span style="">、学会听的艺术。听有两个要求，首先要给业主留出讲话的时间，其次要</span><span style="">&quot;</span><span style="">听话听音</span><span style="">&quot;</span><span style="">。如业主首先讲话，你不可打断他。应做好准备，以便利用恰当的时机给其以响应，鼓励他讲下去。</span></span>
</p>
<p style=";text-align: justify;white-space: normal">
    <span style=""><span style="">　　不能够认真聆听别人谈话的人，也就不能够</span>“听话听音”，更不能机警、巧妙地回答对方的问题。记住：不论是社交场合，还是在工作中，善于听乃是一个人应有的素养。 <span style="">8</span><span style="">、避免不良的动作和姿态。玩弄手中的小东西，用手不时地理头发、</span></span><span style="">搅舌头</span><span style="">，清牙齿，掏耳朵，盯视指甲、天花板或对方身后的字画等，这些动作都有失风度。</span>
</p>
<p style=";text-align: justify;white-space: normal">
    <span style="">　　9</span><span style="">、也</span><span style=""><span style="">不应忘记自己的身份去故作姿态，卖弄亲近：</span>“咱俩无话不谈，要是对别人，我才不提这个呢</span><span style="">!</span><span style="">”俚话和粗话更应避免。</span>
</p>
<p style=";text-align: justify;white-space: normal">
    <span style="">　　10<span style="">、要诚实、坦率、有节制。若在一件小事上</span></span><span style="">做假</span><span style="">，</span><span style="">很可能使</span><span style=""><span style="">你的整个努力付诸东流。对方一旦怀疑你不诚实，你的各种不同凡响的作为都将黯然失色。谁都不是十全十美的完人，因此，你可以坦率地谈起或承认自己的缺点或过失。在评论第三者时不应失去体量他人的气度，无节制地使用尖刻语言只会让人疑心：</span>“谁知哪一天，他也许会在背后这样说我呢。”</span>
</p>
<p style=";text-align: justify;white-space: normal">
    <span style="">　　11<span style="">、要善于“理乱麻”，学会清楚地表达。善于表达使人</span></span><span style="">终生受益</span><span style=""><span style="">。讲话不会概括的人，常常引起人们的反感：叙事没有重点，思维头绪混乱的人，常常迫使人们尽量回避他。一般来说，你若从没有担心过别人会对你的话产生反感，就意味着你已引起他人的反感了。</span> 12<span style="">、注意自己说话的语气和语调。说话要保持清晰，喉音、鼻音不宜太重，语速平缓，语调老成、平淡，充满朝气的语调会使你显得年轻。此功重在平时留心多练。</span></span>
</p>
<p style=";text-align: justify;white-space: normal">
    <span style="">　　13<span style="">、注意衣着和发式。第一次见面就给人一种不整洁的印象，往往会给你的自我表白投下阴影。</span></span>
</p>
<p style=";text-align: justify;white-space: normal">
    <span style="">　　14<span style="">、过度的关心和说教应该避免，要表现出诚意和合作精神。</span></span>
</p>
<p style=";text-align: justify;white-space: normal">
    <span style="">　　结束出门时，不要忘记带走你的帽子、手套、公事包等东西。告别语应适当简练，克制自己不要在临出门时又引出新的话题。</span>
</p>
<p>
    <br/>
</p>`,
// 医疗礼仪
ylly: `<p style=";text-align: justify;white-space: normal">
<span style="">医疗服务培训从医疗服务角度提高医院整体水平，改变医院管理者过分依赖高精尖医疗设备，轻视管理技巧，忽略了人才培养、服务观念、服务流程的旧思想。</span><span style="">医疗礼仪注意以下这些方面：</span>
</p>
<p style=";text-align: center;white-space: normal">
<img  src="https://www.yjyculture.com/commonimg/base/etiquetteTraining/5_1.png" /><span style="">&nbsp;</span>
</p>
<p style=";text-align: justify;white-space: normal">
<span style="">门诊服务台人性化服务</span>
</p>
<p style=";text-align: justify;white-space: normal">
<span style="">门诊是医院的重要组成部分，是医疗工作的第一线，是面向社会的主要窗口，门诊服务台的工作不仅仅是为患者提示诊疗过程，更重要的是拉近了医患之间的距离，明显改善</span><span style="">医</span><span style="">患关系，提升了护理优质人性化服务。</span>
</p>
<p style=";text-align: justify;white-space: normal">
<span style="">规范着装，强调礼仪服务台</span>
</p>
<p style=";text-align: justify;white-space: normal">
<span style="">护士上班时仪表端庄、态度热情诚恳、语言温和亲切、举止文明礼貌，规范着装，淡妆上岗，时刻保持微笑，统一发髻，统一着装。减轻患者对医院和护士的恐惧感和陌生感。医院对护士在坐姿、站姿、走姿、治疗等各方面都要进行礼仪培训。</span>
</p>
<p style=";text-align: justify;white-space: normal">
<span style="">提高护理人员素质，规范服务行为</span>
</p>
<p style=";text-align: justify;white-space: normal">
<span style="">门诊的护理工作必须以人性化服务为手段，针对患者不同文化、生理、心理、精神等需要，提供适合于患者</span><span style="">的最佳</span><span style="">护理。认真学习、强化法律意识，增强职业的危机意识。</span>
</p>
<p style=";text-align: justify;white-space: normal">
<span style="">护士热情、诚恳、爱心服务态度既减轻了患者的不良情绪，也容易赢得患者的信赖，故提高护理人员的业务水平是关键。</span>
</p>
<p style=";text-align: center;white-space: normal">
<img  src="https://www.yjyculture.com/commonimg/base/etiquetteTraining/5_2.png" /><span style="">&nbsp;</span>
</p>
<p style=";text-align: justify;white-space: normal">
<span style="">注重细节，优质服务</span>
</p>
<p style=";text-align: justify;white-space: normal">
<span style="">患者来医院，环境的陌生和对自己身体情况的担忧，大多会产生焦虑、紧张的情绪，这时应该主动上前迎接，热情、诚恳、准确地回答病人的问题，及时判断患者病情轻重缓急，指引患者选择相关科室诊治。</span>
</p>
<p style=";text-align: justify;white-space: normal">
<span style="">往往一个微笑、一句温暖的语言、一个得体的抚摸可起到药物无法替代的作用，让病人在接受诊疗的第一环节就消除了陌生感，增加信任度，减轻心理压力。使病人在医院实行全程优质护理的第一站就得到良好的照顾。</span>
</p>
<p style=";text-align: justify;white-space: normal">
<span style="">创造温馨舒适的人性化诊疗</span>
</p>
<p style=";text-align: justify;white-space: normal">
<span style="">环境诊疗环境的优劣直接影响患者诊疗效果的优劣。绿色、整洁、令人心情舒畅的就医环境有利于病人产生稳定、愉快的情绪。因此除了精湛的诊疗技术外，更应创造一个便捷、舒适的就医环境。</span>
</p>
<p style=";text-align: center;white-space: normal">
<img src="https://www.yjyculture.com/commonimg/base/etiquetteTraining/5_3.png"/><span style="">&nbsp;</span>
</p>
<p style=";text-align: justify;white-space: normal">
<span style="">建立有效的沟通系统</span>
</p>
<p style=";text-align: justify;white-space: normal">
<span style="">通过各种</span><span style="">渠道建立有效的沟通，</span><span style="">诸如简单有效的急救措施、常见病、多发病、季节性传染病的防治知识，用药注意事项、康复知识及饮食、作息、体育锻炼、情绪调节、戒烟戒酒、养成健康的生活方式等信息，营造温馨的就医环境。</span>
</p>
<p style=";text-align: justify;white-space: normal">
<span style="">打造和谐医患关系</span>
</p>
<p style=";text-align: justify;white-space: normal">
<span style="">医患纠纷造成了医患关系紧张，并且达到了前所未有的程度。探讨医患纠纷发生的原因，进而有针对性地采取防范措施，特别是医患双方容易忽视的伦理要求是很有必要的。它可以减少医患纠纷，进而促进医患关系的和谐。</span>
</p>
<p style=";text-align: justify;white-space: normal">
<span style="">通过良好有效的沟通，医务工作者应该掌握较高的沟通技巧，不断提升职业素养和服务的意识，互换角度，为患者考虑，打造和谐的医患关系。</span>
</p>
<p>
<br/>
</p>`,
// 医美礼仪
ymly: `<p style=";text-align: justify;white-space: normal">
<span style="">现代社会，有很多人为了更完美选择整形医美，随之而来的是整形医院的诞生，好的医美礼仪不仅可以使顾客有好的医美体验，更能使客户数量增加，客源不断，医美接待人员</span><span style=""><span style="">作为</span></span><span style="">医院</span><span style=""><span style="">的形象，每个接待细节都会给新顾客留下印象，工作不仅于记录顾客的档案，接待过程也是很</span></span><span style="">关键</span><span style=""><span style="">，一个好的开端才有一个好的结果，那么</span></span><span style="">医院</span><span style=""><span style="">的接待要注意什么呢？</span></span>
</p>
<p style=";text-align: center;white-space: normal">
<img  src="https://www.yjyculture.com/commonimg/base/etiquetteTraining/6_1.png"/><span style="">&nbsp;</span>
</p>
<p style=";text-align: justify;white-space: normal">
<span style="">1、</span><span style=""><span style="">在接待中其本的礼貌用语和仪容仪表也是工作中注意重中之重，只有习惯才会自然，所以</span></span><span style="">医院</span><span style=""><span style="">前台在工作前就要</span></span><span style="">严于律己</span><span style=""><span style="">，规范化的仪式更容易拉近与顾客的距离。</span></span>
</p>
<p style=";text-align: justify;white-space: normal">
<span style="">2、</span><span style=""><span style="">在接待中遇到顾客沉默时，应该给予顾客思考的时间，不要不顾及顾客的想法喋喋不休</span></span><span style=""><span style="">的为顾客</span></span><span style=""><span style="">介绍优惠</span></span><span style="">活动</span><span style=""><span style="">，这种接待方式会让人反感，只有准确把握机会，根据顾客的需求慢慢引导，然后再去促成，这就是告诉前台在工作中不要怕顾客的沉默。</span></span>
</p>
<p style=";text-align: justify;text-indent: 0;white-space: normal">
<span style="">3、</span><span style=""><span style="">顾客在前台咨询问题时，有时会提出尖锐的问题时，前台就应该心平气和去做解答，若超个人工作范围内不能解答时，就要及时安抚顾客，然后找相关管理人员过来与顾客沟通。</span>4</span><span style="">、</span><span style=""><span style="">前台与顾客在沟通时，大谈工作外的话题，而对</span></span><span style="">医院</span><span style=""><span style="">相关的项目及产品交流甚少，这会给顾客留下不敬业的印象，产生不信任感。</span></span>
</p>
<p style=";text-align: justify;white-space: normal">
<span style="">5<span style="">、</span></span><span style=""><span style="">不论新老顾客来店咨询，耐心会介绍是前台的本职工作，不能因为是老顾客就省去操作环节的介绍，倘若如此就是失职。</span></span>
</p>
<p style=";text-align: justify;white-space: normal">
<span style="">6<span style="">、顾客反映问题时，不能只听，应该将顾客所反映的问题记录下来，让顾客感到是对她的所提问题的一种尊重。</span></span>
</p>
<p style=";text-align: justify;white-space: normal">
<span style="">7<span style="">、眼睛就是人心灵的窗口，与顾客交流千万不要用眼睛去斜视她，这种姿态会让顾客极其反感。</span></span>
</p>
<p style=";text-align: justify;white-space: normal">
<span style="">8<span style="">、前台在接待中出现失误，要寻找合适时机主动向顾客表示歉意，打消顾客心中的不满。</span></span>
</p>
<p style=";text-align: justify;white-space: normal">
<span style="">9<span style="">、前台一定注意，不能用怀疑的眼色打量进店顾客，评估顾客的消费能力，真诚热情的对待每一位进店顾客，不仅能提高</span></span><span style="">医院</span><span style=""><span style="">的形象和个人素质，还能打动顾客，因不消费而消费。</span></span><span style="">接待任务尤为重要看似简单</span><span style=""><span style="">，但是将每个细节做好，还是取决于个人的工作态度，以上几点只是</span></span><span style="">医美礼仪的一部分</span><span style=""><span style="">，要想将工作做好，在工作实践中学会分析和总结，才有助于</span></span><span style=""><span style="">能力的提高</span></span><span style=""><span style="">。</span></span>
</p>
<p style=";text-align: justify;white-space: normal">
<span style="">&nbsp;</span>
</p>
<p>
<br/>
</p>`,
// 地产礼仪
dcly: `<p style=";text-align: justify;white-space: normal">
<span style="">房地产</span><span style="">销售人员是企业形象的代言人。房地产销售商务礼仪</span><span style="">对房产买卖人员来说尤为重要</span><span style="">，房地产销售人员在商务场合要保持严谨、高雅、得体的言行举止、仪态仪表外，还要具备专业的售楼知识与技巧。</span>
</p>
<p style=";text-align: center;white-space: normal">
<img src="https://www.yjyculture.com/commonimg/base/etiquetteTraining/7_1.png" /><span style="">&nbsp;</span>
</p>
<p style=";text-align: justify;white-space: normal">
<span style="">一、</span><span style="">房地产销售商务礼仪之做好售前准备</span>
</p>
<p style="margin: 0 0 0 14px;text-align: justify;text-indent: 0;white-space: normal">
<span style="">1、</span><span style=""><span style="">法律文件：五证及工程验收合格证</span>;</span>
</p>
<p style="margin: 0 0 0 14px;text-align: justify;text-indent: 0;white-space: normal">
<span style="">2、</span><span style=""><span style="">楼宇说明书：统一说辞</span>;<span style="">总平规划图</span><span style="">;</span><span style="">交楼标准</span><span style="">;</span><span style="">物管内容</span><span style="">;</span></span>
</p>
<p style="margin: 0 0 0 14px;text-align: justify;text-indent: 0;white-space: normal">
<span style="">3、</span><span style="">合同文本：尾数纸</span><span style="">、</span><span style="">预定书</span><span style="">、</span><span style="">销售合同标准文本</span><span style="">、</span><span style="">抵押合同</span><span style="">、</span><span style="">保险合同</span><span style="">、</span><span style="">公证书</span><span style="">；</span>
</p>
<p style="margin: 0 0 0 14px;text-align: justify;text-indent: 0;white-space: normal">
<span style="">4、</span><span style="">其他资料：价目表</span><span style="">、</span><span style="">付款方式</span><span style="">、</span><span style="">按揭办理方法</span><span style="">、</span><span style="">利率表</span><span style="">、</span><span style="">办理产权证有关程序及费用</span><span style="">、</span><span style="">交楼流程</span><span style="">、</span><span style=""><span style="">入伙收费明细表</span>;</span>
</p>
<p style="margin: 0 0 0 14px;text-align: justify;text-indent: 0;white-space: normal">
<span style="">5、</span><span style="">销售作业指导书：职业准则</span><span style="">、</span><span style="">销售人员基本要求</span><span style="">、</span><span style=""><span style="">服务规范要求</span>;</span>
</p>
<p style="margin: 0 0 0 14px;text-align: justify;text-indent: 0;white-space: normal">
<span style="">6、</span><span style="">管理文件：组织结构</span><span style="">、</span><span style="">人员设置与分工</span><span style="">、</span><span style="">销售各岗位职务说明书</span><span style="">、</span><span style="">销售各岗位工作职责</span><span style="">、</span><span style="">待遇、考核、激励措施</span><span style="">、</span><span style=""><span style="">各项销售制度</span>;</span>
</p>
<p style="margin: 0 0 0 14px;text-align: justify;text-indent: 0;white-space: normal">
<span style="">7、</span><span style="">报表：电话接听记录表</span><span style="">、</span><span style=""><span style="">新</span>/<span style="">老客户表</span></span><span style="">、</span><span style="">客户访谈记录表</span><span style="">、</span><span style="">销售周、月报表</span><span style="">、</span><span style="">已成交客户档案表</span><span style="">、</span><span style="">应收帐款控制表</span><span style="">、</span><span style="">保留楼盘控制表。</span>
</p>
<p style="margin: 0 0 0 14px;text-align: center;white-space: normal">
<img  src="https://www.yjyculture.com/commonimg/base/etiquetteTraining/7_2.png" /><span style="">&nbsp;</span>
</p>
<p style=";text-align: justify;text-indent: 0;white-space: normal">
<span style="">二、</span><span style="">房地产销售商务礼仪中的统一销售说辞培训由于房地产行业特点，所涉及的房产项目比较多，相应的销售人员数量也不少。这其中就会出现各种说辞不一的问题。比如，客户在咨询过程中，前一个销售人员告诉说产品价格是这样，后一个说价格是那样，会造成客户对产品的质量产生疑问：是不是是个楼不值这个价。导致客户流失。统一说辞培训包括楼盘产品的数量、构成，楼盘产品的价格，竞争楼盘优劣分析及回答对策等。这些虽然是一些小的细节，但体现出的是一个公司的专业态度。</span>
</p>
<p style=";text-align: center;white-space: normal">
<img  src="https://www.yjyculture.com/commonimg/base/etiquetteTraining/7_3.png" /><span style="">&nbsp;</span>
</p>
<p style=";text-align: justify;text-indent: 0;white-space: normal">
<span style="">三、</span><span style=""><span style="">房地产销售商务礼仪中的产品演示技巧产品演示通过现场演示产品的特性有优势，以眼见为实促动消费者购买。是一种立竿见影的促销方式。要做好产品演示，首先销售人员要对公司及楼盘有清楚的认识。对公司来说，要充分了解公司的背景、公众形象以及公司的项目推广目标和公司发展目标</span>;<span style="">了解公司的企业文化、公司架构、管理制度、开发理念和开发业绩。这些都是销售人员在做产品演示的时候要展示给客户的。客户正是通过这些对公司的大方向的了解才能对此产生信任，进而有了购买的欲望。其次，要精通本楼盘的特点：</span><span style="">(1)</span><span style="">项目规模、定位、设施、买卖条件</span><span style="">;</span>　<span style="">(2)</span><span style="">项目周边的环境、公共设施、交通条件</span><span style="">;(3)</span><span style="">该区域城市发展规划及经济因素对项目的影响</span><span style="">;(4)</span><span style="">项目规划设计指标：占地面积、总建筑面积、建筑密度、建筑容积率、绿化率、公建面积、商业面积、配套面积、车位</span><span style="">;(5)</span><span style="">规划设计特点：建筑风格、景观、会所功能</span><span style="">;(6)</span><span style="">室内设计特点：大堂、户型、面积、结构、层高、朝向、采光</span><span style="">;</span><span style="">　　专业的知识掌握带给客户的是一种被信赖的感觉，楼盘的成功销售正是建立在客户的信赖基础之上。产品演示可以满足顾客视觉、听觉、嗅觉、触觉的感受。</span></span>
</p>
<p style=";text-align: justify;white-space: normal">
<span style=""><span style="">具体到房地产销售上讲主要有三个要点：</span>1.<span style="">视觉印象这是客户对产品产生的最直接的印象，因此做好楼盘产品模型</span><span style="">(</span><span style="">当然，这是建立在真实可靠的实景基础之上</span><span style="">)</span><span style="">，精通配色方案，带给客户的将是一场完美的视觉冲击。</span><span style="">2.</span><span style="">语言印象这是对销售人员来说的，如何更好的介绍楼盘，把楼盘的每一个优点都清楚的传达给客户是需要一定的语言技巧的。介绍的时候先要在心里有一个清楚的思路，哪里重点讲，哪里一句带过，要让客户保持一直认真听讲的状态。同时要具备行业礼貌用语。</span><span style="">3.</span><span style="">声音印象这个同样是对销售人员的要求。吐字清晰、声音柔美的销售人员会让客户的听讲成为一种享受。</span></span>
</p>
<p style=";text-align: center;white-space: normal">
<img  src="https://www.yjyculture.com/commonimg/base/etiquetteTraining/7_4.png"/><span style="">&nbsp;</span>
</p>
<p style=";text-align: justify;text-indent: 0;white-space: normal">
<span style="">四、</span><span style="">房地产销售商务礼仪中的文明用语在销售活动中，我们要学会使用以下文明用语：</span>
</p>
<p style=";text-align: justify;white-space: normal">
<span style="">迎宾用语类</span><span style="">：</span><span style=""><span style="">您好</span>!<span style="">欢迎光临</span><span style="">!</span><span style="">请坐</span><span style="">! </span><span style="">友好询问类</span></span><span style="">：</span><span style=""><span style="">请问您怎么称呼</span>?;<span style="">请问您是第一次来吗</span><span style="">?;</span><span style="">请问您想看什么样的楼</span><span style="">?;</span><span style="">不耽误您的时间的话，我给您介绍一下好吗</span><span style="">?;</span><span style="">请问您是自住还是投资</span><span style="">?</span><span style="">如果自住</span><span style="">(</span><span style="">投资</span><span style="">)</span><span style="">您不妨看看这套房子。 招待介绍类</span></span><span style="">：</span><span style=""><span style="">请您这边坐</span>!;<span style="">请您看看我们的资料</span><span style="">!</span><span style="">有什么不明白的，请尽管吩咐</span><span style="">!</span><span style="">那儿是我们的模型展示区，这儿是我们的洽谈区。道歉类</span></span><span style="">：</span><span style="">对不起，这套房子刚卖出去了。不好意思，您的话我还没有听明白。有什么意见，请您多指教。</span>
</p>
<p style=";text-align: justify;white-space: normal">
<span style="">房地产交易</span><span style="">谈判不同于一般的商业活动，因为谈判双方要为各自争取尽可能大的利益空间，所以，谈判过程中可能会涉及到双方言语等上的对立与交锋。这就使得一些人容易忽视商务谈判中的商务礼仪。</span>
</p>
<p style=";text-align: justify;white-space: normal">
<span style="">&nbsp;</span>
</p>
<p>
<br/>
</p>`,
// 政务礼仪
zwly: `<p style=";white-space: normal">
<span style="">政务礼仪，是指国家公务机关及相关事业单位在内部沟通交流及对外服务，与社会接触时的礼仪标准及原则。政务礼仪是提高服务质量及好评度的重要方法。</span>
</p>
<p style=";white-space: normal">
<span style="">随着社会的变革与发展，服务型政府的不断完善和进步，政务礼仪的适用人群也拓展除国家机关外的多数窗口单位的技能。其本质是通过系统的交流原则与技巧，维护机关单位的形象，提高服务的质量与好评度，拉近双方的距离，使工作更加</span><span style="">顺利的进行</span><span style="">。</span>
</p>
<p style=";white-space: normal;text-align: center;">
<img  src="https://www.yjyculture.com/commonimg/base/etiquetteTraining/8_1.png" /><span style="">&nbsp;</span>
</p>
<p style=";white-space: normal">
<span style="color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">公务礼仪应把握好</span>“三个关键”，即“精确标准尺度、区别条件因素、讲究方式方法”，才能赢得群众满意、增进信任，赢得社会满意、促进和谐。</span>
</p>
<p style="margin: 7px 0;text-indent: 0;white-space: normal;">
<span style="color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">精确标准尺度。尺度</span></span><span style="color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">即是</span></span><span style="color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">规矩，</span>“没有规矩不成方圆”。公务礼仪是公务交往的一把标尺，科学把握公务交往中的礼仪尺度和标准，才能使公务活动、人际交往圆满成功。首先，个人行为标准要规范。行为标准从狭义的概念来讲，主要包括语言、仪表、仪态等多个方面。在公务交往活动中，语言是增进沟通，促进交流的一把“金钥匙”，而仪表、仪态是一个人、一个集体的形象展示。因此，必须注意“三者”之间的关系，做到既能巧妙地单独使用，又能灵活地结合运用。在进行沟通交流的时候，要用文明、礼貌、朴实、健康、向上的语言，打开对方的心扉，用热情周到、耐心细致、谦逊礼让的态度以及标准得体的仪容仪表，参与公务活动，才能使活动在和谐自然的氛围中开展。</span>
</p>
<p style="margin: 7px 0;text-indent: 0;white-space: normal;text-align: center;">
<img  src="https://www.yjyculture.com/commonimg/base/etiquetteTraining/8_2.png" /><span style="color: rgb(51, 51, 51);letter-spacing: 0;">&nbsp;</span>
</p>
<p style="margin: 7px 0;text-indent: 0;white-space: normal;">
<span style="color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">其次，适用范围要适中。公务礼仪要把握适用、适中原则。适用适中，就要坚持什么样的事项、活动、交往使用什么样的礼仪。与公务活动不相适应的礼仪，从小处讲可能会遗留下笑柄，从大处讲损害形象，产生不良影响。再次，要具备相当的规模。规模即形状、外观情况。在公务活动中，礼仪必须坚持与之规模相匹配的原则，即通常所说的规范达标。公务活动要严格控制规模，尽量压缩事项、减少程序、节约费用，不得超标，不得铺张浪费，要大力提倡勤俭节约的优良作风，使公务活动的规模标准规范，既要办好事项，又要树好形象。</span></span>
</p>
<p style="margin: 7px 0;text-indent: 0;white-space: normal;">
<span style="color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">　区别条件因素。公务礼仪构成的因素，笔者个人认为主要包括时间、环节、环境、场合、风俗、特性等。</span></span>
</p>
<p style="margin: 7px 0;text-indent: 0;white-space: normal;">
<span style="color: rgb(51, 51, 51);letter-spacing: 0;">&nbsp;&nbsp;&nbsp; 一是时间和环节。开展公务活动要在不同的时间、不同的环节中使用不同的礼仪，同时，开展公务活动，要提倡遵守时间观念，办理事项还应做到注重简化程序、提高效率，为群众提供</span><span style="color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">热情周到的服务</span></span><span style="color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">。</span></span>
</p>
<p style="margin: 7px 0;text-indent: 0;white-space: normal;">
<span style="color: rgb(51, 51, 51);letter-spacing: 0;">&nbsp;&nbsp;&nbsp; 二是环境和场合。营造一个宽松的交往环境是融洽关系，促进交融的基础条件。在公务交谈的过程中，谈话双方要以互相尊重和相互理解作为基本前提，用规范的礼仪努力营造出一种幽默、坦诚、率真的谈话环境，给人轻松愉悦的感觉，利于公务活动开展。</span>
</p>
<p style="margin: 7px 0;text-indent: 0;white-space: normal;">
<span style="color: rgb(51, 51, 51);letter-spacing: 0;">&nbsp;</span>
</p>
<p style="margin: 7px 0;white-space: normal;text-indent: 49px;">
<span style="color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">三是风俗习惯和特性。公务礼仪要入乡随俗，充分尊重群众意愿，尊重群众的风俗习惯、宗教信仰、文化传统，要注重贴近群众生活，拉近同群众之间的距离。比如，开展干部下基层为群众解难、解困活动，面对的是基层干部和老百姓，仪态、仪表要坚持入乡随俗，贴近群众、贴近生活、贴近基层，消除同群众之间的陌生感，便于掌握真实情况，使公务活动取得成效。相反，语言、仪容、仪态、仪表严重脱离群众，必为群众厌恶，造成不良影响。</span></span>
</p>
<p style="margin: 7px 0;white-space: normal;text-indent: 49px;text-align: center;">
<img src="https://www.yjyculture.com/commonimg/base/etiquetteTraining/8_3.png"/><span style="color: rgb(51, 51, 51);letter-spacing: 0;">&nbsp;</span>
</p>
<p style="margin: 7px 0;text-indent: 0;white-space: normal;">
<span style="color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">　　讲究方式方法。在公务活动中，方式方法恰当的礼仪是取得成功的关键。一是根据人群、团体类别而定。针对不同的人群、团体，要做到具体事情具体分析，个别事项区别对待。公务礼仪要坚持</span></span><span style="color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">因人而</span></span><span style="color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">异</span></span><span style="color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">、</span></span><span style="color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">因事而</span></span><span style="color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">异</span></span><span style="color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">的原则，根据不同的事项、不同的人物、不同的团体和不同的特点、现状，来恰当运用。二是要讲究分寸，恰到好处。公务礼仪的方式方法除坚持灵活多样性外，还要把握分寸，火候恰当，才能达到事半功倍的效果。包括语言用词得当、语气适中、语速恰当；着装整齐规范、自然得体；形神落落大方、彬彬有礼等等，只有这样的公务礼仪，才能使公务活动能够在一种和谐、融洽、平等的氛围中开展和进行。三是要始终坚持从实际出发，坚持重礼貌、重礼节、重友谊、重实效的原则，采取合适的公务礼仪，才能保证公务活动圆满。</span></span>
</p>
<p style=";white-space: normal">
<span style="color: rgb(51, 51, 51);letter-spacing: 0;">&nbsp;</span>
</p>
<p>
<br/>
</p>`
}