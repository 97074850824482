<template>
    <div class="Box">
        <div class="leftBox">
            <textTitle text="合作伙伴"/>
                <div class="partner" v-for="(item, index) in partnersList" :key="index">
                    <partnerBox :url="item.assistantPicRealPath" :link="item.linkUrl"/>
                </div>
        </div>
        <sidebar />
    </div>
</template>
<script>
import textTitle from "@/components/headTail/textTitle";
import sidebar from "@/components/sidebar/sidebar";
import partnerBox from "@/components/partner/partnerBox"
import { homeService } from "@/api"

export default {
    components:{
        textTitle,
        sidebar,
        partnerBox
    },
    data() {
        return {
             partnersList: []
        }
    },
    methods: {
        getPartnersList() {
            let d = {
                pageNum: 1,
                pageSize: 1000
            }
            homeService.getPartnersList(d).then(res => {
                this.partnersList = res.list
            })
        }
    },
    created() {
        this.getPartnersList()
    }
}
</script>

<style lang="scss" scoped>
    .partner{
        float: left;
        width: 260px;
        height: 180px;
        margin-right: 60px;
        
    }
</style>