<template>
    <div class="Box course_detail">
        <div class="leftBox">
        <textTitle :text="textTitle" />
            <div v-html="data"></div>
        </div>
         <sidebar />
    </div>
</template>
<script>
import textTitle from "@/components/headTail/textTitle";

import sidebar from "@/components/sidebar/sidebar";
import courseJson from './course.js'
export default {
    components: {
        sidebar,
        textTitle
    },
    data() {
        return {
            tag: this.$route.query.tag,
            data: {},
            textTitle: "精品课程"
        }
    },
    watch: {
        $route() {
        this.getData()
        this.gitTitle();
        }
    },
    methods: {
        // 获取 标题
        gitTitle() {
            let textTitleFlag = this.$route.query.tag;
            let arr = [
                {
                tag: 'gjxxlys',
                value: "国际形象礼仪师培训",
                },
                {
                tag: 'gjxxgw',
                value: "国际形象顾问培训",
                },
                {
                tag: 'gjlys',
                value: "国际礼仪师培训",
                },
                {
                tag: 'cslm',
                value: "城市联盟机构培训",
                },
                {
                tag: 'gjsw',
                value: "国际商务礼仪精英认证（线上）",
                },
                {
                tag: 'grly',
                value: "个人礼仪培训",
                }
            ];
            this.textTitle = arr.filter(
                (item) => item.tag == textTitleFlag
            )[0].value;
            },
        getData() {
            this.data = courseJson[this.$route.query.tag]
        }
    },
    created() {
        this.getData()
        this.gitTitle()
    }
}
</script>
<style scoped>
.course_detail >>> img{
  max-width: 600px;
}
.course_detail >>> *{
  line-height: 26px;
}

.course_detail .leftBox{
        margin: 0 auto;
        width: 75%;
}
</style>