
<!-- 精品课程 -->
<template>
  <div class="Box">
    <div class="leftBox">
      <textTitle :text="textTitle" />
      <div class="tempBox" v-for="(item, index) in data" :key="index" @click="goDetail(item.tag)">
        <img src="../../assets/img/course/course1.png" alt="">
        <p>{{item.name}}</p>
      </div>
    </div>
    <sidebar />
  </div>
</template>
<script>
import textTitle from "@/components/headTail/textTitle";
import sidebar from "@/components/sidebar/sidebar";
import courseJson from './course.js'
export default {
  components: {
    textTitle,
    sidebar
  },
  data() {
    return {
      tag: this.$route.query.tag,
      data: [
        {name: '国际形象礼仪师培训', tag: 'gjxxlys'},
        {name: '国际形象顾问培训', tag: 'gjxxgw'},
        {name: '国际礼仪师培训', tag: 'gjlys'},
        {name: '城市联盟机构培训', tag: 'cslm'},
        {name: '国际商务礼仪精英认证（线上）', tag: 'gjsw'},
        {name: '个人礼仪培训', tag: 'grly'}
      ],
      textTitle: "精品课程"
    };
  },
  methods: {
    goDetail(tag) {
        this.$router.push({path: "/course/detail",query:{ tag: tag}})
    }
  },
  created() {},
};
</script>

<style lang="scss" scoped>
.tempBox {
  width: 45%;
  display: inline-block;
  margin: 20px;
  cursor: pointer;
  img{
    width: 50px;
    float: left;
  }
  p{
    line-height: 50px;
    margin-left: 20px;
    float: left;
    color: #404040;
  }
}
</style>