<template>
    <div class="Box etiquetteTraining">
        <div class="leftBox">
        <textTitle :text="textTitle" />
            <div v-html="data"></div>
        </div>
         <sidebar />
    </div>
</template>
<script>
import textTitle from "@/components/headTail/textTitle";

import sidebar from "@/components/sidebar/sidebar";
import etiquetteTrainingJson from './etiquetteTraining.js'
export default {
    components: {
        sidebar,
        textTitle
    },
    data() {
        return {
            tag: this.$route.query.tag,
            data: {},
            textTitle: "企业礼仪培训"
        }
    },
    watch: {
        $route() {
        this.getData()
        this.gitTitle();
        }
    },
    methods: {
        // 获取 标题
        gitTitle() {
            let textTitleFlag = this.$route.query.tag;
            let arr = [
                {
                tag: 'swly',
                value: "商务礼仪",
                },
                {
                tag: 'jdly',
                value: "接待礼仪",
                },
                {
                tag: 'fwly',
                value: "服务礼仪",
                },
                {
                tag: 'wyly',
                value: "物业礼仪",
                },
                {
                tag: 'ylly',
                value: "医疗礼仪",
                },
                {
                tag: 'ymly',
                value: "医美礼仪",
                },
                {
                tag: 'dcly',
                value: "地产礼仪",
                },
                {
                tag: 'zwly',
                value: "政务礼仪",
                }
            ];
            this.textTitle = arr.filter(
                (item) => item.tag == textTitleFlag
            )[0].value;
            },
        getData() {
            this.data = etiquetteTrainingJson[this.$route.query.tag]
        }
    },
    created() {
        this.getData()
        this.gitTitle()
    }
}
</script>
<style scoped>
.etiquetteTraining >>> img{
  width: 600px;
  margin: 20px 0;
}
.etiquetteTraining >>> *{
  line-height: 26px;
}

.etiquetteTraining .leftBox{
        margin: 0 auto;
        width: 75%;
}
</style>