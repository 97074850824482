export default {
    gjxxlys: `
    <h2 style="margin: 0px 0px 9px;  ; text-indent: 0px; white-space: normal; padding: 0px; ; text-align: left;">
    <span style=";"><strong><span style=" color: rgb(51, 51, 51); letter-spacing: 0px;"><span style="">招生：</span>IBIE CIET国际双语形象礼仪师研修班</span></strong></span>
</h2>
<p style=" ; white-space: normal; padding: 0px; text-align: left;">
    <span style=";">&nbsp;</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";">Goethe once said Etiquette is a mirror that reflects each person&#39;s own image.</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";"><strong><span style="color: rgb(62, 62, 62); ; ">歌德曾说过</span></strong></span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";"><strong><span style="color: rgb(62, 62, 62); ; ">礼仪举止是映照每个人自身形象的镜子。</span></strong></span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";"><strong><span style=" color: rgb(20, 86, 131); ;">Culture&nbsp;is&nbsp;shaping&nbsp;the Age&nbsp;</span></strong></span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";"><strong><span style=" color: rgb(20, 86, 131); ;">Etiquette manifest civilization</span></strong></span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";"><strong><span style="color: rgb(20, 86, 131); ; ">文化塑造时代</span></strong></span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";"><strong><span style="color: rgb(20, 86, 131); ; ">礼仪彰显文明</span></strong></span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";"><strong><span style=" color: rgb(62, 62, 62); ;">IBIE</span></strong></span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";"><strong><span style=" color: rgb(62, 62, 62); ;">The top-end academy;</span></strong></span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";"><strong><span style=" color: rgb(62, 62, 62); ;">Cutting-edge culture exchange;</span></strong></span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";"><strong><span style=" color: rgb(62, 62, 62); ;">The most authoritative group of lecturers;</span></strong></span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";"><strong><span style=" color: rgb(62, 62, 62); ;">International Certification Image Etiquette Workshop,</span></strong></span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";"><strong><span style=" color: rgb(62, 62, 62); ;">Here it comes!</span></strong></span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";;white-space: normal;text-align: center">
    <span style=";"><strong><span style=" ;">IBIE</span></strong></span>
</p>
<p style=";;white-space: normal;padding: 0;text-align: center">
    <span style=";"><strong><span style=" ;"><span style="; ">高端的学术碰撞</span>;</span></strong></span>
</p>
<p style=";;white-space: normal;padding: 0;text-align: center">
    <span style=";"><strong><span style=" ;">&nbsp; &nbsp;<span style="; ">严谨的课程体系；</span></span></strong></span>
</p>
<p style=";;white-space: normal;padding: 0;text-align: center">
    <span style=";"><strong><span style=" ;"><span style="; ">前沿的文化交流</span>;</span></strong></span>
</p>
<p style=";;white-space: normal;padding: 0;text-align: center">
    <span style=";"><strong><span style=" ;"><span style="; ">权威的讲师团队</span>;</span></strong></span>
</p>
<p style=";;white-space: normal;padding: 0;text-align: center">
    <span style=";"><strong><span style=" ;"><span style="; ">国际双语形象礼仪研修班</span>;</span></strong></span>
</p>
<p style=";;white-space: normal;padding: 0;text-align: center">
    <span style=";"><strong><span style=" color: rgb(20, 86, 131); ;"><span style="; ">来了</span>!</span></strong></span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";;white-space: normal;text-align: center;">
    <img  src="https://www.yjyculture.com/commonimg/base/1_1.png"  /><span style=";">&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";">Why learn about the knowledge of international image etiquette?</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";">Etiquette is not only the reflect of one&#39;s culture,</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";"><span style=";">It&#39;s also the core strength behind the&nbsp;</span><span style="color: rgb(62, 62, 62); ;">brand image.</span></span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";"><strong><span style=" color: rgb(20, 86, 131); ;">Aesthetics, Creation</span></strong></span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";"><strong><span style=" color: rgb(20, 86, 131); ;">Image, etiquette</span></strong></span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";">To truly understand the essence, and master it to be a success.</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";">To improve the core capability, with strong soft skill power.</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";"><strong><span style=" color: rgb(62, 62, 62); ;"><span style="; ">为什么要学习国际形象礼仪呢</span>?</span></strong></span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=" ;">礼仪已不仅仅是一个人基本素养的体现，</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=" ;">同时也是职场品牌形象背后的核心竞争力之一。</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";">IBIE<span style="; ">致力于当代职场商业人士打造国际形象礼仪，</span></span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=" ;">为精英文化不断注入新鲜能量，</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=" ;">提升您的国际商务礼仪标准和外交软实力。</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";"><strong><span style="color: rgb(20, 86, 131); ; ">审美、创造</span></strong></span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";"><strong><span style="color: rgb(20, 86, 131); ; ">形象、礼仪</span></strong></span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=" ;">领悟到其中精髓并且掌握运用才能够真正算得上成功</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=" ;">如何提高自身的核心竞争，在职场上驰骋呢？</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";">With the rapid evolvement of Today</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";">Stay with international standards</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";">It must be in line with the international image etiquette culture.</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";">Thus,</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";"><strong><span style=" color: rgb(20, 86, 131); ;">International Business Image Etiquette Trainner</span></strong></span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";">A whole new profession quickly emerged</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=" ;">在时代飞速发展的今天</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=" ;">与国际接轨</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=" ;">也就必须要与国际形象礼仪文化接轨</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=" ;">由此</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";"><strong><span style="color: rgb(20, 86, 131); ; ">国际形象礼仪师</span></strong></span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";"><strong><span style="; ">一个与时俱进的职业应运而生</span></strong></span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";">Their</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";"><strong><span style=" color: rgb(20, 86, 131); ;">a kind smile, an elegant manner;</span></strong></span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";"><strong><span style=" color: rgb(20, 86, 131); ;">a thoughtful etiquette, a nice image;</span></strong></span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";"><strong><span style=" color: rgb(20, 86, 131); ;">confident speech, the amiable attitude of Confucianism;</span></strong></span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";">Whether it is daily communication, or business meetings;</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";">will shorten each other&#39;s distance, harmonize people&#39;s relationship;</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";">Warm up the contacts and make the people to get along better and better.</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";">Flashing the office&#39;s smart and confident status. Easier to achieve Win-Win!</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";"><span style="; ">他们</span>/<span style="; ">她们</span></span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";"><strong><span style=" color: rgb(20, 86, 131); ;"><span style="; ">亲切的微笑</span>,<span style="; ">优雅的举止</span><span style="; ">;</span></span></strong></span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";"><strong><span style=" color: rgb(20, 86, 131); ;"><span style="; ">周到的礼仪</span>,<span style="; ">美好的形象</span><span style="; ">;</span></span></strong></span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";"><strong><span style=" color: rgb(20, 86, 131); ;"><span style="; ">侃侃而谈的气度</span>,<span style="; ">儒雅随和的态度</span><span style="; ">;</span></span></strong></span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";"><span style="; ">无论是日常交际</span>,<span style="; ">还是商务往来</span><span style="; ">;</span></span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";"><span style="; ">都会拉近彼此的距离</span>,<span style="; ">融洽人们之间的关系</span><span style="; ">;</span></span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";"><span style="; ">使得双方的接触和相处平添暖心的温度</span>.</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=" ;">闪动着职场领域无所不知的姿态。</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";"><span style="; ">更易达成双方所愿</span>!</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";;white-space: normal;text-align: center;">
    <img src="https://www.yjyculture.com/commonimg/base/1_2.png"  /><span style=" ;">&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<div style="text-align: center;">
<p style=";;white-space: normal;padding: 0">
    <span style=";"><strong><span style=" color: rgb(20, 86, 131); ;">Their Mission and Duties are:</span></strong></span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";"><strong><span style=" color: rgb(20, 86, 131); ;">Helping people to improve elegant business image,&nbsp;</span></strong></span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";"><strong><span style=" color: rgb(20, 86, 131); ;">Spreading image&nbsp;etiquette knowledge;</span></strong></span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";"><strong><span style=" color: rgb(20, 86, 131); ;">Transfering civilization, breed love, and&nbsp;cultivate&nbsp;beauty!</span></strong></span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">It&#39;s this kind of identity.</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">Can help you to achieve the image management of your personal brand</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">Like Phoenix Nirvana, standing on the summit</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">So How can you become a professional international image etiquette trainer?</span>
</p>
</div>
<p style=";;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";"><strong><span style=" color: rgb(20, 86, 131); ;"><span style="; ">他们的使命和职责是</span>:</span></strong></span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";"><strong><span style=" color: rgb(20, 86, 131); ;"><span style="; ">帮助人们设计美好商务形象</span>,<span style="; ">普及国际形象礼仪知识</span><span style="; ">;</span></span></strong></span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";"><strong><span style=" color: rgb(20, 86, 131); ;"><span style="; ">散播文明素养</span>,<span style="; ">传递爱心</span><span style="; ">,</span><span style="; ">传播美</span><span style="; ">!</span></span></strong></span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=" ;">正是这样的一种身份</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=" ;">能够帮助你实现个人品牌的形象管理</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=" ;">如同凤凰涅槃，屹立闪耀于职场之巅</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=" ;">那么，</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";"><strong><span style=" color: rgb(20, 86, 131); ;"><span style="; ">该如何才能成为一名专业的国际形象礼仪师呢</span>?</span></strong></span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";"><strong><span style=" ;">IBIE will tell you all about them.</span></strong></span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";"><strong><span style=" ;">IBIE<span style="; ">会告诉你一切关于他们的奥秘。</span></span></strong></span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";">What is the IBIE?</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";"><strong><span style=" ;"><span style="; ">什么是</span>IBIE<span style="; ">国际形象礼仪组织协会？</span></span></strong></span>
</p>
<p style=";;white-space: normal">
    <span style=";">&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">&quot;INTERNATIONAL BUSINESS IMAGE ETIQUETTE&quot; STANDARD &amp; EVALUATION is an NPO Non-Profit Organization Association, jointly established by experts and professionals in the field of image and etiquette in Beijing, China, Beijing University of Posts and Telecommunications, University of International Business and Economics, China Media University, Diplomatic Academy, Capital Normal University, Northeastern University, and the University of California, Los Angels, the California State Polytechnic University Pomona, and the people in all sectors of public who are interested in this field.</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";"><span style="; ">「</span>INTERNATIONAL BUSINESS IMAGE ETIQUETTE<span style="; ">」</span><span style="; ">STANDARD &amp; EVALUATION</span><span style="; ">是由中国北京形象和礼仪专业领域内专家及老师、北京邮电大学、对外经济贸易大学、中国传媒大学、外交学院、首都师范大学、东北大学、美国加州大学洛杉矶分校社科院专家学者、美国加州州立理工大学波莫纳分校及社会各界对该领域抱有兴趣的有识之士共同创建的 </span><span style="; ">NPO </span><span style="; ">无盈利性质组织协会。</span></span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";;white-space: normal;text-align: center;">
    <img src="https://www.yjyculture.com/commonimg/base/1_3.png" /><span style=" ;">&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">IBIE in China (International Business Image Etiquette).IBIE, is jointly organized and coordinated by Beijing YJY International Cultural Development Co., Ltd. in China and CalPoly Pomona CPP in the United States.</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">It is an vocational training certificate institution, which covers training, certification, online learning and exams.</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">IBIE <span style="; ">在中国（国际形象礼仪组织协会 </span><span style="; ">· </span><span style="; ">中国北京）由北京瑜璟缘国际文化发展有限公司组织协调，在美国由（美国 </span><span style="; ">· </span><span style="; ">加州州立理工大学延展学院）联合国际形象认证协会，共同组织和发起的公益性协会组织。</span></span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=" ;">主要覆盖范围包括培训、认证、在线学习和考试的职业培训认证机构。</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";;white-space: normal">
    <span style=";">&nbsp;</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";"><strong><span style=" ;">What is the purpose of obtaining IBIE certification?</span></strong></span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";"><strong><span style=" ;"><span style="; ">【获得</span>IBIE<span style="; ">证书有什么作用呢？】</span></span></strong></span>
</p>
<p style=";;white-space: normal">
    <span style=";">&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0;text-align: center;">
    <img  src="https://www.yjyculture.com/commonimg/base/1_4.png" /><span style=" ;">&nbsp;</span>
</p>
<p style=";text-align: center;;white-space: normal">
    <span style=";">&nbsp;</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";"><strong><span style=" letter-spacing: 0px; ;"><span style="; ">（</span>IBIE<span style="; ">证书样本）</span></span></strong></span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">IBIE certification can represent today&#39;s international image etiquette industry professional certification level, can objectively evaluate the international image etiquette industry personnel expert level of knowledge and ability level, to obtain this certification will have the following benefits:</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";"><strong><span style=" color: rgb(20, 86, 131); ;">Improve the systematic awareness of your international image etiquette;</span></strong></span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";"><strong><span style=" color: rgb(20, 86, 131); ;">Favorable for further study abroad; Expand resources and enrich the network;</span></strong></span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">IBIE <span style="; ">认证能够代表当今国际形象礼仪行业的职业认证一线水平，可以客观地评价从事国际形象礼仪行业人员的专家级的知识和能力水平，取得这项认证可以获得以下收益：</span></span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";"><strong><span style=" color: rgb(20, 86, 131); ;">• <span style="; ">提升自身国际形象礼仪方面的系统认知；</span></span></strong></span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";"><strong><span style=" color: rgb(20, 86, 131); ;">• <span style="; ">有利出国深造；</span></span></strong></span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";"><strong><span style=" color: rgb(20, 86, 131); ;">• <span style="; ">扩展资源、充实人脉圈；</span></span></strong></span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";;white-space: normal">
    <span style=";">&nbsp;</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";"><strong><span style=" ;">Employment Prospects of International Image Etiquette&nbsp;Educators</span></strong></span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";"><strong><span style="; ">【国际形象礼仪师的就业前景】</span></strong></span>
</p>
<div  style="text-align: center;">
<p style=";;white-space: normal;padding: 0">
    <span style=";">International business particiation<span style="; ">；</span></span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">Regular participation in government or business meetings</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=" ;">涉外工作人员；经常参加政务或商务会议；</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">Financial elite<span style="; ">；</span><span style="; ">International Real Estate Consultant</span><span style="; ">；</span></span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";"><span style="; ">金融行业精英；国际房产顾问；</span>&nbsp;&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">International School Teachers<span style="; ">；</span></span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">International Concierge Service<span style="; ">；</span></span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";"><span style="; ">国际学校老师；国际管家服务；</span>&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">TV host<span style="; ">；</span><span style="; ">International English Teacher</span><span style="; ">；</span></span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";"><span style="; ">电视台主持人；国际英语老师；</span>&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">International Image Consultant;</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">International Etiquette Trainer;</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=" ;">国际形象顾问；国际礼仪培训师；</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">CEO of Industrial Chain Enterprises;&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">International stewardess;</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";"><span style="; ">产业链企业</span>CEO<span style="; ">；</span><span style="; ">&nbsp;</span><span style="; ">国际空姐；</span></span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">Medical and Health Institutions&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=" ;">医疗卫生事业单位；</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">International Star Hotel Management&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=" ;">国际星标酒店管理；</span>
</p>
</div>
<p style=";;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";">If you&#39;re looking forward to international culture,</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";">So</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";">IBIE International Business Image Etiquette Organization Certification,</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";">International image etiquette teacher will be the most important and proper choice in your life! It will add endless possibilities to your career.</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=" ;">如果你很向往国际文化</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=" ;">那么，</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";">IBIE<span style="; ">国际形象礼仪组织认证，</span></span>
</p>
<p style=";text-align: center;;white-space: normal">
    <span style=" color: rgb(20, 86, 131); letter-spacing: 0px; ;">国际形象礼仪师将是您的一生中最重要而美好的选择！</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style="color: rgb(20, 86, 131); ;">Ta<span style="color: rgb(20, 86, 131); ; ">将为您的职业生涯增添无限可能。</span></span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";;white-space: normal;text-align: center;">
    <img src="https://www.yjyculture.com/commonimg/base/1_5.png"  /><span style=" ;">&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">IBIE<span style="; ">：</span><span style="; ">INTERNATIONAL BUSINESS IMAGE ETIQUETTE Standards &amp; Evaluation. International professional business image etiquette standards and evaluation.</span></span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">Domestic debut, only this one certified international image etiquette teacher standard courses. If you successfully finished study, you will be china&#39;s international image etiquette division in the top level people. So for our cause and life to bring some passion and new ideas, follow the international pace of development rhythm, do this era of the long drive elite!</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">IBIE<span style="; ">：</span><span style="; ">INTERNATIONAL BUSINESS IMAGE ETIQUETTE STANDARD &amp; EVALUATION </span><span style="; ">国际职业商务形象礼仪标准及测评。国内首发，仅此一家测评国际形象礼仪师标准与综合素养的课程。</span></span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";"><span style="; ">如果您在学习后顺利结业，</span><span style="color: rgb(0, 122, 170); ; ">那么您将在中国境内国际形象礼仪师行列名列前矛</span><span style=";"><span style="; ">，并且成为北京瑜璟缘国际文化发展有限公司签约讲师，并获得赴美深造留学的机会，还可申请</span>IBIE<span style="; ">国内分会合作发展共赢的同路人！紧随国际步伐的发展节奏，共同做这个时代的长驱精英！</span></span></span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";text-align: center;;white-space: normal">
    <span style=";">&nbsp;</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";"><strong><span style=" ;">IBIE International Certification-Related Upgrade System</span></strong></span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";"><strong><span style=" ;"><span style="; ">【</span>IBIE<span style="; ">国际认证相关的升级制度 】</span></span></strong></span>
</p>
<p style=";;white-space: normal">
    <span style=";">&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";"><strong><span style=" color: rgb(20, 86, 131); ;">Domestic stage:&nbsp;</span></strong><strong><span style=" color: rgb(62, 62, 62); ;">IBIE CIET International Certified Image Etiquette Trainer (domestic part) in two ways:</span></strong></span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";"><strong><span style="color: rgb(20, 86, 131); ; ">国内阶段</span></strong><span style="color: rgb(20, 86, 131); ; ">：</span><span style=";"><span style="; ">获得</span>IBIE CIET<span style="; ">国际认证形象礼仪培训师（国内部分）有两种方式：</span><span style="; ">&nbsp;</span></span></span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style="margin: 0 0 0 24px;text-align: justify;;white-space: normal;padding: 0">
    <span style=" ;">·&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">IBIE CIET ---- to participate in the IBIE International Image Etiquette Instructor Training Certification Course, the test passed IBIE CIET (International Image Etiquette Trainer) certificate. The Global Image Etiquette Business Community, a three-star lecturer can submit a portfolio of portfolios and a letter of recommendation to Nomination (at least three recommenders) and pass test scores B and above.</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">IBIE CIET----<span style="; ">参加</span><span style="; ">IBIE </span><span style="; ">国际形象礼仪师培训认证班，考试合格过得</span><span style="; ">&nbsp; IBIE CIET(</span><span style="; ">国际形象礼仪培训师</span><span style="; ">) </span><span style="; ">的证书。国际形象礼仪商学院授星三星讲师，可提交作品集</span><span style="; ">Portfolio</span><span style="; ">及推荐信</span><span style="; ">Nomination</span><span style="; ">（至少三位推荐者）并通过考试成绩</span><span style="; ">B </span><span style="; ">及以上。</span><span style="; ">&nbsp;</span></span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";"><strong><span style=" color: rgb(20, 86, 131); ;">International stage:&nbsp;</span></strong><strong><span style=" color: rgb(62, 62, 62); ;">IBIE CIEP International Certified Image Etiquette Expert (International part) in two ways:</span></strong></span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";"><strong><span style="color: rgb(20, 86, 131); ; ">国际阶段：</span></strong><span style=";"><span style="; ">获得</span>IBIE CIEP<span style="; ">国际认证形象礼仪专家（国际部分）有两种方式：</span><span style="; ">&nbsp;</span></span></span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">IBIE CIEP ---- to apply for and participate in the United States to continue their studies and successfully obtain a certificate of completion (at least three months after the completion of the CIET certification), to obtain the IBIE CIEP (International Image Etiquette Expert) certificate. The Global Image Etiquette Business Community, a four-star lecturer, can submit a portfolio of portfolios and a letter of recommendation. Nomination (at least four recommenders) and pass a test score B and above.</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">IBIE CIEP----<span style="; ">申请并参加美国继续深造并成功取得结业证书者（需在获得 </span><span style="; ">CIET </span><span style="; ">认证期满至少三个月后），获得 </span><span style="; ">IBIE CIEP(</span><span style="; ">国际形象礼仪专家</span><span style="; ">)</span><span style="; ">证书。国际形象礼仪商学院授星四星讲师，可提交作品集</span><span style="; ">Portfolio</span><span style="; ">及推荐信</span><span style="; ">Nomination</span><span style="; ">（至少四位推荐者）并通过考试成绩</span><span style="; ">B </span><span style="; ">及以上。</span><span style="; ">&nbsp;</span></span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";"><strong><span style=" color: rgb(20, 86, 131); ;">Master Stage:</span></strong><strong><span style=" color: rgb(179, 104, 122); ;">&nbsp;</span></strong><span style="color: rgb(179, 104, 122); ;">Get IBIE CIEM International Certified Image Etiquette Master in one way:&nbsp;</span></span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";"><strong><span style="color: rgb(20, 86, 131); ; ">大师阶段</span></strong><span style="color: rgb(20, 86, 131); ; ">：</span><span style=";"><span style="; ">获得</span>IBIE CIEM <span style="; ">国际认证形象礼仪大师有一种方式：</span><span style="; ">&nbsp;</span></span></span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">&nbsp;IBIE CIEM ---- successfully published journals for more than one year or a series of books, can submit portfolio and letter of recommendation Nomination (at least five recommenders) and pass the test scores B and above. The exam is divided into two parts (paper and reply Presentation).&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">IBIE CIEM----<span style="; ">成功发表期刊以或系列图书一年以上，可提交作品集</span><span style="; ">Portfolio</span><span style="; ">及推荐信 </span><span style="; ">Nomination </span><span style="; ">（至少五位推荐者）并通过国际形象礼仪组织协会考试成绩</span><span style="; ">B</span><span style="; ">及以上。考试分为（论文及答辩</span><span style="; ">Presentation</span><span style="; ">两部分）。</span></span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";text-align: center;;white-space: normal">
    <span style=";">&nbsp;</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";">Introduction to the Grand Master Team</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";"><strong><span style="; ">【大师讲师团介绍】</span></strong></span>
</p>
<p style=";;white-space: normal">
    <span style=";">&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0;text-align: center;">
    <span style=";"><strong><span style="; ">讲师：周加李老师</span></strong></span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";;white-space: normal;text-align: center;">
    <img  src="https://www.yjyculture.com/commonimg/base/1_6.png"  /><span style=" ;">&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";"><strong><span style=" color: rgb(20, 86, 131); ;">Ph.D., Zhou Jiali</span></strong></span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">S.J.D<span style="; ">，</span><span style="; ">Doctor of Judicial Science</span></span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">Full-time teacher of the Department of Diplomacy and Foreign Affairs Management, School of Foreign Affairs, School of Foreign Affairs</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">Visiting Professor, Institute of International Business Officials, Central University of Finance and Economics/Ministry of Commerce</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">Visiting Scholar at Bond University of Australia and Oxford University, UK</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">International Image Consultant Association AICI Certified Image Expert</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">Vice President, Beijing Branch, International Image Consultant Association</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">Publishing monograph &quot;External communication and personal image shaping&quot; &quot;Foreign etiquette&quot; &quot;Clothing can be simple accessories can not be sloppy&quot;</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">Editor-in-Chief &quot;From Theory to Practice - Diplomatic Protocol Etiquette Research&quot;</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">Editor-in-Chief of &quot;New Civil Service Foreign Affairs Etiquette&quot;</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">Participated in the writing of the Foreign Affairs Personnel Knowledge Handbook</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">Published more than forty papers and articles</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";"><strong><span style="color: rgb(20, 86, 131); ; ">法学博士</span></strong></span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=" ;">外交学院外交学与外事管理系公共外交教研室专职教师</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";"><span style="; ">中央财经大学</span>/<span style="; ">商务部国际商务官员研修学院客座教授</span></span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=" ;">澳大利亚邦德大学与英国牛津大学访问学者</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";"><span style="; ">国际形象顾问协会</span>AICI<span style="; ">认证形象专家</span></span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=" ;">国际形象顾问协会北京分会副主席</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";"><span style="; ">出版专著《对外交流与个人形象塑造》《涉外礼仪》《服装可以简单</span> <span style="; ">配饰不能马虎》</span></span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";"><span style="; ">主编《从理论到实践</span>—<span style="; ">外交礼宾礼仪研究》</span></span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=" ;">主编《新编公务员外事礼仪》</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=" ;">参与撰写《公职人员外事知识手册》</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=" ;">发表各种论文与文章四十余篇</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0;text-align: center;">
    <span style=";"><strong><span style="; ">讲师：赵琳老师</span></strong></span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";;white-space: normal;text-align: center;">
    <img src="https://www.yjyculture.com/commonimg/base/1_7.png"/><span style=" ;">&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";"><strong><span style=" color: rgb(20, 86, 131); ;">Dr. Lin Zhao &nbsp;</span></strong></span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">Associate Professor, Academy of broadcasting Host Art, China Media University</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">The practice of oral communication in the majors of news and communication</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">Master&#39;s Tutor, Language Communication</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">Former CCTV reporter, Chinese and English interview with international dignitaries Dedicated to cross-cultural language communication and public image research for more than ten years</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";"><strong><span style=" color: rgb(20, 86, 131); ;"><span style="; ">赵博士</span>&nbsp;&nbsp;</span></strong><strong><span style=" color: rgb(179, 104, 122); ;">&nbsp;&nbsp;&nbsp;</span></strong><span style=";">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";"><span style="; ">中国传媒大学播音主持艺术学院</span> <span style="; ">副教授</span></span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=" ;">新闻与传播专业口语传播实务</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";"><span style="; ">语言传播专业</span> <span style="; ">硕士研究生导师</span></span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=" ;">曾任央视记者，中英文专访国际政要</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=" ;">潜心致力于跨文化语言传播与公众形象研究十余年</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0;text-align: center;">
    <span style=";"><strong><span style="; ">讲师：肖倪老师</span></strong></span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";;white-space: normal;text-align: center;">
    <img src="https://www.yjyculture.com/commonimg/base/1_8.png" /><span style=" ;">&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";"><strong><span style=" color: rgb(20, 86, 131); ;">Ms. Xiao Ni</span></strong></span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">Master of Arts in Art, Beijing Normal University</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">Teacher of &quot;Shape and Social Etiquette&quot; at Beijing University of Posts and Telecommunications</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">Teaching Director, Courses as &quot;Appreciation of Dance Art&quot; and &quot;Introduction to Art&quot;</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">Participation for the State Patent Office Cofco Group and other government departments</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">Enterprises and institutions as social government etiquette and Knowledge training and lectures on business reception</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";"><strong><span style="color: rgb(20, 86, 131); ; ">肖妮老师</span></strong></span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=" ;">北京师范大学艺术学硕士学位</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=" ;">北京邮电大学《形体与社交礼仪》任课教师</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=" ;">曾担任《舞蹈艺术鉴赏》《艺术概论》等课程的教学工作</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=" ;">多次为国家专利局、中粮集团等政府部门</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=" ;">企事业单位担任社交、政务礼仪和</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=" ;">商务接待等知识培训和讲座</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0;text-align: center;">
    <span style=";"><strong><span style=" ;"><span style="; ">讲师：逄天舒老师</span> &nbsp;Angelina Pang</span></strong></span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";;white-space: normal;text-align: center;">
    <img src="https://www.yjyculture.com/commonimg/base/1_9.png" /><span style=" ;">&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";"><strong><span style=" color: rgb(20, 86, 131); ;">Angelina Pang</span></strong></span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">International Image Etiquette (N+1) Course Creator</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">Certified Senior Image Manager, Ministry of Culture</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">Co-Creation and Re-Education International Etiquette Project Exchange Sponsor</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">&nbsp;AICI IMMIE Award 2019</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">Global Director, Organizations of IBIE, International Business Image Etiquette</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">Invited Visiting Scholar, POMONA Institute of Technology, California, USA</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">Lecturer of Beijing Municipal Committee of the Communist Youth League (public welfare)</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">EF Education Special Co-operative Bilingual Lecturer</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">Special Etiquette Division, University of California, USA 2022 Beijing Winter Olympic Organizing Committee Special Advisory Etiquette</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";"><strong><span style="color: rgb(20, 86, 131); ; ">逄天舒老师</span></strong></span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";"><span style="; ">国际形象礼仪</span>[N+1]<span style="; ">课程创研人</span></span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=" ;">国家文化部认证高级形象管理师</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=" ;">美国联创再教育国际礼仪项目交流发起人</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";"><span style="; ">美国</span>AICI IMMIE Award<span style="; ">奖项国内大陆唯一获奖者</span></span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">IBIE NPO<span style="; ">国际形象礼仪组织协会理事长</span></span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";"><span style="; ">美国加州</span>POMONA<span style="; ">理工大学受邀访问学者</span></span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=" ;">共青团北京市委员会礼仪讲师（公益）</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";"><span style="; ">英孚教育</span> <span style="; ">特约合作双语讲师</span></span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=" ;">美国加州大学特约礼仪师</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">2022<span style="; ">北京冬奥组委特约咨询礼仪师</span></span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0;text-align: center;">
    <span style=";"><strong><span style=" ;"><span style="; ">讲师：内森</span>·<span style="; ">杰克逊老师</span></span></strong></span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";;white-space: normal;text-align: center;">
    <img src="https://www.yjyculture.com/commonimg/base/1_10.png" /><span style=" ;">&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";"><strong><span style=" color: rgb(20, 86, 131); ;">Nathan Jackson</span></strong></span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">Beijing&nbsp;Center Education Manager (2013 - Present)</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">Created English Environment standards that were implemented nationwide.</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">International Diploma for Language Teaching Management (IDLTM/DELTM)&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">Graduate of the University of Western Ontario (5 year, double major) &nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">in East Asian Studies: China</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">in Spanish Language and Linguistics</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">Chinese Language Proficiency Exam (advanced level, HSK 5) &nbsp;&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";"><strong><span style=" color: rgb(20, 86, 131); ;"><span style="; ">内森</span>·<span style="; ">杰克逊老师</span></span></strong></span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";"><span style="; ">北京教育</span> <span style="; ">中心教育经理（</span>2013<span style="; ">年至今）</span></span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=" ;">创建了在全国范围内实施的英语环境标准。</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";"><span style="; ">高级教师</span>/<span style="; ">初级教育经理</span></span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";"><span style="; ">国际语言教学管理文凭（</span>IDLTM/DelTM<span style="; ">）</span></span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";"><span style="; ">西安大略大学毕业（</span>5<span style="; ">年，双专业）</span></span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";"><span style="; ">东亚研究学士学位：中国</span>&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";"><span style="; ">西班牙语和语言学学士</span>&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";"><span style="; ">汉语水平考试（高级水平，</span>HSK 5<span style="; ">）</span></span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";;white-space: normal">
    <span style=";">&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0;text-align: center;">
    <span style=";"><strong><span style=" ;"><span style="; ">特约</span>Lanie <span style="; ">老师</span></span></strong></span>
</p>
<p style=";;white-space: normal">
    <span style=";">&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0;text-align: center;">
    <img src="https://www.yjyculture.com/commonimg/base/1_11.png"  /><span style=";"><strong><span style=" ;">&nbsp;</span></strong></span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";"><span style="; ">国际段课程</span><strong><span style=" color: rgb(62, 62, 62); ;">International part</span></strong></span>
</p>
<p style=";;white-space: normal">
    <span style=";">&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";"><strong><span style=" color: rgb(0, 122, 170); ;">Lanie Denslow</span></strong></span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">Founder &amp; Principal</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">World Wise Intercultural Training &amp; Resources</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">Lanie is a graduate of the Protocol School of Washington and holds a BS from Antioch University and both a Masters of International Business and an MBA from Pepperdine University.</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">Lanie’s work in the area of international trade and diplomacy has been recognized by multiple organizations. She received the Spirit of Diplomacy award from Protocol and Diplomacy International - The Protocol Officers Association and the Special Recognition Award for outstanding contributions to the world trade community from the Los Angeles Area Chamber of Commerce.</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";"><strong><span style=" color: rgb(20, 86, 131); ;"><span style="; ">拉尼</span>·<span style="; ">登斯洛</span></span></strong></span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=" ;">创始人兼校长</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=" ;">世界智者跨文化培训与资源</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">Lanie <span style="; ">毕业于华盛顿礼宾学院</span><span style="; ">,</span><span style="; ">拥有安提奥克大学学士学位、国际商业硕士和佩珀丁大学工商管理硕士学位。</span></span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";"><span style="; ">拉尼在国际贸易和外交领域的工作得到了多个组织的承认。她获得了《议定书与外交国际</span>——<span style="; ">礼宾官员协会》颁发的</span><span style="; ">&quot;</span><span style="; ">外交精神奖</span><span style="; ">&quot;,</span><span style="; ">以及洛杉矶地区商会颁发的对世界贸易界的杰出贡献特别表彰奖。</span></span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";;white-space: normal">
    <span style=";">&nbsp;</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";"><strong><span style=" ;">The&nbsp;Outline</span></strong></span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";"><strong><span style="; ">【</span></strong><strong><span style="letter-spacing: 0px; ; ">课程大纲】</span></strong></span>
</p>
<p style=";;white-space: normal">
    <span style=";">&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";text-align: justify;;text-indent: 0;white-space: normal;padding: 0;">
    <span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">Image etiquette teacher comprehensive literacy improvement</span>
</p>
<p style=";text-align: justify;;text-indent: 0;white-space: normal;padding: 0;">
    <span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">- Four-dimensional aesthetics based on micro and macro</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=" ;">形象礼仪师综合素养提升</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">——<span style="; ">基于微观与宏观的四维美学</span></span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">International Image Etiquette</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">Management System Part 1</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";"><span style="; ">国际形象礼仪体系</span>1<span style="; ">商务社交</span></span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">International Image Etiquette&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">Management System Part 2</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";"><span style="; ">国际形象礼仪体系</span>2<span style="; ">涉外文化修养</span></span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">The International Dress Code</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=" ;">国际着装礼仪</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">International Business English</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=" ;">国际形象礼仪商务英语</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">Leadership presentation skills</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=" ;">领导力的演讲技巧与声音塑造</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">Exams Completion</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";"><span style="; ">考试</span> <span style="; ">结业</span></span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=" ;">选修课程：（需另付费）</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">Part 1: &nbsp;<span style="; ">国际西餐礼仪标准</span></span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">Part 2<span style="; ">：中国国宴礼仪标准</span></span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">Part 3: &nbsp;<span style="; ">国际标准交谊舞</span></span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">Part 4<span style="; ">：国际形象礼仪</span><span style="; ">TTT</span></span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">The copyright of this course has been reserved by the national copyright administration.</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">Only for Beijing YJY international cultural development co., Ltd.</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">And authorize the cooperative company to use it.</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">Please do not copy without authorization.</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">If found, legal action will be taken.</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=" ;">本课程介绍内容在国家版权局已申请版权，</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=" ;">仅供北京瑜璟缘国际文化发展有限公司，</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=" ;">及授权合作公司使用。</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=" ;">未经授权，请勿抄袭，</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=" ;">如有发现，必诉诸法律。</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";;white-space: normal">
    <span style=";">&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";"><strong><span style=" ;">The Daily Life of International Image Etiquette Teachers</span></strong></span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";"><strong><span style="; ">国际形象礼仪师的日常</span></strong></span>
</p>
<p style=";;white-space: normal">
    <span style=";">&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0;text-align: center;">
    <img  src="https://www.yjyculture.com/commonimg/base/1_12.png"  />
</p>
<p style=";;white-space: normal;padding: 0;text-align: center;">
    <span style=" ;">东软集团国际商务礼仪及</span>
</p>
<p style=";;white-space: normal;padding: 0;text-align: center;">
    <span style=" ;">职业形象塑造</span>
</p>
<p style=";;white-space: normal">
    <span style=";">&nbsp;</span>
</p>
<p style=";;white-space: normal">
    <span style=";">&nbsp;</span>
</p>
<p style=";;white-space: normal;text-align: center;">
    <img  src="https://www.yjyculture.com/commonimg/base/1_13.png" />
</p>
<p style=";;white-space: normal;padding: 0;text-align: center;">
    <span style=" ;">瑜璟缘国际</span>
</p>
<p style=";;white-space: normal;padding: 0;text-align: center;">
    <span style=" ;">第三届国际讲师招募说明会</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";;white-space: normal;text-align: center;">
    <img  src="https://www.yjyculture.com/commonimg/base/1_14.png" word_img="file:////private/var/folders/1d/_3t9lyws27qbmz0mkbtfbbh00000gn/T/com.kingsoft.wpsoffice.mac//ksohtml/wps7x6F0X.png" />
</p>
<p style=";;white-space: normal;padding: 0;text-align: center;">
    <span style=" ;">平安银行聚龙团队</span>
</p>
<p style=";;white-space: normal;padding: 0;text-align: center;">
    <span style=" ;">国际商务职业形象塑造</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";;white-space: normal;text-align: center;">
    <img src="https://www.yjyculture.com/commonimg/base/1_15.png"  />
</p>
<p style=";;white-space: normal;padding: 0;text-align: center;">
    <span style=";">EF<span style=";text-align: center; ">英孚崇文门校区</span></span>
</p>
<p style=";;white-space: normal;padding: 0;text-align: center;">
    <span style=" ;">商务礼仪魅力形象塑造</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";;white-space: normal;text-align: center;">
    <img src="https://www.yjyculture.com/commonimg/base/1_16.png"  />
</p>
<p style=";;white-space: normal;padding: 0;text-align: center;">
    <span style=" ;">一带一路国际经贸</span>
</p>
<p style=";;white-space: normal;padding: 0;text-align: center;">
    <span style=" ;">文化交流高峰论坛</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";;white-space: normal;text-align: center;">
    <img  src="https://www.yjyculture.com/commonimg/base/1_17.png"  />
</p>
<p style=";;white-space: normal;padding: 0;text-align: center;">
    <span style=" ;">英法德意西餐</span>
</p>
<p style=";;white-space: normal;padding: 0;text-align: center;">
    <span style=" ;">男士国际形象礼仪</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";;white-space: normal;text-align: center;">
    <img  src="https://www.yjyculture.com/commonimg/base/1_18.png"/>
</p>
<p style=";;white-space: normal;padding: 0;text-align: center;">
    <span style=";">IBIE<span style="; ">首届国际商务形象礼仪</span></span>
</p>
<p style=";;white-space: normal;padding: 0;text-align: center;">
    <span style=" ;">精英论坛现场</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";;white-space: normal;text-align: center;">
    <img src="https://www.yjyculture.com/commonimg/base/1_19.png" />
</p>
<p style=";;white-space: normal;padding: 0;text-align: center;">
    <span style=";">IBIE<span style="; ">国际形象礼仪文化传播大使</span></span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";;white-space: normal;text-align: center;">
    <img src="https://www.yjyculture.com/commonimg/base/1_20.png" />
</p>
<p style=";;white-space: normal;padding: 0;text-align: center;">
    <span style=";">IBIE IIC<span style="; ">国际形象顾问集训营</span></span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";;white-space: normal;text-align: center;">
    <img  src="https://www.yjyculture.com/commonimg/base/1_21.png"  />
</p>
<p style=";;white-space: normal;padding: 0;text-align: center;">
    <span style=" ;">瑜璟缘国际与国瓷永丰源</span>
</p>
<p style=";;white-space: normal;padding: 0;text-align: center;">
    <span style=";"><span style="; ">文化</span>IP“<span style="; ">瓷袍秀</span><span style="; ">”</span><span style="; ">首秀</span></span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";text-align: center;;white-space: normal">
    <span style=" ;">Something we need to know</span>
</p>
<p style=";;white-space: normal;text-align: center;">
    <span style=" ;"><br/></span><img  src="https://www.yjyculture.com/commonimg/base/1_22.png" word_img="file:////private/var/folders/1d/_3t9lyws27qbmz0mkbtfbbh00000gn/T/com.kingsoft.wpsoffice.mac//ksohtml/wpsfndMvb.png" /><span style=" ;"><br/><br/></span><img  src="https://www.yjyculture.com/commonimg/base/1_23.png"  /><span style=" ;"><br/><br/></span><img src="https://www.yjyculture.com/commonimg/base/1_24.png" word_img="file:////private/var/folders/1d/_3t9lyws27qbmz0mkbtfbbh00000gn/T/com.kingsoft.wpsoffice.mac//ksohtml/wpsXud9m4.png" />
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";">Every positive, upward person.</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";">are very willing to study hard by themselves</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";">Not only can it improve self-awareness?</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";">People who are better able to spread their culture and create more needs</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";">But</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";">Suffering from not finding</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";">The right way to realize the inner pursuit of beauty</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";">Over here</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";">IBIE wants to be a guide</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";">Share cutting-edge expertise</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";">Help you to become an outstanding international image etiquette lecturer</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";">Work with you to design a beautiful image for people;</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";">popularize etiquette knowledge;</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";">Spreading civilizational literacy;</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";">Breeding love,</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";">Spreading the beauty!</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";"><span style="; ">每一个积极</span> <span style="; ">向上的人</span></span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=" ;">都非常愿意通过自身努力学习</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=" ;">不仅可以提升自我认知</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=" ;">更能传播文化造诣更多需求的人</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=" ;">但</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=" ;">苦于找不到</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=" ;">正确的方式去实现内心对美的追求</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=" ;">在这里</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";"><strong><span style=" color: rgb(62, 62, 62); ;">IBIE<span style="; ">愿作为引路人</span></span></strong></span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=" ;">分享前沿尖端的专业知识</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=" ;">助您成为一名出色的国际形象礼仪讲师</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=" ;">与您一起为人们设计美好形象；</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=" ;">普及礼仪知识；</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=" ;">散播文明素养；</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";"><strong><span style="color: rgb(20, 86, 131); ; ">传递爱，</span></strong></span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";"><strong><span style="color: rgb(20, 86, 131); ; ">传递美！</span></strong></span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";;white-space: normal">
    <span style=";">&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0;text-align: center;">
    <span style=";"><strong><span style=" ;">Course fees</span></strong></span>
</p>
<p style=";;white-space: normal;padding: 0;text-align: center;">
    <span style=";"><strong><span style="; ">【课程费用】</span></strong></span>
</p>
<p style=";;white-space: normal">
    <span style=";">&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";">Course fees for 2019 <span style="; ">￥</span><span style="; ">15,980/person</span></span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";">Tax included: <span style="; ">￥</span><span style="; ">16,938/person</span></span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";">(Includes exams, invoices, and $500 International IBIE Certification Certificate Fee)</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";">Duty-free: <span style="; ">￥</span><span style="; ">15,980/person</span></span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";">(Includes exam, $500 International IBIE Certification Certificate Fee)</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";">Book 30 days in advance to register</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";">Enjoy the offer: cash direct lysing at <span style="; ">￥</span><span style="; ">1,000/person</span></span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";">The above fees include:</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";">Teaching material handout fees, expert review fees, authorization qualifications</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";">Inter-class coffee, desserts, refreshments, etc.</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";">2020<span style="; ">年课程费用￥</span><span style="; ">15</span><span style="; ">，</span><span style="; ">980/</span><span style="; ">人</span></span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";"><strong><span style=" color: rgb(20, 86, 131); ;"><span style="; ">含税：￥</span>16<span style="; ">，</span><span style="; ">938/</span><span style="; ">人</span></span></strong></span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";"><strong><span style=" color: rgb(20, 86, 131); ;"><span style="; ">免税：￥</span>15<span style="; ">，</span><span style="; ">980/</span><span style="; ">人</span></span></strong></span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";"><strong><span style=" color: rgb(20, 86, 131); ;"><span style="; ">提前</span>30<span style="; ">天预约报名</span></span></strong></span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";"><strong><span style=" color: rgb(20, 86, 131); ;"><span style="; ">享优惠</span> <span style="; ">：现金直减￥</span>1<span style="; ">，</span><span style="; ">000/</span><span style="; ">人</span></span></strong></span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";"><strong><span style="; ">以上费用均包含：</span></strong></span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";"><strong><span style="color: rgb(20, 86, 131); ; ">教材讲义费、专家评审费、授权资格</span></strong></span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";"><strong><span style="color: rgb(20, 86, 131); ; ">课间咖啡、甜品、茶点、</span></strong><strong><span style="color: rgb(20, 86, 131); letter-spacing: 0px; ; ">等</span></strong></span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";"><strong><span style=" letter-spacing: 0px; ;">Start</span></strong></span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";"><strong><span style="letter-spacing: 0px; ; ">【开课日期】</span></strong></span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style="letter-spacing: 0px; ;">&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=" letter-spacing: 0px; ;">（七天、精品小班）</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<table cellspacing="0" width="360" style='margin: 0 auto'>
    <tbody>
        <tr class="firstRow">
            <td width="190" valign="bottom" colspan="4" style="padding: 3px 7px; border-width: 1px; background: rgb(252, 252, 252);">
                <p style=";text-align: center;">
                    <span style=";"><strong><span style=" ;">IBIE&nbsp;<span style="; ">国际认证双语形象礼仪师</span><span style="; ">&nbsp; </span><span style="; ">研修班</span></span></strong></span>
                </p>
                <p style=";text-align: center;">
                    <span style=";"><strong><span style=" letter-spacing: 0px; ; ;">&nbsp; 2020<span style="; ">年 课程排期表</span></span></strong></span>
                </p>
            </td>
        </tr>
        <tr>
            <td width="32" valign="bottom" style="padding: 3px 7px; border-left-width: 1px; border-right-width: 1px; border-top: none; border-bottom-width: 1px; background: rgb(247, 250, 255);">
                <p style=";text-align: center;">
                    <span style=" ;">1月</span>
                </p>
            </td>
            <td width="45" valign="bottom" style="padding: 3px 7px; border-left: none; border-right-width: 1px; border-top-width: 1px; border-bottom-width: 1px; background: rgb(247, 250, 255);">
                <p style=";text-align: center;">
                    <span style=" ;">12.31-1.6</span>
                </p>
            </td>
            <td width="28" valign="bottom" style="padding: 3px 7px; border-left: none; border-right-width: 1px; border-top-width: 1px; border-bottom-width: 1px; background: rgb(247, 250, 255);">
                <p style=";text-align: center;">
                    <span style=" ;">2月</span>
                </p>
            </td>
            <td width="51" valign="bottom" style="padding: 3px 7px; border-left: none; border-right-width: 1px; border-top-width: 1px; border-bottom-width: 1px; background: rgb(247, 250, 255);">
                <p style=";text-align: center;">
                    <span style=" color: rgb(171, 25, 66); ;">不开课</span>
                </p>
            </td>
        </tr>
        <tr>
            <td width="33" valign="bottom" style="padding: 3px 7px; border-left-width: 1px; border-right-width: 1px; border-top: none; border-bottom-width: 1px; background: rgb(252, 252, 252);">
                <p style=";text-align: center;">
                    <span style=" ;">3月</span>
                </p>
            </td>
            <td width="45" valign="bottom" style="padding: 3px 7px; border-left: none; border-right-width: 1px; border-top: none; border-bottom-width: 1px; background: rgb(252, 252, 252);">
                <p style=";text-align: center;">
                    <span style=" ;">疫情期</span>
                </p>
            </td>
            <td width="28" valign="bottom" style="padding: 3px 7px; border-left: none; border-right-width: 1px; border-top: none; border-bottom-width: 1px; background: rgb(252, 252, 252);">
                <p style=";text-align: center;">
                    <span style=" ;">4月</span>
                </p>
            </td>
            <td width="51" valign="bottom" style="padding: 3px 7px; border-left: none; border-right-width: 1px; border-top: none; border-bottom-width: 1px; background: rgb(252, 252, 252);">
                <p style=";text-align: center;">
                    <span style=" ;">疫情期</span>
                </p>
            </td>
        </tr>
        <tr>
            <td width="33" valign="bottom" style="padding: 3px 7px; border-left-width: 1px; border-right-width: 1px; border-top: none; border-bottom-width: 1px; background: rgb(247, 250, 255);">
                <p style=";text-align: center;">
                    <span style=" ;">5月</span>
                </p>
            </td>
            <td width="45" valign="bottom" style="padding: 3px 7px; border-left: none; border-right-width: 1px; border-top: none; border-bottom-width: 1px; background: rgb(247, 250, 255);">
                <p style=";text-align: center;">
                    <span style=" ;">疫情期</span>
                </p>
            </td>
            <td width="28" valign="bottom" style="padding: 3px 7px; border-left: none; border-right-width: 1px; border-top: none; border-bottom-width: 1px; background: rgb(247, 250, 255);">
                <p style=";text-align: center;">
                    <span style=" ;">6月</span>
                </p>
            </td>
            <td width="51" valign="bottom" style="padding: 3px 7px; border-left: none; border-right-width: 1px; border-top: none; border-bottom-width: 1px; background: rgb(247, 250, 255);">
                <p style=";text-align: center;">
                    <span style=" ;">20--26日</span>
                </p>
            </td>
        </tr>
        <tr>
            <td width="33" valign="bottom" style="padding: 3px 7px; border-left-width: 1px; border-right-width: 1px; border-top: none; border-bottom-width: 1px; background: rgb(252, 252, 252);">
                <p style=";text-align: center;">
                    <span style=" ;">7月</span>
                </p>
            </td>
            <td width="45" valign="bottom" style="padding: 3px 7px; border-left: none; border-right-width: 1px; border-top: none; border-bottom-width: 1px; background: rgb(252, 252, 252);">
                <p style=";text-align: center;">
                    <span style=" ;">20--26日</span>
                </p>
            </td>
            <td width="28" valign="bottom" style="padding: 3px 7px; border-left: none; border-right-width: 1px; border-top: none; border-bottom-width: 1px; background: rgb(252, 252, 252);">
                <p style=";text-align: center;">
                    <span style=" ;">8月</span>
                </p>
            </td>
            <td width="51" valign="bottom" style="padding: 3px 7px; border-left: none; border-right-width: 1px; border-top: none; border-bottom-width: 1px; background: rgb(252, 252, 252);">
                <p style=";text-align: center;">
                    <span style=" ;">24--30日</span>
                </p>
            </td>
        </tr>
        <tr>
            <td width="12" valign="bottom" style="padding: 3px 7px; border-left-width: 1px; border-right-width: 1px; border-top: none; border-bottom-width: 1px; background: rgb(247, 250, 255);">
                <p style=";text-align: center;">
                    <span style=" ;">9月</span>
                </p>
            </td>
            <td width="45" valign="bottom" style="padding: 3px 7px; border-left: none; border-right-width: 1px; border-top: none; border-bottom-width: 1px; background: rgb(247, 250, 255);">
                <p style=";text-align: center;">
                    <span style=" ;">20--26日</span>
                </p>
            </td>
            <td width="28" valign="bottom" style="padding: 3px 7px; border-left: none; border-right-width: 1px; border-top: none; border-bottom-width: 1px; background: rgb(247, 250, 255);">
                <p style=";text-align: center;">
                    <span style=" ;">10月</span>
                </p>
            </td>
            <td width="51" valign="bottom" style="padding: 3px 7px; border-left: none; border-right-width: 1px; border-top: none; border-bottom-width: 1px; background: rgb(247, 250, 255);">
                <p style=";text-align: center;">
                    <span style=" ;">18--24日</span>
                </p>
            </td>
        </tr>
        <tr>
            <td width="12" valign="bottom" style="padding: 3px 7px; border-left-width: 1px; border-right-width: 1px; border-top: none; border-bottom-width: 1px; background: rgb(252, 252, 252);">
                <p style=";text-align: center;">
                    <span style=" ;">11月</span>
                </p>
            </td>
            <td width="45" valign="bottom" style="padding: 3px 7px; border-left: none; border-right-width: 1px; border-top: none; border-bottom-width: 1px; background: rgb(252, 252, 252);">
                <p style=";text-align: center;">
                    <span style=" ;">22--28日</span>
                </p>
            </td>
            <td width="28" valign="bottom" style="padding: 3px 7px; border-left: none; border-right-width: 1px; border-top: none; border-bottom-width: 1px; background: rgb(252, 252, 252);">
                <p style=";text-align: center;">
                    <span style=" ;">12月</span>
                </p>
            </td>
            <td width="51" valign="bottom" style="padding: 3px 7px; border-left: none; border-right-width: 1px; border-top: none; border-bottom-width: 1px; background: rgb(252, 252, 252);">
                <p style=";text-align: center;">
                    <span style=" ;">20--26日</span>
                </p>
            </td>
        </tr>
    </tbody>
</table>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";;white-space: normal">
    <span style=";">&nbsp;</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";"><strong><span style=" ;">Fee account</span></strong></span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";"><strong><span style="; ">【费用账号】</span></strong></span>
</p>
<p style=";;white-space: normal">
    <span style=";">&nbsp;</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";">Accounts:</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";">Account Name: Beijing YJY International Cultural Development Co., Ltd.</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";">Bank of China: South Road Branch of Beijing Airport, Bank of China Co., Ltd.</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";">For public account: 332467469772</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";">For private accounts:</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";">13121112777 (Alipay or WeChat)</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=" ;">以下方式任意选择</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";"><strong><span style="; ">对公账户</span></strong><span style="; ">：</span></span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=" ;">账号名称：北京瑜璟缘国际文化发展有限公司</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=" ;">开户行：中国银行股份有限公司北京机场南路支行</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";"><span style="; ">对公账号：</span>332467469772</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";"><strong><span style="; ">对私账户</span></strong><span style="; ">：</span></span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";">13121112777<span style="; ">（同支付宝、微信号均可）</span></span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";text-align: justify;;white-space: normal">
    <span style=";">&nbsp;</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";"><strong><span style=" ;">Registration process</span></strong></span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";"><strong><span style="; ">【报名流程】</span></strong></span>
</p>
<p style=";;white-space: normal">
    <span style=";">&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";">Please contact Assistant Lara to submit the information for:</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";">1, Name</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";">2, Mobile Phone</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";">3, ID Card number, E-mail</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";">4, two-inch white-bottomed document photo</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";"><strong><span style=" color: rgb(20, 86, 131); ;">Payment of a deposit of RMB&nbsp;<span style="; ">￥</span><span style="; ">5,000/person</span></span></strong></span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=" ;">请联系助理老师，提交资料为：</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";">1<span style="; ">、姓名</span></span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";">2<span style="; ">、联系电话</span></span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";">3<span style="; ">、身份证号码、常用邮箱</span></span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";">4<span style="; ">、两寸白底证件照</span></span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";"><strong><span style=" color: rgb(20, 86, 131); ;"><span style="; ">支付定金￥</span>5<span style="; ">，</span><span style="; ">000/</span><span style="; ">人</span></span></strong></span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0;text-align: center;">
    <img width='200' src="https://www.yjyculture.com/commonimg/base/1_25.png" word_img="file:////private/var/folders/1d/_3t9lyws27qbmz0mkbtfbbh00000gn/T/com.kingsoft.wpsoffice.mac//ksohtml/wpsO22MAQ.png" /><span style=" ;">&nbsp;</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";"><strong><span style=" color: rgb(20, 86, 131); ;">Warm Tip:</span></strong><span style=";">&nbsp;</span></span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";">Contact assistant Lara to make an appointment for a class!</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";"><strong><span style="color: rgb(20, 86, 131); ; ">温馨提示：</span></strong><span style="color: rgb(62, 62, 62); ; ">报名学员较多</span></span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style="color: rgb(62, 62, 62); ;"><span style="color: rgb(62, 62, 62); ; ">请大家提前联系助理老师</span> <span style="color: rgb(62, 62, 62); ; ">预约排课！</span></span>
</p>
<p style=";;white-space: normal;text-align: center;">
    <img src="https://www.yjyculture.com/commonimg/base/1_26.png" word_img="file:////private/var/folders/1d/_3t9lyws27qbmz0mkbtfbbh00000gn/T/com.kingsoft.wpsoffice.mac//ksohtml/wps1ajAyT.png" /><span style=" ;">&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";">Come on!</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";">Great not only history, but also the future of you!</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";">Looking forward to IBIE Meeting you</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=" ;">来吧！</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=" ;">伟大的不止历史，也可以是未来的你！</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";"><strong><span style="color: rgb(20, 86, 131); ; ">期待在</span></strong></span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";"><strong><span style=" color: rgb(20, 86, 131); ;">IBIE</span></strong></span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";"><strong><span style="color: rgb(20, 86, 131); ; ">与你相遇</span></strong></span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";"><strong><span style=" ;">-END-</span></strong></span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";">(All images of this article are owned by Beijing YJY International Cultural Development Co., Ltd., and may not be reproduced and published without permission)</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=" ;">（本文所有图片归北京瑜景瑜国际文化发展有限公司所有，未经允许不得擅自转载和刊用）</span>
</p>
<p style=";;white-space: normal;padding: 0;text-align: center;">
    <img src="https://www.yjyculture.com/commonimg/base/1_27.png" /><span style=" ;">&nbsp;</span>
</p>
<p style=";text-align: center;;text-indent: 0;white-space: normal;padding: 0;;text-align: center;">
    <img  src="https://www.yjyculture.com/commonimg/base/1_28.png" /><span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">&nbsp;</span>
</p>
<p style=";;white-space: normal">
    <span style=";">&nbsp;</span>
</p>
<p style=";text-align: justify;;white-space: normal">
    <span style=";">&nbsp;</span>
</p>
<p>
    <br/>
</p>`,
    gjxxgw: `<h2 style="margin: 0 0 9px;;text-indent: 0;white-space: normal;padding: 0;">
    <strong><span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">招生：</span>IBIE IIC认证专业国际形象顾问</span></strong>
</h2>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">形象是浅浅的表达，礼仪是深深的自律。</span></span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;">Image is light&nbsp;expression. Etiquette is profound&nbsp;self-discipline.</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;">——&nbsp;Angelina Pang</span>
</p>
<p style=";text-align: center;;text-indent: 0;white-space: normal;padding: 0;;text-align: center;">
    <img src="https://www.yjyculture.com/commonimg/base/2_1.png" word_img="file:////private/var/folders/1d/_3t9lyws27qbmz0mkbtfbbh00000gn/T/com.kingsoft.wpsoffice.mac/wps-chutingting/ksohtml/wpsCtBhJi.png" style="background:url(/static/plugins/UEditor/lang/zh-cn/images/localimage.png) no-repeat center center;border:1px solid #ddd"/><span style=";color: rgb(51, 51, 51);letter-spacing: 0;">&nbsp;</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style="">&nbsp;</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style="">&nbsp;</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;">IBIE国际形象礼仪组织协会，是国内首家测评国际形象礼仪标准与测试的组织机构。</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">全新的理念、全新的课程体系、国际化视角，结合国情打造权威国际形象礼仪测评标准。</span></span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style="">&nbsp;</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;">IBIE International Image Etiquette Organizing Committee&nbsp;is the first evaluation of international image etiquette standards and testing of the organization. New concept, new curriculum system, international perspective, combined with national conditions to create authoritative international image etiquette evaluation standards.</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style="">&nbsp;</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <strong><span style=";color: rgb(51, 51, 51);letter-spacing: 0;;">IBIE在中国</span></strong>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <strong><span style=";color: rgb(51, 51, 51);letter-spacing: 0;;">IBIE IN CHINA</span></strong>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">国际形象礼仪组织协会</span> · 中国北京</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;">International&nbsp;Image Etiquette&nbsp;Organizing Committee·&nbsp;Beijing&nbsp;China</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">由北京瑜璟缘国际文化发展有限公司组织协调</span></span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;">Coordinated by Beijing YJY&nbsp;International Cultural Development Co., Ltd.</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style="">&nbsp;</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <strong><span style=";color: rgb(51, 51, 51);letter-spacing: 0;;">IBIE在美国</span></strong>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <strong><span style=";color: rgb(51, 51, 51);letter-spacing: 0;;">IBIE IN&nbsp;THE USA</span></strong>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">美国</span> · 加州州立理工大学延展学院</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;">United States California State Polytechnic University Extension College</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">联合国际形象认证协会</span></span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;">Association of Image Consultants International</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">主要覆盖范围包括培训、认证、在线学习和考试的职业培训测评机构</span></span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;">Vocational training assessment institutions whose main coverage includes training, certification, online learning and examinations</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style="">&nbsp;</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0;text-align: center;">
    <img src="https://www.yjyculture.com/commonimg/base/2_2.png" word_img="file:////private/var/folders/1d/_3t9lyws27qbmz0mkbtfbbh00000gn/T/com.kingsoft.wpsoffice.mac/wps-chutingting/ksohtml/wps91cM6T.png" style="background:url(/static/plugins/UEditor/lang/zh-cn/images/localimage.png) no-repeat center center;border:1px solid #ddd"/><span style="">&nbsp;</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style="">&nbsp;</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <strong><span style=";color: rgb(51, 51, 51);letter-spacing: 0;">IBIE理念The concept of IBIE</span></strong>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;">IBIE 恪守“学做结合、学以致用、品德为本、自我突破”的理念，让学员端正学习心态，努力突破自我，学有所成、学以致用，不断在职业化和专业化的方向，一路前进。</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style="">&nbsp;</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;">IBIE adhere to the &quot;learning to do the combination, learning to use, moral-oriented, self-breakthrough&quot; concept, so that students correct learning mentality, and strive to break through the self, learning to use, and constantly in the direction of professionalism and specialization, all the way forward.</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style="">&nbsp;</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style="">&nbsp;</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <strong><span style=";color: rgb(51, 51, 51);letter-spacing: 0;;"><span style="">国际形象顾问认证</span></span></strong>
</p>
<p style=";;white-space: normal;padding: 0">
    <strong><span style=";color: rgb(51, 51, 51);letter-spacing: 0;;">International Image Consultant Certification</span></strong>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style="">&nbsp;</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0;text-align: center;">
    <img  src="https://www.yjyculture.com/commonimg/base/2_3.png" word_img="file:////private/var/folders/1d/_3t9lyws27qbmz0mkbtfbbh00000gn/T/com.kingsoft.wpsoffice.mac/wps-chutingting/ksohtml/wps0RxvHX.png" style="background:url(/static/plugins/UEditor/lang/zh-cn/images/localimage.png) no-repeat center center;border:1px solid #ddd"/><span style="">&nbsp;</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style="">&nbsp;</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <strong><span style=";color: rgb(51, 51, 51);letter-spacing: 0;;"><span style="">证书：</span>IBIE国际形象礼仪组织协会标准与测评</span></strong>
</p>
<p style=";;white-space: normal;padding: 0">
    <strong><span style=";color: rgb(51, 51, 51);letter-spacing: 0;;">IBIE INTERNATIONAL&nbsp;IMAGE ETIQUETTE&nbsp;STANDARDS&nbsp;&amp;&nbsp;EVALUATION</span></strong>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style="">&nbsp;</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0;text-align: center;">
    <img  src="https://www.yjyculture.com/commonimg/base/2_4.png" word_img="file:////private/var/folders/1d/_3t9lyws27qbmz0mkbtfbbh00000gn/T/com.kingsoft.wpsoffice.mac/wps-chutingting/ksohtml/wpsNkRh1e.png" style="background:url(/static/plugins/UEditor/lang/zh-cn/images/localimage.png) no-repeat center center;border:1px solid #ddd"/><span style="">&nbsp;</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">国际形象顾问认证课程，两个阶段：</span></span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;">①IBIE IIC&nbsp; &nbsp;国际形象顾问&nbsp;（国内课程）</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;">②IBIE SIIC&nbsp;国际高级形象顾问（国际课程）</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;;">International Image Consultant Certification Course, in two phases:</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;;">(1) IBIE IIC International Image Consultant (Domestic Course)</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;;">(2) IBIE SIIC International Senior Image Consultant (International Course)</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style="">&nbsp;</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0;text-align: center;">
    <img src="https://www.yjyculture.com/commonimg/base/2_5.png" word_img="file:////private/var/folders/1d/_3t9lyws27qbmz0mkbtfbbh00000gn/T/com.kingsoft.wpsoffice.mac/wps-chutingting/ksohtml/wpsjg768K.png" style="background:url(/static/plugins/UEditor/lang/zh-cn/images/localimage.png) no-repeat center center;border:1px solid #ddd"/><span style="">&nbsp;</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style="">&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <strong><span style=";color: rgb(51, 51, 51);letter-spacing: 0;;">IBIE形象顾问课程目标&nbsp;IBIE Image Consultant&nbsp;Course Objectives</span></strong>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style="">&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <strong><span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">认知美、</span></span></strong><span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">得体的塑造和维护自身形象，不仅仅会给陌生人留下美好的最初印象，它还是人与人之间重要的沟通工具。</span>Cognitive beauty, decent shaping and maintaining their own image, not only will make a good initial impression on strangers, it is also an important communication tool between people</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style="">&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <strong><span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">了解美、</span></span></strong><span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">个人形象，不再仅仅是个人的事情，它承载着家庭、组织、乃至国家的形象定位，同时个人形象还会影响到组织的发展。所以，现代社会，保持和维护良好的个人形象，是每个社会人都应该具备的基本素养。</span>Understanding beauty, personal image, is no longer just a personal matter, it carries the family, organization, and even the image of the country positioning, while personal image will also affect the development of the organization. Therefore, in modern society, maintaining and maintaining a good personal image is the basic quality that every society should have.</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style="">&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <strong><span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">学习美、</span></span></strong><span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">美学意识并不是天生的，它是通过后天大量的美学知识积累及时尚讯息的收集与美感培养来达成。</span>Learning beauty, aesthetic consciousness is not innate, it is through the day after day a large amount of aesthetic knowledge accumulation and fashion information collection and aesthetic training to achieve</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style="">&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <strong><span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">提升美、</span></span></strong><span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">并不是每个人都能找到自己最适合的形象定位，错误的形象定位及塑造往往会将个人推向成功的反向，成为生活及事业上的阻碍。</span>To enhance beauty, not everyone can find their most suitable image positioning, wrong image positioning and shaping will often push the individual to success in reverse, become a barrier to life and career</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style="">&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <strong><span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">热爱美、</span></span></strong><span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">越来越多的人开始意识到与其耗费大量的精力及财力去塑造一个不适合自己的个人形象，</span> <span style="">不如寻求专业形象礼仪塑造师的帮助来塑造专属于自己的得体形象。</span>Love beauty, more and more people began to realize that rather than spend a lot of energy and financial resources to create a personal image not suitable for their own, it is better to seek professional image etiquette stylist&#39;s help to create their own decent image.</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style="">&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
    <strong><span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">创造美、</span></span></strong><span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">拥有一双善于发现美的眼睛，提升审美，以专业的国际形象顾问的角度创造大美。</span>Create beauty, have a pair of good at finding beauty eyes, improve the aesthetic, to professional international image consultant sing of the beauty.</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style="">&nbsp;</span>
</p>
<p style=";;white-space: normal;text-align: center;">
    <img src="https://www.yjyculture.com/commonimg/base/2_6.png" word_img="file:////private/var/folders/1d/_3t9lyws27qbmz0mkbtfbbh00000gn/T/com.kingsoft.wpsoffice.mac/wps-chutingting/ksohtml/wpsNRHF6l.png" style="background:url(/static/plugins/UEditor/lang/zh-cn/images/localimage.png) no-repeat center center;border:1px solid #ddd"/>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";letter-spacing: 0;">IBIE IIC第二期国际形象顾问毕业合影留念</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style="">&nbsp;</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0;text-align: center;">
    <img src="https://www.yjyculture.com/commonimg/base/2_7.png" word_img="file:////private/var/folders/1d/_3t9lyws27qbmz0mkbtfbbh00000gn/T/com.kingsoft.wpsoffice.mac/wps-chutingting/ksohtml/wpsG0Xa10.png" style="background:url(/static/plugins/UEditor/lang/zh-cn/images/localimage.png) no-repeat center center;border:1px solid #ddd"/><span style=";letter-spacing: 0;;display: block;">IBIE IIC第三期国际形象顾问毕业合影留念</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style="">&nbsp;</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0;text-align: center;">
    <img src="https://www.yjyculture.com/commonimg/base/2_8.png" word_img="file:////private/var/folders/1d/_3t9lyws27qbmz0mkbtfbbh00000gn/T/com.kingsoft.wpsoffice.mac/wps-chutingting/ksohtml/wpsFHDHaP.png" style="background:url(/static/plugins/UEditor/lang/zh-cn/images/localimage.png) no-repeat center center;border:1px solid #ddd"/>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";letter-spacing: 0;">IBIE IIC第五期国际形象顾问毕业合影留念</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style="">&nbsp;</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0;text-align: center;">
    <img  src="https://www.yjyculture.com/commonimg/base/2_9.png" word_img="file:////private/var/folders/1d/_3t9lyws27qbmz0mkbtfbbh00000gn/T/com.kingsoft.wpsoffice.mac/wps-chutingting/ksohtml/wpsvmQhiE.png" style="background:url(/static/plugins/UEditor/lang/zh-cn/images/localimage.png) no-repeat center center;border:1px solid #ddd"/>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style=";letter-spacing: 0;">IBIE IIC第六期国际形象顾问毕业合影留念</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style="">&nbsp;</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <strong><span style=";color: rgb(51, 51, 51);letter-spacing: 0;;">IBIE形象顾问课程内容IBIE Image Consultant&nbsp;Course Content</span></strong>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">国际色彩体系</span>International Color System</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">人体色</span></span><span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">彩密码</span>Human color password</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">色彩技能</span>Color skills</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">人物风格管理</span>Character Style Management</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">男士风格诊断</span>Men&#39;s Style Diagnostics</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">服饰风格精准分析</span>Accurate analysis of clothing style</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">美妆法则</span>Beauty Rule</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">人物发型法则</span>Hair&nbsp;style&nbsp;skill</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">服饰搭配发展史</span>History of clothing matching</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">多维搭配美学</span>Multi-dimensional aesthetics</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">国际品牌商场实战</span>Practice&nbsp;International brand shopping malls</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">绅士文化</span>Gentleman&#39;s Culture</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">奢侈品文化与品鉴</span>Luxury Culture and Taste</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">形象美学沙龙解析</span>Image Aesthetics Salon Analysis</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">世界名品电影品鉴</span>World Famous Film Tasting</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">多维目测能量转化</span>Multi-dimensional visual energy&nbsp;</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">考核</span>conversion Assessment</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;">&nbsp;</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <strong><span style=";color: rgb(51, 51, 51);letter-spacing: 0;;">IBIE形象顾问授课时长Days</span></strong>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style="">&nbsp;</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;">7天&nbsp;</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style="">&nbsp;</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <strong><span style=";color: rgb(51, 51, 51);letter-spacing: 0;;">IBIE形象顾问授课方法</span></strong>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">精品小班：</span>8-10人授课</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">理实结合：导师指导示范与学员互动全方位演练</span></span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">视频记录：多次回放方式</span> <span style="">让所学所用不断得到加固</span></span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0;text-align: center;">
    <img src="https://www.yjyculture.com/commonimg/base/2_10.png" word_img="file:////private/var/folders/1d/_3t9lyws27qbmz0mkbtfbbh00000gn/T/com.kingsoft.wpsoffice.mac/wps-chutingting/ksohtml/wps1WC9xC.png" style="background:url(/static/plugins/UEditor/lang/zh-cn/images/localimage.png) no-repeat center center;border:1px solid #ddd"/><span style="">&nbsp;</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <span style="">&nbsp;</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <strong><span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">开课时间：（七天</span> <span style="">精品小班）</span></span></strong>
</p>
<table cellspacing="0" width="451" style="margin: 0 auto;">
    <tbody>
        <tr class="firstRow">
            <td width="223" valign="top" colspan="4" style="padding: 3px 7px; border-width: 1px; background: rgb(252, 252, 252);">
                <p style=";text-align: center;">
                    <strong><span style="">IBIE IIC国际形象顾问集训营</span></strong><span style=""><br/></span><strong><span style="">2020年 课程排期表</span></strong>
                </p>
            </td>
        </tr>
        <tr>
            <td width="34" valign="top" style="padding: 3px 7px; border-left-width: 1px; border-right-width: 1px; border-top: none; border-bottom-width: 1px; background: rgb(247, 250, 255);">
                <p style=";">
                    <span style="">1月</span>
                </p>
            </td>
            <td width="56" valign="top" style="padding: 3px 7px; border-left: none; border-right-width: 1px; border-top-width: 1px; border-bottom-width: 1px; background: rgb(247, 250, 255);">
                <p style=";">
                    <span style="color: rgb(255, 0, 0);">春 节</span>
                </p>
            </td>
            <td width="32" valign="top" style="padding: 3px 7px; border-left: none; border-right-width: 1px; border-top-width: 1px; border-bottom-width: 1px; background: rgb(247, 250, 255);">
                <p style=";">
                    <span style="">2月</span>
                </p>
            </td>
            <td width="63" valign="top" style="padding: 3px 7px; border-left: none; border-right-width: 1px; border-top-width: 1px; border-bottom-width: 1px; background: rgb(247, 250, 255);">
                <p style=";">
                    <span style="color: rgb(255, 0, 0);">不开课</span>
                </p>
            </td>
        </tr>
        <tr>
            <td width="32" valign="top" style="padding: 3px 7px; border-left-width: 1px; border-right-width: 1px; border-top: none; border-bottom-width: 1px; background: rgb(252, 252, 252);">
                <p style=";">
                    <span style="">3月</span>
                </p>
            </td>
            <td width="56" valign="top" style="padding: 3px 7px; border-left: none; border-right-width: 1px; border-top: none; border-bottom-width: 1px; background: rgb(252, 252, 252);">
                <p style=";">
                    <span style="">疫情原因暂停</span>
                </p>
            </td>
            <td width="32" valign="top" style="padding: 3px 7px; border-left: none; border-right-width: 1px; border-top: none; border-bottom-width: 1px; background: rgb(252, 252, 252);">
                <p style=";">
                    <span style="">4月</span>
                </p>
            </td>
            <td width="63" valign="top" style="padding: 3px 7px; border-left: none; border-right-width: 1px; border-top: none; border-bottom-width: 1px; background: rgb(252, 252, 252);">
                <p style=";">
                    <span style="">疫情原因暂停</span>
                </p>
            </td>
        </tr>
        <tr>
            <td width="34" valign="top" style="padding: 3px 7px; border-left-width: 1px; border-right-width: 1px; border-top: none; border-bottom-width: 1px; background: rgb(247, 250, 255);">
                <p style=";">
                    <span style="">5月</span>
                </p>
            </td>
            <td width="56" valign="top" style="padding: 3px 7px; border-left: none; border-right-width: 1px; border-top: none; border-bottom-width: 1px; background: rgb(247, 250, 255);">
                <p style=";">
                    <span style="background: rgb(252, 252, 252)">疫情原因暂停</span>
                </p>
            </td>
            <td width="32" valign="top" style="padding: 3px 7px; border-left: none; border-right-width: 1px; border-top: none; border-bottom-width: 1px; background: rgb(247, 250, 255);">
                <p style=";">
                    <span style="">6月</span>
                </p>
            </td>
            <td width="63" valign="top" style="padding: 3px 7px; border-left: none; border-right-width: 1px; border-top: none; border-bottom-width: 1px; background: rgb(247, 250, 255);">
                <p style=";">
                    <span style="">8-14日</span>
                </p>
            </td>
        </tr>
        <tr>
            <td width="34" valign="top" style="padding: 3px 7px; border-left-width: 1px; border-right-width: 1px; border-top: none; border-bottom-width: 1px; background: rgb(252, 252, 252);">
                <p style=";">
                    <span style="">7月</span>
                </p>
            </td>
            <td width="56" valign="top" style="padding: 3px 7px; border-left: none; border-right-width: 1px; border-top: none; border-bottom-width: 1px; background: rgb(252, 252, 252);">
                <p style=";">
                    <span style="">6-12日</span>
                </p>
            </td>
            <td width="32" valign="top" style="padding: 3px 7px; border-left: none; border-right-width: 1px; border-top: none; border-bottom-width: 1px; background: rgb(252, 252, 252);">
                <p style=";">
                    <span style="">8月</span>
                </p>
            </td>
            <td width="63" valign="top" style="padding: 3px 7px; border-left: none; border-right-width: 1px; border-top: none; border-bottom-width: 1px; background: rgb(252, 252, 252);">
                <p style=";">
                    <span style="">10-16日</span>
                </p>
            </td>
        </tr>
        <tr>
            <td width="33" valign="top" style="padding: 3px 7px; border-left-width: 1px; border-right-width: 1px; border-top: none; border-bottom-width: 1px; background: rgb(247, 250, 255);">
                <p style=";">
                    <span style="">9月</span>
                </p>
            </td>
            <td width="56" valign="top" style="padding: 3px 7px; border-left: none; border-right-width: 1px; border-top: none; border-bottom-width: 1px; background: rgb(247, 250, 255);">
                <p style=";">
                    <span style="">7-13日</span>
                </p>
            </td>
            <td width="32" valign="top" style="padding: 3px 7px; border-left: none; border-right-width: 1px; border-top: none; border-bottom-width: 1px; background: rgb(247, 250, 255);">
                <p style=";">
                    <span style="">10月</span>
                </p>
            </td>
            <td width="63" valign="top" style="padding: 3px 7px; border-left: none; border-right-width: 1px; border-top: none; border-bottom-width: 1px; background: rgb(247, 250, 255);">
                <p style=";">
                    <span style="">5-11日</span>
                </p>
            </td>
        </tr>
        <tr>
            <td width="33" valign="top" style="padding: 3px 7px; border-left-width: 1px; border-right-width: 1px; border-top: none; border-bottom-width: 1px; background: rgb(252, 252, 252);">
                <p style=";">
                    <span style="">11月</span>
                </p>
            </td>
            <td width="56" valign="top" style="padding: 3px 7px; border-left: none; border-right-width: 1px; border-top: none; border-bottom-width: 1px; background: rgb(252, 252, 252);">
                <p style=";">
                    <span style="">9-15日</span>
                </p>
            </td>
            <td width="32" valign="top" style="padding: 3px 7px; border-left: none; border-right-width: 1px; border-top: none; border-bottom-width: 1px; background: rgb(252, 252, 252);">
                <p style=";">
                    <span style="">12月</span>
                </p>
            </td>
            <td width="63" valign="top" style="padding: 3px 7px; border-left: none; border-right-width: 1px; border-top: none; border-bottom-width: 1px; background: rgb(252, 252, 252);">
                <p style=";">
                    <span style="">7-13日</span>
                </p>
            </td>
        </tr>
    </tbody>
</table>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style="">&nbsp;</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <strong><span style=";color: rgb(51, 51, 51);letter-spacing: 0;;">IBIE形象顾问学员支持</span></strong>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">我们将于集训营后终身给予学员课后跟踪、指导、扶持、线上随时解决学员在实际教学中存在的困惑，不让任何一名学员宝贵的时间消磨在探索的路上。帮助您结业即可着手运用。</span></span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0;text-align: center;">
    <img  src="https://www.yjyculture.com/commonimg/base/2_11.png" word_img="file:////private/var/folders/1d/_3t9lyws27qbmz0mkbtfbbh00000gn/T/com.kingsoft.wpsoffice.mac/wps-chutingting/ksohtml/wpsq28BKf.png" style="background:url(/static/plugins/UEditor/lang/zh-cn/images/localimage.png) no-repeat center center;border:1px solid #ddd"/><span style="">&nbsp;</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style="">&nbsp;</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <strong><span style=";color: rgb(51, 51, 51);letter-spacing: 0;;">IBIE形象顾问适合人群</span></strong>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;">1.</span><span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">国际礼仪培训师</span></span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;">2.</span><span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">从事整体形象设计</span>.形象顾问专职形象设计师</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;">3.</span><span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">各大专院校形象礼仪教师</span></span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;">4.</span><span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">各大专院校未来有意向成为形象顾问在校学生</span></span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;">5.</span><span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">渴望突破自我、希望职业转型形象顾问的人士</span></span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;">6.</span><span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">希望自我形象提升，实现人生价值的人士</span></span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;">7.</span><span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">渴望助他人改变形象，热爱形象顾问职业人士</span></span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;">8.</span><span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">心理咨询顾问，健康管理师</span></span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <strong><span style=";color: rgb(51, 51, 51);letter-spacing: 0;;">IBIE形象顾问就业前景</span></strong>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;">1.</span><span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">自主创业开办形象设计公司</span>.形象设计工作室；</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;">2.</span><span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">为政要、企业家、主持人、明星做形象顾问；</span></span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;">3.</span><span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">为企事业单位做企业文化指导和培训；</span></span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;">4.</span><span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">就职院校担任形象设计教学工作；</span></span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;">5.</span><span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">为剧组、电视台主持人做造型设计；</span></span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;">6.</span><span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">为化妆品店、服装店做形象设计督导</span>.</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style="">&nbsp;</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <strong><span style=";color: rgb(51, 51, 51);letter-spacing: 0;;">IBIE导师介绍</span></strong>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <strong><span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">逄天舒（</span>Angelina）</span></strong>
</p>
<p style=";;white-space: normal;text-align: center;">
    <img  src="https://www.yjyculture.com/commonimg/base/2_12.png" word_img="file:////private/var/folders/1d/_3t9lyws27qbmz0mkbtfbbh00000gn/T/com.kingsoft.wpsoffice.mac/wps-chutingting/ksohtml/wpsFXKKBI.png" style="background:url(/static/plugins/UEditor/lang/zh-cn/images/localimage.png) no-repeat center center;border:1px solid #ddd"/><span style="">&nbsp;</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style="">&nbsp;</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">第一位将国际形象礼仪概念引入中国的人</span></span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;">IBIE国际形象礼仪组织协会全球理事长</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">国际商务形象礼仪标准与测评体系测评员</span></span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">国家文化部认证高级形象管理师</span></span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">国际形象礼仪</span>[N+1]课程创研人</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">美国加州州立理工大学受邀访问学者</span></span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">美国</span>AICI IMMIE Award 奖项国内大陆唯一获奖者</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">美国加州大学</span>UCLA特约礼仪师</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">北京瑜璟缘国际文化发展有限公司</span>CEO</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">共青团北京市委员会礼仪讲师（公益）</span></span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">对外经贸大学客座讲师</span></span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">英孚教育</span> <span style="">特约合作双语讲师</span></span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;">2022北京冬奥组委特约咨询礼仪师</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;;"><span style="font-family:微软雅黑">天舒老师在国际形象礼仪</span></span><span style=";color: rgb(51, 51, 51);letter-spacing: 0;;">N+1课程创研中做出了巨大贡献，是国际形象礼仪文化交流项目发起人、</span><span style="color: rgb(51, 51, 51);letter-spacing: 0;;">IBIE</span><span style=";color: rgb(51, 51, 51);letter-spacing: 0;;"><span style="font-family:微软雅黑">协会理事长、国际双语讲师、美国加州</span></span><span style="color: rgb(51, 51, 51);letter-spacing: 0;;">Pomona</span><span style=";color: rgb(51, 51, 51);letter-spacing: 0;;"><span style="font-family:微软雅黑">大学受邀访问学者，从事多年礼仪培训及形象管理行业，一直热爱钻研形象礼仪领域，为诸多</span></span><span style="color: rgb(51, 51, 51);letter-spacing: 0;;">500</span><span style=";color: rgb(51, 51, 51);letter-spacing: 0;;"><span style="font-family:微软雅黑">强企业及大学提供专业的国际形象礼仪培训课程。擅长针对行业定制礼仪课程。</span></span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style="">&nbsp;</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <strong><span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">王云（</span>Sunny Yun Wang）</span></strong>
</p>
<p style=";;white-space: normal;text-align: center;">
    <img src="https://www.yjyculture.com/commonimg/base/2_13.png" word_img="file:////private/var/folders/1d/_3t9lyws27qbmz0mkbtfbbh00000gn/T/com.kingsoft.wpsoffice.mac/wps-chutingting/ksohtml/wps8amg43.png" style="background:url(/static/plugins/UEditor/lang/zh-cn/images/localimage.png) no-repeat center center;border:1px solid #ddd"/><span style=";letter-spacing: 0;">&nbsp;</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0;">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">知名时尚形象管理专家、形象顾问导师</span></span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0;">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">最受企业欢迎培训师誉畅销书作家服装设计师</span></span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0;">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">形象搭配魔方</span>(ICMC)系统创始人</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0;">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;">IBIE国际形象礼仪组织协会理事</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0;">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">中国服装设计师协会专业委员会委员</span></span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0;">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">历任国际形象顾问协会全球理事、北京分会主席</span></span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0;">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">国际认证形象专家</span> AICI CIP</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0;">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">华盛顿全球个人形象顾问大奖唯一华人获得者</span></span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0;">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">美国</span> BODY BEAUTIFUL学院风格大师认证顾问</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0;">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">美国约形象资讯中心认证顾问</span></span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0;">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">法国形象教练学院认证顾问</span></span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0;">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">加拿大形象学院沟通管理认证顾问</span></span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0;">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">清华大学培训中心、北京大学精英班特邀讲师</span></span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0;">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">北京电视台、中国教育台特邀形象顾问讲师</span></span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0;">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">出版著作</span>10余本，行业中出版著作最多的形象顾问导师之一</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0;">
    <span style=";letter-spacing: 0;">&nbsp;</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style="">&nbsp;</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
    <strong><span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">董畅（</span>Charm）</span></strong>
</p>
<p style=";;white-space: normal;text-align: center;">
    <img  src="https://www.yjyculture.com/commonimg/base/2_14.png" word_img="file:////private/var/folders/1d/_3t9lyws27qbmz0mkbtfbbh00000gn/T/com.kingsoft.wpsoffice.mac/wps-chutingting/ksohtml/wpsDhxGNq.jpg" style="background:url(/static/plugins/UEditor/lang/zh-cn/images/localimage.png) no-repeat center center;border:1px solid #ddd"/><span style="">&nbsp;</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0;">
    <span style=";letter-spacing: 0;">&nbsp;</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0;">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;">IBIE 国际形象礼仪组织协会形象讲师</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0;">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;">AICI 国际形象顾问协会北京分会副主席</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0;">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">中韩美业大赛评委</span></span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0;">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">辽美协化妆专业委员会秘书长</span></span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0;">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">国际认证形象礼仪讲师</span></span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0;">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">国际认证形象顾问</span></span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0;">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">国际认证芳疗项目管理师</span></span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0;">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">国家美容教师资质</span></span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0;">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">国家美容高级技师资质</span></span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0;">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">国家形象设计师高级资质</span></span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0;">
    <span style=";letter-spacing: 0;">&nbsp;</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <strong><span style=";color: rgb(51, 51, 51);letter-spacing: 0;;"><span style="">课程费用</span></span></strong>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">售价￥</span>12,980/人&nbsp; 提前一个月报课可优享￥8,980/人</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style=";color: rgb(249, 110, 87);letter-spacing: 0;"><span style="">注：如需开具发票，请自行承担税点。</span></span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style="">&nbsp;</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <strong><span style=";color: rgb(51, 51, 51);letter-spacing: 0;;"><span style="">支付费用</span></span></strong>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">以下方式任意选择</span></span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">对公账户：</span></span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">账号名称：北京瑜璟缘国际文化发展有限公司</span></span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">开户行：中国银行股份有限公司北京机场南路支行</span>&nbsp;&nbsp;</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">对公账号：</span>332467469772</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">对私账户：支付宝账户：</span>13121112777，逄天舒</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style="">&nbsp;</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <strong><span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">报名流程</span></span></strong>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">请联系助理电话（微信）：</span>13366266117</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">提交资料为：</span></span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;">1.</span><span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">中文姓名、英文姓名</span></span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;">2.</span><span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">联系电话、常用邮箱</span></span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;">3.</span><span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">身份证号码</span></span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;">4.</span><span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">两寸白底证件照、半身正面职业白底形象照电子版</span></span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;">&nbsp; &nbsp;支付定金￥2000元</span>
</p>
<p style=";;white-space: normal;text-align: center;">
    <img src="https://www.yjyculture.com/commonimg/base/2_15.png" word_img="file:////private/var/folders/1d/_3t9lyws27qbmz0mkbtfbbh00000gn/T/com.kingsoft.wpsoffice.mac/wps-chutingting/ksohtml/wpsEa7mIM.png" style="background:url(/static/plugins/UEditor/lang/zh-cn/images/localimage.png) no-repeat center center;border:1px solid #ddd"/><span style="">&nbsp;</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <strong><span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">声明</span></span></strong>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">本公司所有课程均已申请版权保护，详情咨询北京隆安律师事务所，我们崇尚知识分享，但不推荐抄袭掠夺。转载请注明出处，商务合作请拨打</span>+8613121112777.</span>
</p>
<p style=";text-align: justify;;white-space: normal;text-align: center;">
    <img src="https://www.yjyculture.com/commonimg/base/2_16.png" word_img="file:////private/var/folders/1d/_3t9lyws27qbmz0mkbtfbbh00000gn/T/com.kingsoft.wpsoffice.mac/wps-chutingting/ksohtml/wps2p9I9E.png" style="background:url(/static/plugins/UEditor/lang/zh-cn/images/localimage.png) no-repeat center center;border:1px solid #ddd"/><span style="">&nbsp;</span>
</p>
<p>
    <br/>
</p>`
,gjlys: `<h2 style="margin: 0 0 9px;;text-indent: 0;white-space: normal;padding: 0;">
<span style=";"><strong><span style="color: rgb(51, 51, 51); letter-spacing: 0px;"><span style="">招生：</span>IBIE EET测评国际礼仪师——北京、厦门</span></strong></span>
</h2>
<p style=";;white-space: normal;padding: 0">
<span style=";"><strong><span style=" ;">&nbsp;</span></strong></span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
<span style=";"><strong><span style=" ;">Life is not measured by the number of breaths we take, but by the moments that take our breath away.</span></strong></span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
<span style=";"><strong><span style="; ">生命不在于你活了多久，而在于你经历了多少令人怦然心动的瞬间。</span></strong></span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
<span style=";"><strong><span style="; ">我们无法改变身边人的品性和素质，难以消除人与人之间的认知差距。但你终会明白，最好的发声方式，莫过于做最好的自己。好的礼仪可以打开最好的教育未曾打开过的大门。</span></strong></span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
<span style=";"><strong><span style="; ">形象是浅浅的表达，礼仪是深深的自律。</span></strong><strong><span style=" ;"><br/></span></strong><strong><span style=" ;">Image is the light expression, Etiquette is the profound self-discipline.</span></strong></span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">&nbsp;</span>
</p>
<p style=";text-align: center;;white-space: normal;text-align: center;">
<img src="https://www.yjyculture.com/commonimg/base/3_1.png" word_img="file:////private/var/folders/1d/_3t9lyws27qbmz0mkbtfbbh00000gn/T/com.kingsoft.wpsoffice.mac/wps-chutingting/ksohtml/wpsIlGV8z.png" style="background:url(/static/plugins/UEditor/lang/zh-cn/images/localimage.png) no-repeat center center;border:1px solid #ddd"/>
<br/><span style=" ;">IBIE</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
<span style=" ;">高端的学术碰撞;</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
<span style=" ;">严谨的课程体系</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
<span style=" ;">前沿的文化交流;</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
<span style=" ;">权威的讲师团队;</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
<span style=" ;">国际礼仪师IBIE EET测评;</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
<span style=" ;">我们来了!</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=";"><strong><em><span style=" ;">01 </span></em></strong><strong><span style="; "> 您是否在工作和生活中，面临过以下各种问题：</span></strong></span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=";"><span style=" color: rgb(4, 50, 112); ;">●</span><span style="; ">商务接待不了解如何安排细节？正式场合行为举止局促不安？</span></span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=";"><span style=" color: rgb(4, 50, 112); ;">●</span><span style="; ">面试的时候不知道该注意哪些细节，而错失良机？</span></span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
<span style=";"><span style=" color: rgb(4, 50, 112); ;">●</span><span style="; ">学习商务礼仪知识不知道哪个机构靠谱？不知道该学习哪些标准？</span></span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=";"><span style=" color: rgb(4, 50, 112); ;">●</span><span style="; ">学习商务礼仪相关知识总是感觉不够系统和发展前途不明朗？</span></span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=";"><span style=" color: rgb(4, 50, 112); ;">●</span><span style="; ">缺少国际化系统知识学习的机会和对接桥梁？</span></span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=";"><span style=" color: rgb(4, 50, 112); ;">●</span><span style="; ">因为不注意一些细节，被朋友或者同事嘲笑？</span></span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=";"><span style=" color: rgb(4, 50, 112); ;">●</span><span style="; ">想要获得更多个人职业发展的指导和规划，与智者同行？</span></span>
</p>

<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style="">&nbsp;</span>
</p>
<p style=";;white-space: normal;text-align: center;">
<img  src="https://www.yjyculture.com/commonimg/base/3_2.png" word_img="file:////private/var/folders/1d/_3t9lyws27qbmz0mkbtfbbh00000gn/T/com.kingsoft.wpsoffice.mac/wps-chutingting/ksohtml/wpsvXOydo.png" style="background:url(/static/plugins/UEditor/lang/zh-cn/images/localimage.png) no-repeat center center;border:1px solid #ddd"/><span style=" ;">&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">各大公司HR在招聘面试中，通常的惯例是：“如果两个能力差不多的面试者来面试，我肯定会优先录用懂软技能的”。刚进入职场，每个人都是一张白纸；然而没多久我 们就会发现，个体之间的专业积累很快会产生差距。有些人迅速成为“职场多面手”，交给他各种任务都能灵活应对，工作效率高，办事可靠，专业能力越来越强，但更多人则是机械地重复已掌握的经验和技巧，被职场繁琐的工作磨灭了热情，放弃了不断学习。保持空杯心态，走出舒适区，敢于挑战自我，永不满足。人生就像上帝发给你的一幅牌，有好有坏，但出牌的权力在你的手中，将一手烂牌打出王炸的人，才能成为这个时代不灭的强者。</span>
</p>

<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style="">&nbsp;</span>
</p>
<p style=";;white-space: normal;text-align: center;">
<img  src="https://www.yjyculture.com/commonimg/base/3_3.png" word_img="file:////private/var/folders/1d/_3t9lyws27qbmz0mkbtfbbh00000gn/T/com.kingsoft.wpsoffice.mac/wps-chutingting/ksohtml/wpsf4bwDs.jpg" style="background:url(/static/plugins/UEditor/lang/zh-cn/images/localimage.png) no-repeat center center;border:1px solid #ddd"/><span style=" ;">&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=";"><strong><em><span style=" ;">02 </span></em></strong><strong><span style=" color: rgb(5, 45, 100); ;"><span style="; ">成为国际礼仪师获益：</span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span></strong></span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" color: rgb(249, 110, 87); ;">绝佳的机会，学习国际领先的商务/形象礼仪知识，踏上人生快速发展的高速路。</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">▸从中国公民形象向世界公民形象转型的重要契机；</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">▸ 获得国际化顶级标准的形象礼仪测评学习知识体系的绝佳机会；</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">▸加入持续学习不断进步的成长平台，拓展资源，共同进步，充实人脉圈的入门通行证；</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">▸跨国赴美学习顶级国际形象礼仪课程的机会开启；</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">▸200多名专业讲师的精彩知识分享和课程，以及众多国际顶级专家的课程分享和见面机会；</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">▸&nbsp;大量的题库，共同学习和进步，有机会成为知名有影响力的礼仪师；</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">▸成为职场高素养精英人群，企业良好形象礼仪的标杆人物；</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">▸&nbsp;自己变得更加赏心悦目，提升自信，重塑精神面貌；</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">▸&nbsp;形象整洁，对自己认真，让自己的状态更加快速进入状态；</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">▸持续提升获取机遇的比例和质量，让自己的努力更加容易产出结果；</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">▸彰显自身的价值取向，通过自我的认真整理和表达，更快更多的结交志同道合之友；</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">▸&nbsp;充分表达对他人的尊重，提升大家工作或相处时的愉悦心情，让每一天过得更加丰富多彩；</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">▸快速增加一起工作和相处的人的好感，更多的获取“周围人”欣赏的而不是嫌弃的目光。</span>
</p>

<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style="">&nbsp;</span>
</p>
<p style=";;white-space: normal;text-align: center;">
<img  src="https://www.yjyculture.com/commonimg/base/3_4.png" word_img="file:////private/var/folders/1d/_3t9lyws27qbmz0mkbtfbbh00000gn/T/com.kingsoft.wpsoffice.mac/wps-chutingting/ksohtml/wpsiRarMF.jpg" style="background:url(/static/plugins/UEditor/lang/zh-cn/images/localimage.png) no-repeat center center;border:1px solid #ddd"/><span style=" ;">&nbsp;</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
<span style=" ;">礼仪已不仅仅是一个人基本素养的体现，</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
<span style=" ;">同时也是品牌背后的核心竞争力之一。</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
<span style=" ;">IBIE致力于打造国际形象礼仪，</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
<span style=" ;">为精英文化不断注入新鲜能量，</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
<span style=" ;">提升您的国际商务礼仪标准和社交软实力。</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
<span style=" color: rgb(95, 156, 239); ;">审美、创造</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
<span style=" color: rgb(95, 156, 239); ;">形象、礼仪</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
<span style=" ;">领悟到其中精髓并且掌握运用才能够真正算得上成功</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
<span style=" ;">如何提高自身的核心竞争，在职场上驰骋呢？</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
<span style=" ;">在时代飞速发展的今天</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
<span style=" ;">与国际接轨</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
<span style=" ;">也就必须要与国际商务礼仪文化接轨</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
<span style=" ;">由此</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
<span style=";"><strong><span style=" color: rgb(95, 156, 239); ;"><span style="; ">国际礼仪师</span>EET</span></strong></span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
<span style=";"><strong><span style="; ">一个与时俱进的职业应运而生</span></strong></span>
</p>

<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style="">&nbsp;</span>
</p>
<p style=";;white-space: normal;text-align: center;">
<img  src="https://www.yjyculture.com/commonimg/base/3_5.png" word_img="file:////private/var/folders/1d/_3t9lyws27qbmz0mkbtfbbh00000gn/T/com.kingsoft.wpsoffice.mac/wps-chutingting/ksohtml/wpsx8sAUD.jpg" style="background:url(/static/plugins/UEditor/lang/zh-cn/images/localimage.png) no-repeat center center;border:1px solid #ddd"/><span style=" ;">&nbsp;</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
<span style=" ;">国际礼仪师</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
<span style=" ;">他们/她们</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
<span style=" ;">亲切的微笑,优雅的举止;</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
<span style=" ;">周到的礼仪,美好的形象;</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
<span style=" ;">侃侃而谈的气度，儒雅随和的态度;</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
<span style=" ;">无论是日常交际,还是商务往来;</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
<span style=" ;">都会拉近彼此的距离,融洽人们之间的关系;</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
<span style=" ;">使得双方的接触和相处平添暖心的温度.</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
<span style=" ;">闪动着职场领域无所不知的姿态。</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
<span style=" ;">更易达成双方所愿!</span>
</p>

<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style="">&nbsp;</span>
</p>
<p style=";;white-space: normal;text-align: center;">
<img  src="https://www.yjyculture.com/commonimg/base/3_6.png" word_img="file:////private/var/folders/1d/_3t9lyws27qbmz0mkbtfbbh00000gn/T/com.kingsoft.wpsoffice.mac/wps-chutingting/ksohtml/wpsp6BZeJ.png" style="background:url(/static/plugins/UEditor/lang/zh-cn/images/localimage.png) no-repeat center center;border:1px solid #ddd"/><span style=" ;">&nbsp;</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
<span style=" ;">他们的使命和职责是:</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
<span style=" ;">帮助人们规划美好商务形象,普及国际礼仪知识;</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
<span style=" ;">传播文明素养,传递爱心,传播美!</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
<span style=" ;">正是这样的一种身份</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
<span style=" ;">能够帮助你实现个人品牌的形象管理</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
<span style=" ;">如同凤凰涅槃，屹立闪耀于职场之巅</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
<span style=" ;">那么，</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
<span style=" ;">该如何才能成为一名专业的国际礼仪师呢?</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
<span style=" ;">IBIE会告诉你一切关于他们的奥秘</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=";"><strong><em><span style=" ;">03</span></em></strong><strong><span style=" ;">IBIE国际礼仪师EET素质测评课程体系&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;</span></strong></span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">IBIE作为世界领先的行业组织，其知识体系是动态发展的。是在尊重知识产权前提下各国形象顾问和专家的集体智慧。其知识体系特点是：专业、并随着时代发展而持续发展；是多领域专家专业成果的集合和叠加。</span>
</p>

<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style="">&nbsp;</span>
</p>
<p style=";;white-space: normal;text-align: center;">
<img  src="https://www.yjyculture.com/commonimg/base/3_7.png" word_img="file:////private/var/folders/1d/_3t9lyws27qbmz0mkbtfbbh00000gn/T/com.kingsoft.wpsoffice.mac/wps-chutingting/ksohtml/wpsbbHA8I.png" style="background:url(/static/plugins/UEditor/lang/zh-cn/images/localimage.png) no-repeat center center;border:1px solid #ddd"/><span style=" ;">&nbsp;</span>
</p>

<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style="">&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=";"><strong><span style="; ">公认的国际形象礼仪知识体系包括：</span></strong></span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">1， 形象礼仪的概念和功能、形象礼仪的现实价值；行为、语言、文字、着装、态度的管理和考量；</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">2，形象视觉传达领域（如，个人色彩研究，不同体型修正，个性风格表达，公共形象研究，化妆与发型，场合，衣橱管理，陪购等；既有技术层面的专业高度，又有社会成名的角色考量和礼仪规范）非语言信息传达领域 （如，行为举止，礼仪，微表情微动作等肢体语言，商务谈判，沟通心理学等）</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">3，沟通的力量，语言信息传达领域 （如，沟通技巧，语气语调运用，演讲技巧和个人影响力塑造，解决冲突，建立社交关系，压力情绪管理，领导力培养，电话礼仪、微信礼仪等）</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">4，服装专业知识 （如，服装史，面料知识，服装品牌史，流行趋势、时尚等）</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">5，商务形象礼仪。一般职场着装规范、行政人员魅力装扮法则；形象传达的信息及作用：个人层面、企业层面；首因效应“第一印象让您的形象先声夺人”；“55387定律”落地应用，如何产生有效形象价值；商务接待着装及化妆技巧，场合着装定律TPOR 原则的应用；</span>
</p>

<p style=";text-align: justify;;white-space: normal;padding: 0">
    <span style="">&nbsp;</span>
</p>
<p style=";;white-space: normal;text-align: center;">
<img  src="https://www.yjyculture.com/commonimg/base/3_8.png" word_img="file:////private/var/folders/1d/_3t9lyws27qbmz0mkbtfbbh00000gn/T/com.kingsoft.wpsoffice.mac/wps-chutingting/ksohtml/wpspQMCpV.jpg" style="background:url(/static/plugins/UEditor/lang/zh-cn/images/localimage.png) no-repeat center center;border:1px solid #ddd"/><span style=" ;">&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=";"><strong><em><span style=" ;">04</span></em></strong><strong><span style=" ;">IBIE国际礼仪师EET素质测评课程设计 &nbsp;&nbsp;</span></strong></span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0;">
<span style=";"><strong><span style="color: rgb(54, 48, 48); ; ">【课程介绍】</span></strong></span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=";"><strong><span style="color: rgb(120, 172, 254); letter-spacing: -1px; ; ">乔立君老师：</span></strong></span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=";"><strong><span style=" letter-spacing: -1px; ;">Day 1：礼仪文化发展修心之旅</span></strong></span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" letter-spacing: -1px; ;">◇ 中国人文化素养现状剖析</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" letter-spacing: -1px; ;">◇ 国际礼仪师素质素养的必备与提升</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" letter-spacing: -1px; ;">◇ 国际文化差异，差异客户接待，涉外通用礼仪</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" letter-spacing: -1px; ;">◇ 拜访、谈判、行政、位次礼仪、5W1H、国际问候礼仪</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" letter-spacing: -1px; ;">◇ 会议礼仪，参会与会礼仪</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" letter-spacing: -1px; ;">◇ 中餐、酒文化</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" letter-spacing: -1px; ;">&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=";"><strong><span style="color: rgb(120, 172, 254); letter-spacing: -1px; ; ">逄天舒老师：</span></strong></span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=";"><strong><span style=" letter-spacing: -1px; ;">Day 2：形象礼仪——商务着装规避场合着装风险</span></strong></span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" letter-spacing: -1px; ;">◇&nbsp;形象是浅浅的表达，礼仪是深深的自律</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" letter-spacing: -1px; ;">◇&nbsp;第一印象（55387）、TPOR</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" letter-spacing: -1px; ;">◇&nbsp;商务精英领带、丝巾加分项</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" letter-spacing: -1px; ;">◇&nbsp;严肃职场（国际会议、政务会议、殡仪）</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" letter-spacing: -1px; ;">◇&nbsp;一般职场（白骨精、商务谈判、宴请）</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" letter-spacing: -1px; ;">◇&nbsp;社交职场（晚宴、party、赛马、高尔夫）</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" letter-spacing: -1px; ;">◇&nbsp;时尚职场（时尚发布会、演唱会、拍卖会、品鉴会、明星模特场合出席）</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" letter-spacing: -1px; ;">◇&nbsp;生活场合（亲子、闺蜜游、home趴、同学聚会）</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" letter-spacing: -1px; ;">◇&nbsp;国际色彩体系及人物风格管理</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" letter-spacing: -1px; ;">◇&nbsp;国际形象礼仪综合运用</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=";"><strong><span style=" color: rgb(120, 172, 254); letter-spacing: -1px; ;">Day 2晚选修：实战--英法德意西餐礼仪</span></strong></span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=";"><strong><span style="color: rgb(120, 172, 254); letter-spacing: -1px; ; ">陈燕老师</span></strong></span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=";"><strong><span style=" ;">Day 3：精致得道服务礼仪</span></strong></span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=";"><strong><span style=" ;">◇服务礼仪与服务意识</span></strong></span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=";"><strong><span style=" ;">◇服务人员专业形象</span></strong></span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=";"><strong><span style=" ;">◇服务礼仪仪态、手势标准、表情规范</span></strong></span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=";"><strong><span style=" ;">◇沟通礼仪与技巧、主人翁心态、3A原则、 五三一目光区域</span></strong></span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=";"><strong><span style=" ;">◇倾听原则、礼貌用语、服务忌语、沟通禁忌、十字箴言</span></strong></span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=";"><strong><span style=" ;">◇电话礼仪、称呼问候、声音的感染力</span></strong></span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=";"><strong><span style=" ;">◇优质服务流程梳理</span></strong></span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=";"><strong><span style=" ;">◇ 客户投诉处理</span></strong></span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=";"><strong><span style=" ;">◇案例解析、教学方法分享</span></strong></span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=";"><strong><span style=" letter-spacing: -1px; ;">&nbsp;</span></strong></span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=";"><strong><span style="color: rgb(120, 172, 254); letter-spacing: -1px; ; ">陈军燕老师：</span></strong></span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=";"><strong><span style=" letter-spacing: -1px; ;">Day 4：礼仪师授课技巧TTT , Training To Trainer</span></strong></span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" letter-spacing: -1px; ;">◇教学大纲</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" letter-spacing: -1px; ;">一）&nbsp;&nbsp;&nbsp;案例在身边</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" letter-spacing: -1px; ;">二）&nbsp;&nbsp;&nbsp;初始金字塔：原理、叠搭、练习</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" letter-spacing: -1px; ;">三）&nbsp;&nbsp;&nbsp;金字塔的使用：自上而下＆自下而上</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" letter-spacing: -1px; ;">四）&nbsp;&nbsp;&nbsp;培训课纲的制作</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" letter-spacing: -1px; ;">◇教学法：</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" letter-spacing: -1px; ;">一）&nbsp;&nbsp;&nbsp;培训法</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" letter-spacing: -1px; ;">二）&nbsp;&nbsp;&nbsp;利弊分析</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" letter-spacing: -1px; ;">三）&nbsp;&nbsp;&nbsp;教学使用</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" letter-spacing: -1px; ;">◇实效培训</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" letter-spacing: -1px; ;">一）&nbsp;&nbsp;&nbsp;吸睛开场</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" letter-spacing: -1px; ;">二）&nbsp;&nbsp;&nbsp;精彩点评</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" letter-spacing: -1px; ;">三）&nbsp;&nbsp;&nbsp;落地总结与中肯结尾</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" letter-spacing: -1px; ;">四）&nbsp;&nbsp;&nbsp;对学员与讲师的控制</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" letter-spacing: -1px; ;">◇教学梳理与回顾</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" letter-spacing: -1px; ;">一）&nbsp;&nbsp;&nbsp;学习是一个持续的过程</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" letter-spacing: -1px; ;">二）&nbsp;&nbsp;&nbsp;教学过程N阶段</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" letter-spacing: -1px; ;">三）&nbsp;&nbsp;&nbsp;课程细节——提炼萃取</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=";"><strong><span style="color: rgb(95, 156, 239); letter-spacing: -1px; ; ">陈燕老师：</span></strong></span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=";"><strong><span style=" letter-spacing: -1px; ;">Day 4晚选修：国际礼仪师职业规划--方向引导+落地辅导</span></strong></span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" color: rgb(51, 51, 51); letter-spacing: -1px; ;">&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=";"><strong><span style="color: rgb(95, 156, 239); letter-spacing: -1px; ; ">陈燕老师：</span></strong></span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=";"><strong><span style=" ;">Day 5上午：社会公众演说力综合技能提升</span></strong></span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">◇自我确认，破怯立场</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">◇演讲者必备的站立思维能力</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">◇演讲的控场技巧</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">◇特定场景表达技巧</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">◇实战演练</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=";"><strong><span style="color: rgb(95, 156, 239); letter-spacing: -1px; ; ">测评员：</span></strong></span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=";"><strong><span style=" letter-spacing: -1px; ;">Day 5&nbsp;下午：成果转化——实战＋考核</span></strong></span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=";"><strong><span style=" letter-spacing: -1px; ;">&nbsp;</span></strong></span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=";"><strong><em><span style=" ;">05</span></em></strong><strong><span style=" ;">IBIE平台的众多资深专家大师讲师团介绍&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span></strong></span>
</p>
<p style=";;white-space: normal;text-align: center;">
<img src="https://www.yjyculture.com/commonimg/base/3_9.png" word_img="file:////private/var/folders/1d/_3t9lyws27qbmz0mkbtfbbh00000gn/T/com.kingsoft.wpsoffice.mac/wps-chutingting/ksohtml/wpsiS74bB.png" style="background:url(/static/plugins/UEditor/lang/zh-cn/images/localimage.png) no-repeat center center;border:1px solid #ddd"/><span style=" ;">&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=";"><strong><span style=" ;">Ms. Angelina Pang</span></strong></span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">International Image Etiquette (N-1) Course&nbsp;Creator</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">Certified Senior Image Manager, Ministry of Culture</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">Co-Creation and Re-Education International Etiquette Project Exchange Sponsor,</span>
</p>
<p style=";;white-space: normal;padding: 0;">
<span style=";"><strong><span style=" ;"><span style="; ">讲师：逄天舒老师（</span>EET 授课）</span></strong></span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">国际形象礼仪[N+1]课程创研人</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">国家文化部认证高级形象管理师</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">美国联创再教育国际礼仪项目交流发起人</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">美国AICI IMMIE Award奖项国内大陆唯一获奖者</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">IBIE NPO国际形象礼仪组织协会理事长</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">美国加州POMONA理工大学受邀访问学者</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">共青团北京市委员会礼仪讲师（公益）</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">英孚教育 特约合作双语讲师</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">美国加州大学特约礼仪师</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">2022北京冬奥组委特约咨询礼仪师</span>
</p>
<p><span>&nbsp;</span></p>
<p style=";;white-space: normal;text-align: center;">
<img  src="https://www.yjyculture.com/commonimg/base/3_10.png" word_img="file:////private/var/folders/1d/_3t9lyws27qbmz0mkbtfbbh00000gn/T/com.kingsoft.wpsoffice.mac/wps-chutingting/ksohtml/wpsPSjxk2.png" style="background:url(/static/plugins/UEditor/lang/zh-cn/images/localimage.png) no-repeat center center;border:1px solid #ddd"/><span style=" ;">&nbsp;</span>
</p>
<p><span>&nbsp;</span></p>
<p style=";;white-space: normal;padding: 0">
<span style=";"><strong><span style=" ;">Ph.D., Jiali&nbsp;Zhou</span></strong></span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">S.J.D，Doctor of Judicial Science</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">Full-time teacher of the Department of Diplomacy and Foreign Affairs Management, School of Foreign Affairs, School of Foreign Affairs</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">Visiting Professor, Institute of International Business Officials, Central University of Finance and Economics/Ministry of Commerce</span>
</p>
<p style=";;white-space: normal;padding: 0;">
<span style=";"><strong><span style=" ;"><span style="; ">讲师：周加李老师</span>&nbsp;法学博士(CIET 授课）</span></strong></span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">外交学院外交学与外事管理系公共外交教研室专职教师</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">中央财经大学/商务部国际商务官员研修学院客座教授</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">澳大利亚邦德大学与英国牛津大学访问学者</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">国际形象顾问协会AICI认证形象专家</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">国际形象顾问协会北京分会副主席</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">出版专著《对外交流与个人形象塑造》</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">《涉外礼仪》《服装可以简单配饰不能马虎》</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">主编《从理论到实践—外交礼宾礼仪研究》</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">主编《新编公务员外事礼仪》</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">参与撰写《公职人员外事知识手册》, 发表各种论文与文章四十余篇</span>
</p>
<p><span>&nbsp;</span></p>
<p style=";;white-space: normal;text-align: center;">
<img src="https://www.yjyculture.com/commonimg/base/3_11.png" word_img="file:////private/var/folders/1d/_3t9lyws27qbmz0mkbtfbbh00000gn/T/com.kingsoft.wpsoffice.mac/wps-chutingting/ksohtml/wpswIq0G7.png" style="background:url(/static/plugins/UEditor/lang/zh-cn/images/localimage.png) no-repeat center center;border:1px solid #ddd"/><span style=" ;">&nbsp;</span>
</p>
<p><span>&nbsp;</span></p>
<p style=";;white-space: normal;padding: 0">
<span style=";"><strong><span style=" ;">Dr. Linda&nbsp;</span></strong></span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">Associate Professor, Academy of broadcasting Host Art, China Media University</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">The practice of oral communication in the majors of news and communication</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">Master&#39;s Tutor, Language Communication</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">Former CCTV reporter, Chinese and English interview with international dignitaries Dedicated to cross-cultural language communication and public image research for more than ten years.</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=";"><strong><span style=" ;"><span style="; ">讲师：赵琳老师（</span>CIET 授课）</span></strong></span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">中国传媒大学播音主持艺术学院 副教授</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">新闻与传播专业口语传播实务</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">语言传播专业&nbsp;硕士研究生导师</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">曾任央视记者，中英文专访国际政要</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">潜心致力于跨文化语言传播与公众形象研究十余年</span>
</p>
<p><span>&nbsp;</span></p>
<p style=";;white-space: normal;text-align: center;">
<img  src="https://www.yjyculture.com/commonimg/base/3_12.png" word_img="file:////private/var/folders/1d/_3t9lyws27qbmz0mkbtfbbh00000gn/T/com.kingsoft.wpsoffice.mac/wps-chutingting/ksohtml/wpszI9cJH.png" style="background:url(/static/plugins/UEditor/lang/zh-cn/images/localimage.png) no-repeat center center;border:1px solid #ddd"/><span style=" ;">&nbsp;</span>
</p>
<p><span>&nbsp;</span></p>
<p style=";;white-space: normal;padding: 0">
<span style=";"><strong><span style=" ;">Ms. Ni&nbsp;Xiao</span></strong></span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">Master of Arts in Art, Beijing Normal University</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">Teacher of &quot;Shape and Social Etiquette&quot; at Beijing University of Posts and Telecommunications</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">Teaching Director, Courses as &quot;Appreciation of Dance Art&quot; and &quot;Introduction to Art&quot;</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">Participation for the State Patent Office Cofco Group and other government departments</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">Enterprises and institutions as social government etiquette and Knowledge training and lectures on business reception</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=";"><strong><span style=" ;"><span style="; ">讲师：肖倪老师（</span>EET 授课·北京）</span></strong></span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">北京师范大学艺术学硕士学位</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">北京邮电大学《形体与社交礼仪》任课教师</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">曾担任《舞蹈艺术鉴赏》《艺术概论》等课程的教学工作</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">多次为国家专利局、中粮集团等政府部门</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">企事业单位担任社交、政务礼仪和</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">商务接待等知识培训和讲座</span>
</p>
<p><span>&nbsp;</span></p>
<p style=";;white-space: normal;text-align: center;">
<img  src="https://www.yjyculture.com/commonimg/base/3_13.png" style="width:522px;background:url(/static/plugins/UEditor/lang/zh-cn/images/localimage.png) no-repeat center center;border:1px solid #ddd"/><span style=" ;">&nbsp;</span>
</p>
<p><span>&nbsp;</span></p>
<p style=";;white-space: normal;padding: 0">
<span style=";"><strong><span style=" ;">Ms. Junyan Chen</span></strong></span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">International Image Etiquette (N-1) Course&nbsp;Creator</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">Certified Senior Image Manager, Ministry of Culture</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">Co-Creation and Re-Education International Etiquette Project Exchange Sponsor,</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=";"><strong><span style=" ;"><span style="; ">讲师：陈军燕（</span>EET 授课）</span></strong></span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">国际形象礼仪专业委员会专家</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">IBIE国际商务形象礼仪标准与测评导师</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">贵州民族大学客座副教授</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">教育部职业院校教师</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">国际职业经理人协会高级注册培训师</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">高级礼仪培训师</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">中国礼宾礼仪文化专业委员会理事</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">北大EMBA班、猎聘学院等机构特约讲师</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">国际航空协会北亚中国区首席乘务教官</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">中国民航总局客舱乘务监察员资质</span>
</p>
<p><span>&nbsp;</span></p>
<p style=";;white-space: normal;text-align: center;">
<img  src="https://www.yjyculture.com/commonimg/base/3_14.png"  style="width:522px;background:url(/static/plugins/UEditor/lang/zh-cn/images/localimage.png) no-repeat center center;border:1px solid #ddd"/><span style=" ;">&nbsp;</span>
</p>
<p><span>&nbsp;</span></p>
<p style=";;white-space: normal;padding: 0">
<span style=";"><strong><span style=" ;">Ms. Molly Chen&nbsp;</span></strong></span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">IBIE IIC certified international image consultant&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">IBIE International Certified image consultant the first person in Asia&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">Member representative of IBIE international image etiquette Organization Association</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">International image etiquette star gathering lecturer group star lecturer</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=";"><strong><span style=" ;"><span style="; ">讲师：陈燕老师（</span>EET 授课）</span></strong></span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">IBIE IIC 认证国际形象顾问</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">IBIE国际认证形象顾问亚洲第一人</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">IBIE国际形象礼仪组织协会会员代表</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">国际形象礼仪聚星讲师团授星讲师</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">国际形象礼仪商学院高级礼仪讲师</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">春天礼仪创始人</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">语业主持机构创始人</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">志惠说语言艺术中心联合创始人 &nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">中国演出行业演出经纪人</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">漳州政府网《政务访谈》栏目主播</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">海峡两岸主持人大赛专家评委</span>
</p>
<p><span>&nbsp;</span></p>
<p style=";;white-space: normal;text-align: center;">
<img  src="https://www.yjyculture.com/commonimg/base/3_15.png" word_img="file:////private/var/folders/1d/_3t9lyws27qbmz0mkbtfbbh00000gn/T/com.kingsoft.wpsoffice.mac/wps-chutingting/ksohtml/wpsazmG68.png" style="width:522px;background:url(/static/plugins/UEditor/lang/zh-cn/images/localimage.png) no-repeat center center;border:1px solid #ddd"/><span style=" ;">&nbsp;</span>
</p>
<p><span>&nbsp;</span></p>
<p style=";;white-space: normal;padding: 0">
<span style=";"><strong><span style=" ;">Mr. Vincent Qiao&nbsp;&nbsp;</span></strong></span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">Deputy General Manager, Neusoft Group Division;</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">IBIE International Commissioner 2019;</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">20+ years IT Solution &amp; Product Management experiences;</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">Experienced in Global Business Cooperation and Management;</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">Managed business with Microsoft, Honeywell, Amazon, Intel, EMC, Nokia, and etc.</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">Domestic customers cover CMCC, PICC, Wanda, UNIONPAY, Xinhua news agency, Ministry of Labor and Social Security.</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=";"><strong><span style=" ;"><span style="; ">讲师：乔立君老师（</span>EET 授课）</span></strong></span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">东软集团股份有限公司 事业部副总经理；</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">IBIE协会2019年度国际特派委员；</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">20年以上IT解决方案和产品及团队管理经验；</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">长期与微软、霍尼韦尔、亚马逊、英特尔、EMC、诺基亚等系列世界顶级公司进行业务合作。国内客户覆盖中国移动、中国人保、万达集团、中国银联、新华社、劳动和社会保障部等等。</span>
</p>
<p><span>&nbsp;</span></p>
<p style=";;white-space: normal;text-align: center;">
<img  src="https://www.yjyculture.com/commonimg/base/3_16.png" word_img="file:////private/var/folders/1d/_3t9lyws27qbmz0mkbtfbbh00000gn/T/com.kingsoft.wpsoffice.mac/wps-chutingting/ksohtml/wpsTXGjLg.png" style="background:url(/static/plugins/UEditor/lang/zh-cn/images/localimage.png) no-repeat center center;border:1px solid #ddd"/><span style=" ;">&nbsp;</span>
</p>
<p><span>&nbsp;</span></p>
<p style=";;white-space: normal;padding: 0">
<span style=";"><strong><span style=" ;">Mr.Nathan&nbsp;Jackson&nbsp;</span></strong></span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">Beijing&nbsp;Center Education Manager (2013 - Present)</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">Created English Environment standards that were implemented nationwide.</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">International Diploma for Language Teaching Management (IDLTM/DELTM)&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">Graduate of the University of Western Ontario&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">(5 year, double major) &nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">in East Asian Studies: China</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">in Spanish Language and Linguistics</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">Chinese Language Proficiency Exam&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">(advanced level, HSK 5)&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=";"><strong><span style=" ;"><span style="; ">讲师：内森</span>·杰克逊老师（CIET 授课）</span></strong></span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">北京教育中心教育经理(2013年至今)</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">创建了在全国范围内实施的英语环境标准</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">高级教师/初级教育经理, 国际语言教学管理文（IDLTM/DelTM）</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">西安大略大学毕业（5年，双专业）, 东亚研究学士学位</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">中国&nbsp;西班牙语和语言学学士&nbsp;, 汉语水平考试（高级水平，HSK5）</span>
</p>
<p><span>&nbsp;</span></p>
<p style=";;white-space: normal;text-align: center;">
<img src="https://www.yjyculture.com/commonimg/base/3_17.png" word_img="file:////private/var/folders/1d/_3t9lyws27qbmz0mkbtfbbh00000gn/T/com.kingsoft.wpsoffice.mac/wps-chutingting/ksohtml/wpsUwlH6J.png" style="width:522px;background:url(/static/plugins/UEditor/lang/zh-cn/images/localimage.png) no-repeat center center;border:1px solid #ddd"/><span style=" ;">&nbsp;</span>
</p>
<p><span>&nbsp;</span></p>
<p style=";;white-space: normal;padding: 0">
<span style=";"><strong><span style=" ;">Lanie Denslow&nbsp;</span></strong></span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">Founder &amp; Principal</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">World Wise Intercultural Training &amp; Resources</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">Lanie is a graduate of the Protocol School of Washington and holds a BS from Antioch University and both a Masters of International Business and an MBA from Pepperdine University.</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">Lanie’s work in the area of international trade and diplomacy has been recognized by multiple organizations. She received the Spirit of Diplomacy award from Protocol and Diplomacy International - The Protocol Officers Association and the Special Recognition Award for outstanding contributions to the world trade community from the Los Angeles Area Chamber of Commerce.</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=";"><strong><span style=" ;"><span style="; ">特约</span>Lanie 老师（国际段授课）</span></strong></span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">国际段课程International part</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">拉尼·登斯洛创始人兼校长</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">世界智者跨文化培训与资源</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">Lanie 毕业于华盛顿礼宾学院,拥有安提奥克大学学士学位、国际商业硕士和佩珀丁大学工商管理硕士学位。</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">拉尼在国际贸易和外交领域的工作得到了多个组织的承认。她获得了《议定书与外交国际——礼宾官员协会》颁发的&quot;外交精神奖&quot;,以及洛杉矶地区商会颁发的对世界贸易界的杰出贡献特别表彰奖。</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=";"><strong><em><span style=" ;">06 </span></em></strong><strong><span style=" ;"><span style="; ">考取国际礼仪师</span>IBIE EET测评执照&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;</span></strong></span>
</p>
<p><span>&nbsp;</span></p>
<p style=";;white-space: normal;text-align: center;">
<img width="3240" height="812" src="https://www.yjyculture.com/commonimg/base/3_18.png" word_img="file:////private/var/folders/1d/_3t9lyws27qbmz0mkbtfbbh00000gn/T/com.kingsoft.wpsoffice.mac/wps-chutingting/ksohtml/wpscdZbmn.jpg" style="background:url(/static/plugins/UEditor/lang/zh-cn/images/localimage.png) no-repeat center center;border:1px solid #ddd"/><span style=" ;">&nbsp;</span>
</p>
<p><span>&nbsp;</span></p>

<p style=";text-align: center;;white-space: normal;padding: 0;text-align: center;">
<img src="https://www.yjyculture.com/commonimg/base/3_19.png" word_img="file:////private/var/folders/1d/_3t9lyws27qbmz0mkbtfbbh00000gn/T/com.kingsoft.wpsoffice.mac/wps-chutingting/ksohtml/wps0YvZVd.png" style="background:url(/static/plugins/UEditor/lang/zh-cn/images/localimage.png) no-repeat center center;border:1px solid #ddd"/><span style=" ;">&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">IBIE国际礼仪师测评执照由IBIE测评标准颁发，世界500强企业及行业内认可。具有行业标准及企业优选的普世价值。持有IBIE国际标准与测评的系列证书者，具备行业内专业领域学识及知识体系储备，具备从事此行业的资质及相应级别的能力。IBIE国际礼仪师测评执照代表了具备可从事礼仪培训的基本能力与素养。</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=";"><strong><em><span style=" ;">07 </span></em></strong><strong><span style=" ;"><span style="; ">国际礼仪师</span>EET就业前景&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span></strong></span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">◇&nbsp;商务礼仪培训讲师，组织正规商务礼仪培训；</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">◇&nbsp;公司商务或行政管理人员，需要更加专业的礼仪相关知识；</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">◇&nbsp;参加政务或商务会议，接待或进行客户拜访；</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">◇&nbsp;金融、地产、医疗卫生等企事业单位、酒店业务等行政管理及接待服务人员；</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">◇&nbsp;跻身精英人群，月收入过万；</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">◇&nbsp;热爱传播礼仪文化、开展礼仪培训机构的创业人士；</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">◇&nbsp;大学教师及一些向往真、善、美的时代精英。</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">IBIE：INTERNATIONAL BUSINESS IMAGE ETIQUETTE Standards &amp; Evaluation 国际职业商务形象礼仪标准及测评。如果您在学习后顺利结业，那么您将在中国境内国际礼仪师行业占据一席之地，并且可以继续深造IIC/SIIC/CAMC/CIET等课程，通过后可以成为北京瑜璟缘国际文化发展有限公司®签约讲师，并获得赴美深造留学的机会，还可申请IBIE国内分会合作发展共赢的同路人！紧随国际步伐的发展节奏，共同做这个时代的长驱精英！</span>
</p>
<p><span>&nbsp;</span></p>

<p style=";;white-space: normal;text-align: center;">
<img src="https://www.yjyculture.com/commonimg/base/3_20.png" word_img="file:////private/var/folders/1d/_3t9lyws27qbmz0mkbtfbbh00000gn/T/com.kingsoft.wpsoffice.mac/wps-chutingting/ksohtml/wpsY9gtas.jpg" style="background:url(/static/plugins/UEditor/lang/zh-cn/images/localimage.png) no-repeat center center;border:1px solid #ddd"/><span style=" ;">&nbsp;</span>
</p>
<p><span>&nbsp;</span></p>

<p style=";text-align: center;;white-space: normal;padding: 0">
<span style=" ;">如果你很向往国际文化</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
<span style=" ;">那么，</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
<span style=" ;">IBIE国际形象礼仪组织协会</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
<span style=" ;">国际礼仪师将是您的一生中最重要而美好的选择！</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
<span style=" ;">将为您的职业生涯增添无限可能。</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
<span style=" ;">加入一个简约而不简单的群体，用形象来表达，用礼仪来自律，用文化来传承。波澜壮阔，不如一身简单。简单的用文化来滋养，用高级质感来雕琢，用精致的剪裁来勾勒，用穿透岁月的力量，用最美的姿态来绽放。这里是IBIE，一个用知识来承载命运、用形象来掌舵人生的美好家园。</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
<span style=" ;">IBIE协会每周会为大家定期分享微课，协会不定期举办沙龙及商会活动。为表入会诚意，采取会员年费制度，200¥/年/人，一个分享礼仪及形象美学知识、国际文化的平台，我们崇尚知识分享。我们有供大家学习参考的资料，可以共同学习共同讨论共同发展。欢迎有相同价值观的你加入。</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=";"><strong><em><span style=" ;">08</span></em></strong><strong><span style=" ;"><span style="; ">什么是国际形象礼仪组织协会（</span>IBIE）？&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span></strong></span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
<span style=" ;">IBIE国际商务形象礼仪标准与测评，</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
<span style=" ;">International Business Image Etiquette</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
<span style=" ;">Standards &amp; Evaluation,</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">IBIE国际形象礼仪组织协会通过三年的努力取得与美国加州Pomona大学和美国形象顾问组织协会联合开展再教育合作项目，为国内广大礼仪师及领域爱好者提供国际形象礼仪测评。IBIE是经由领域内专家及国内外资深礼仪师与大学教授联合开展的无盈利组织协会，旨在为其领域内人士提供专业认证及组织级别考试和汇聚该领域内有识之士学习成长的一个平台。取得该组织测评执照的老师被业内人士认可并且具备一定的国际教育背景可获得由北京瑜璟缘国际文化发展有限公司提供的申请函为其申请国际课程赴美继续学习。其会员遍布中国和美国，并在北京、福建、辽宁、贵州、湖南和云南等6个省份和地区已设有分会和授权机构。IBIE一直致力于专业形象礼仪行业的教育、发展，并追求卓越。</span>
</p>
<p><span>&nbsp;</span></p>

<p style=";;white-space: normal;text-align: center;">
<img  src="https://www.yjyculture.com/commonimg/base/3_21.png" word_img="file:////private/var/folders/1d/_3t9lyws27qbmz0mkbtfbbh00000gn/T/com.kingsoft.wpsoffice.mac/wps-chutingting/ksohtml/wpshPuIaj.png" style="background:url(/static/plugins/UEditor/lang/zh-cn/images/localimage.png) no-repeat center center;border:1px solid #ddd"/><span style=" ;">&nbsp;</span>
</p>
<p><span>&nbsp;</span></p>

<p style=";;white-space: normal;padding: 0">
<span style=" ;">◇ IBIE是国际领先的专业形象礼仪协会组织；</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">◇&nbsp;是国内咨询顾问最多，专业领域研究最广泛和最深入的组织；</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">◇&nbsp;是国内顶级专家最集中的世界级专业行业协会组织；</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">◇&nbsp;第一家将形象和礼仪结合成一体，系统进行培训的形象礼仪培训组织；</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">◇&nbsp;第一家通过生态模式构建培训体系以及扩大影响力的培训组织；</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">◇&nbsp;参与世界领先的形象礼仪行业发展，并研究和贡献本国 本地区文化与形象、礼仪等多元文化交流；</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">◇&nbsp;获得IBIE国际形象礼仪协会测评，既意味着获得了国际 形象礼仪行业的专业认可，也会走遍天下皆有与您同行的伙伴。</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">通过符合国际化发展前瞻的科学完善的培训体系和考试体制，充分客观的评价和展示学员在国际化形象礼仪方面的专业能力水平和深度。IBIE恪守“学做结合、学以致用、品德为本、自我突破”的理念，让学员端正学习心态，努力突破自我，学有所成、学以致用，不断在职业化和专业化的方向，一路前进。</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">目前在国际范围内推广四大类，七种测评和认证服务：</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">◇&nbsp;Evaluated Etiquette Trainer</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">&nbsp; ( IBIE 测评礼仪师，EET);</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">◇&nbsp;City Alliance Member Corporation</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">（城市联盟成员机构，CAMC课程）；</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">◇&nbsp;International Image Consultant</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">（国际形象顾问，IIC课程）；</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">◇&nbsp;Senior International Image Consultant</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">（高级国际形象顾问，SIIC课程）；</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">◇&nbsp;Certified Image Etiquette Trainer&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">&nbsp; ( IBIE 认证国际形象礼仪培训师简称，CIET )；</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">◇&nbsp;Certified Image Etiquette Professional&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">&nbsp; ( IBIE 认证国际形象礼仪专家，简称 CIEP )；</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">◇&nbsp;Certified Image Etiquette Master</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">&nbsp; ( IBIE 认证国际形象礼仪大师，简称 CIEM )；</span>
</p>
<p><span>&nbsp;</span></p>

<p style=";;white-space: normal;text-align: center;">
<img  src="https://www.yjyculture.com/commonimg/base/3_22.png" word_img="file:////private/var/folders/1d/_3t9lyws27qbmz0mkbtfbbh00000gn/T/com.kingsoft.wpsoffice.mac/wps-chutingting/ksohtml/wpsd7quCV.jpg" style="background:url(/static/plugins/UEditor/lang/zh-cn/images/localimage.png) no-repeat center center;border:1px solid #ddd"/><span style=" ;">&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=";"><strong><span style=" color: rgb(5, 45, 100); ;">IBIE国际形象礼仪包含四大部分&nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;&nbsp;</span></strong></span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">(一)&nbsp;形象礼仪</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">(二)&nbsp;行为举止</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">(三)&nbsp;语音语调</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">(四)&nbsp;国际文化</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">掌握国际形象礼仪标准是当今社会个人职业发展的重要内容，是用人单位考核员工综合职业素养和个人素质的重要参考依据，是对无论个人或者企业培养国际化、综合性人才需求的必要手段。</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">波澜壮阔，不如一身简单。简单的用文化来滋养，用高级质感来雕琢，用精致的剪裁来勾勒，用穿透岁月的力量，用最美的姿态绽放。人生新篇章！</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">IBIE | INTERNATIONAL BUSINESS IMAGE ETIQUETTE CERTIFICATION AND MORE...</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">进入国际主流社交的入场券、职场形象管理的先锋。职场的优雅穿着，来自对未来的向往，来自对自己价值的正确认识，来自内心品格的选择。加入IBIE，我们带领您扬帆启航！</span>
</p>
<p><span>&nbsp;</span></p>

<p style=";;white-space: normal;text-align: center;">
<img src="https://www.yjyculture.com/commonimg/base/3_23.png" word_img="file:////private/var/folders/1d/_3t9lyws27qbmz0mkbtfbbh00000gn/T/com.kingsoft.wpsoffice.mac/wps-chutingting/ksohtml/wpsltN3zo.jpg" style="background:url(/static/plugins/UEditor/lang/zh-cn/images/localimage.png) no-repeat center center;border:1px solid #ddd"/><span style=" ;">&nbsp;</span>
</p>

<p style=";text-align: center;;white-space: normal;padding: 0">
<span style=" ;">每一个积极 向上的人</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
<span style=" ;">都非常愿意通过自身努力学习</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
<span style=" ;">不仅可以提升自我认知</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
<span style=" ;">更能传播文化造诣更多需求的人</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
<span style=" ;">但</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
<span style=" ;">苦于找不到</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
<span style=" ;">正确的方式去实现内心对美的追求</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
<span style=" ;">在这里</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
<span style=" ;">IBIE愿作为引路人</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
<span style=" ;">分享前沿尖端的专业知识</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
<span style=" ;">助您成为一名出色的国际礼仪讲师</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
<span style=" ;">&nbsp;&nbsp;与您一起为人们设计美好形象</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
<span style=" ;">&nbsp;&nbsp;&nbsp;普及礼仪知识</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
<span style=" ;">&nbsp;&nbsp;&nbsp;传播文明素养</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
<span style=" ;">&nbsp;传递爱</span>
</p>
<p><span>&nbsp;</span></p>

<p style=";;white-space: normal;text-align: center;">
<img  src="https://www.yjyculture.com/commonimg/base/3_24.png" word_img="file:////private/var/folders/1d/_3t9lyws27qbmz0mkbtfbbh00000gn/T/com.kingsoft.wpsoffice.mac/wps-chutingting/ksohtml/wpsZlcVvA.jpg" style="background:url(/static/plugins/UEditor/lang/zh-cn/images/localimage.png) no-repeat center center;border:1px solid #ddd"/><span style=" ;">&nbsp;</span>
</p>
<p><span>&nbsp;</span></p>

<p style=";;white-space: normal;padding: 0">
<span style=" ;">【课程费用】</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">2020年课程费用￥9553/人</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">含税：￥9553/人；</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">免税：￥8980</span>
</p>

<p style=";;white-space: normal;padding: 0">
<span style=";"><span style=" color: rgb(95, 156, 239); ;"><span style="; ">提前</span>30天预约报名，现金直降￥1000，</span><span style=" color: rgb(123, 12, 0); ;"><span style="; ">仅售￥</span>7980</span><span style=" color: rgb(95, 156, 239); ;"><span style="; ">（含税：￥</span>8489）；</span></span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">以上费用均包含：教材讲义费、专家评审费、授权资格；课间咖啡、甜品、茶点等；</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=";"><span style=" ;"><span style="; ">现在报名，默认加入</span>IBIE协会，</span><span style=" color: rgb(95, 156, 239); ;"><span style="; ">赠送一年会员资质，赠送</span>52节线上课程免费畅听；</span></span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=";"><span style=" ;"><span style="; ">后续报名学习</span>IIC或CIET、CAMC即可签约北京瑜璟缘国际文化发展有限公司®成为</span><span style="color: rgb(95, 156, 239); ; ">签约讲师亦或赴美深造。</span></span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">【报名流程】</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">提交资料：</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">1、姓名</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">2、联系电话</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">3、身份证号码、常用邮箱</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">4、两寸白底证件照</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" ;">支付定金￥2，000/人</span>
</p>
<p><span>&nbsp;</span></p>

<p style=";text-align: center;;white-space: normal;padding: 0;text-align: center;">
<img width='200' src="https://www.yjyculture.com/commonimg/base/3_25.png" word_img="file:////private/var/folders/1d/_3t9lyws27qbmz0mkbtfbbh00000gn/T/com.kingsoft.wpsoffice.mac/wps-chutingting/ksohtml/wps1uYOR6.png" style="background:url(/static/plugins/UEditor/lang/zh-cn/images/localimage.png) no-repeat center center;border:1px solid #ddd"/><span style=" ;">&nbsp;</span>
<img width='200' src="https://www.yjyculture.com/commonimg/base/3_26.png" word_img="file:////private/var/folders/1d/_3t9lyws27qbmz0mkbtfbbh00000gn/T/com.kingsoft.wpsoffice.mac/wps-chutingting/ksohtml/wpsQVymCl.png" style="background:url(/static/plugins/UEditor/lang/zh-cn/images/localimage.png) no-repeat center center;border:1px solid #ddd"/><span style=" ;">&nbsp;&nbsp; &nbsp; &nbsp;&nbsp;</span>
</p>
<p><span>&nbsp;</span></p>

<p style=";text-align: center;;white-space: normal;padding: 0;text-align: center;">
</p>
<p style=";;white-space: normal;padding: 0;text-align: center;">
<span style=" ;"><span style="; ">课程服务咨询可联系助理老师</span></span>
</p>
<p style=";text-align: center;;text-indent: 0;white-space: normal;padding: 0;background: rgb(254, 255, 255)">
<span style=" letter-spacing: 1px; ;">&nbsp;来吧！</span>
</p>
<p style=";text-align: center;;text-indent: 0;white-space: normal;padding: 0;background: rgb(254, 255, 255)">
<span style=" letter-spacing: 1px; ;">伟大的不止历史，也可以是未来的你！</span>
</p>
<p style=";text-align: center;;text-indent: 0;white-space: normal;padding: 0;background: rgb(254, 255, 255)">
<span style=" letter-spacing: 1px; ;">期待在</span>
</p>
<p style=";text-align: center;;text-indent: 0;white-space: normal;padding: 0;background: rgb(254, 255, 255)">
<span style=" letter-spacing: 1px; ;">IBIE</span>
</p>
<p style=";text-align: center;;text-indent: 0;white-space: normal;padding: 0;background: rgb(254, 255, 255)">
<span style=" letter-spacing: 1px; ;">与你相遇</span>
</p>
<p style=";text-align: center;;text-indent: 0;white-space: normal;padding: 0;background: rgb(254, 255, 255)">
<span style=" letter-spacing: 1px; ;">【每个努力工作的人都有一双隐形的翅膀，All those who work hard are angels.】</span>
</p>
<p style=";text-align: center;;text-indent: 0;white-space: normal;padding: 0;background: rgb(254, 255, 255)">
<span style=" letter-spacing: 1px; ;">IBIE Organizing Committee</span>
</p>
<p style=";;white-space: normal">
<span style=" ;">&nbsp;</span>
</p>
<p style=";text-align: justify;;white-space: normal">
<span style=" ;">&nbsp;</span>
</p>
<p>
<br/>
</p>`
,cslm: `<p style=";;text-indent: 0;white-space: normal;padding: 0;">
<span style=";"><strong><span style=" color: rgb(51, 51, 51); letter-spacing: 0px;">IBIE CAMC城市联盟成员机构测评与督导员认证</span></strong></span>
</p>
<p style=";text-align: center;;text-indent: 0;white-space: normal;padding: 0;">
<span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">IBIE CAMC&nbsp;</span>
</p>
<p style=";text-align: center;;text-indent: 0;white-space: normal;padding: 0;">
<span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">国际形象礼仪机构城市联盟标准测评研修班</span>
</p>
<p style=";text-align: center;;text-indent: 0;white-space: normal;padding: 0;">
<span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">IBIE 国际形象礼仪组织协会欢迎您！</span>
</p>
<p style=";text-align: center;;text-indent: 0;white-space: normal;padding: 0;">
<span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">WELCOME TO&nbsp;IBIE ORGANIZING&nbsp;CORPORATION!</span>
</p>
<p><span>&nbsp;</span></p>
<p style=";text-align: center;;text-indent: 0;white-space: normal;padding: 0;">
<img  src="https://www.yjyculture.com/commonimg/base/4_1.png" word_img="file:////private/var/folders/1d/_3t9lyws27qbmz0mkbtfbbh00000gn/T/com.kingsoft.wpsoffice.mac/wps-chutingting/ksohtml/wpsy72dxn.png" style="background:url(/static/plugins/UEditor/lang/zh-cn/images/localimage.png) no-repeat center center;border:1px solid #ddd"/><span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">&nbsp;</span>
</p>
<p style=";;text-indent: 0;white-space: normal;padding: 0;">
<span style=" letter-spacing: 0px; ;">亲爱的形象礼仪行业从业者朋友，</span>
</p>
<p style=";;text-indent: 0;white-space: normal;padding: 0;">
<span style=" letter-spacing: 0px; ;">Dear Image Etiquette&nbsp;Industry Practitioner Friend,</span>
</p>
<p style=";;text-indent: 0;white-space: normal;padding: 0;">
<span style=" letter-spacing: 0px; ;">您是否感觉到目前工作结果的力不从心？</span>
</p>
<p style=";;text-indent: 0;white-space: normal;padding: 0;">
<span style=" letter-spacing: 0px; ;">Do you feel that the results of your current work are not working?</span>
</p>
<p style=";;text-indent: 0;white-space: normal;padding: 0;">
<span style=" letter-spacing: 0px; ;">希望自己能够提升与行业客户谈判技能？</span>
</p>
<p style=";;text-indent: 0;white-space: normal;padding: 0;">
<span style=" letter-spacing: 0px; ;">Want to improve your negotiating skills with industry customers?</span>
</p>
<p style=";;text-indent: 0;white-space: normal;padding: 0;">
<span style=" letter-spacing: 0px; ;">讲课教材保鲜度不够而深感课程教学结果不理想？</span>
</p>
<p style=";;text-indent: 0;white-space: normal;padding: 0;">
<span style=" letter-spacing: 0px; ;">Lecture materials preservation is not enough and deeply feel that the curriculum teaching results are not ideal?</span>
</p>
<p style=";;text-indent: 0;white-space: normal;padding: 0;">
<span style=" letter-spacing: 0px; ;">开设机构过程繁琐管理模版没有参考？</span>
</p>
<p style=";;text-indent: 0;white-space: normal;padding: 0;">
<span style=" letter-spacing: 0px; ;">The process of opening an institution cumbersome management template has no reference?</span>
</p>
<p style=";;text-indent: 0;white-space: normal;padding: 0;">
<span style=" letter-spacing: 0px; ;">&nbsp;</span>
</p>
<p style=";;text-indent: 0;white-space: normal;padding: 0;">
<span style=" letter-spacing: 0px; ;">IBIE国际形象礼仪组织协会对每一位会员负责，为大家提供在本行业领域纵深发展的专业技能学术知识和本领域职业发展的规划之路。</span>
</p>
<p style=";;text-indent: 0;white-space: normal;padding: 0;">
<span style=" letter-spacing: 0px; ;">三天时长精选的IBIE CAMC课程学习，将帮助您排忧解难，祝您事业腾飞一臂之力！</span>
</p>
<p style=";;text-indent: 0;white-space: normal;padding: 0;">
<span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">The IBIE Organizing Committee&nbsp;is responsible for each member, providing you with academic knowledge of professional skills and career development in the field.</span>
</p>
<p style=";;text-indent: 0;white-space: normal;padding: 0;">
<span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">A selection of three-day IBIE CAMC courses will help you solve your problems and wish you a boost!</span>
</p>
<p style=";;text-indent: 0;white-space: normal;padding: 0;">
<span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">&nbsp;</span>
</p>
<p style="text-align: center;;text-indent: 0;white-space: normal;padding: 0;">
<img  src="https://www.yjyculture.com/commonimg/base/4_2.png" word_img="file:////private/var/folders/1d/_3t9lyws27qbmz0mkbtfbbh00000gn/T/com.kingsoft.wpsoffice.mac/wps-chutingting/ksohtml/wpsFbhO58.png" style="background:url(/static/plugins/UEditor/lang/zh-cn/images/localimage.png) no-repeat center center;border:1px solid #ddd"/><span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">&nbsp;</span>
</p>
<p style=";text-align: justify;;text-indent: 0;white-space: normal;padding: 0;">
<span style=" letter-spacing: 0px; ;">&nbsp;</span>
</p>
<p style=";text-align: justify;;text-indent: 0;white-space: normal;padding: 0;">
<span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">随着中国在国际上地位的影响力越来越高，中国经济体系的壮大，越来越多的企事业单位也同样认识到自身企业形象建设的重要性。我们每天都要面对形形色色的人，如何正确掌握场合礼仪规范，树立良好的政府、企业及个人形象，保持强大的文化竞争力，在日常交往中事事合乎规范，处处表现得体，是每位现代人士事业成功的必修课之一，而国际礼仪培训师的数量随着社会和各企事业单位的需求而逐渐壮大。显而易见，礼仪师培训机构的测评标准被广泛运用其中。</span>
</p>
<p style=";text-align: justify;;text-indent: 0;white-space: normal;padding: 0;">
<span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">IBIE CAMC 国际形象礼仪机构测评标准为整体行业带来螺丝钉般的重要意义和作用。</span>
</p>
<p style=";;text-indent: 0;white-space: normal;padding: 0;">
<span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">With China&#39;s increasing influence in the international arena and the growth of China&#39;s economic system, more and more enterprises and institutions are also aware of the importance of their corporate image building. Every day we have to face all kinds of people, how to correctly grasp the etiquette norms of the occasion, establish a good government, enterprises and personal image, maintain a strong cultural competitiveness, in daily interaction in line with the norms of things, everywhere, is one of the necessary courses for the success of every modern person&#39;s career, And the number of international etiquette trainers with the needs of society and enterprises and institutions and gradually grow.</span>
</p>
<p style=";;text-indent: 0;white-space: normal;padding: 0;">
<span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">Obviously, the evaluation criteria of etiquette training institutions are widely used. IBIE CAMC International Image Etiquette Agency evaluation standards for the overall industry to bring screw-like significance and role.</span>
</p>
<p style=";;text-indent: 0;white-space: normal;padding: 0;">
<span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">&nbsp;</span>
</p>
<p style=";text-align: justify;;text-indent: 0;white-space: normal;padding: 0;">
<span style=";"><strong><span style=" letter-spacing: 0px; ;"><span style="; ">一、什么是</span>IBIE国际形象礼仪组织协会？</span></strong></span>
</p>
<p style=";;text-indent: 0;white-space: normal;padding: 0;">
<span style=" letter-spacing: 0px; ;">「INTERNATIONAL BUSINESS IMAGE ETIQUETTE」STANDARD &amp; EVALUATION是由中国北京形象和礼仪专业领域内专家及老师、北京邮电大学、对外经济贸易大学、首都师范大学、东北大学、美国加州大学洛杉矶分校社科院专家学者、美国加州州立理工大学波莫纳分校及社会各界对该领域抱有兴趣的有识之士共同创建的NPO 无盈利性质组织协会。IBIE 在中国（国际形象礼仪组织协会·&nbsp;中国北京）由北京瑜璟缘国际文化发展有限公司组织协调，在美国由加州州立理工大学延展学院联合国际形象顾问组织协会，共同组织和发起的公益性协会组织。主要覆盖范围包括培训、在线学习和考试等职业软技能测评工作。</span>
</p>
<p style=";;text-indent: 0;white-space: normal;padding: 0;">
<span style=" letter-spacing: 0px; ;">1. What is the IBIE International Image Etiquette Organization Association?</span>
</p>
<p style=";;text-indent: 0;white-space: normal;padding: 0;">
<span style=" letter-spacing: 0px; ;">&quot;INTERNATIONAL BUSINESS IMAGE ETIQUETTE&quot; STANDARD AND EVALUATION is composed of experts and teachers in the field of image and etiquette in Beijing, China, Beijing University of Posts and Telecommunications,&nbsp; University of Foreign Economics and Trade, Capital Normal University, Northeastern University, The NPO Non-Profit Organization Association, co-founded by the UCLA Academy of Social Sciences, the Pomona Campus of California State Polytechnic University, and people from all walks of life interested in the field. IBIE in China (International Image Organizing Corporation&nbsp;Beijing, China) organized by Beijing YJY&nbsp;International Cultural Development Co., Ltd., and in the United States by (U.S. California State Polytechnic University Extension College) in conjunction with the International Image Certification Association, co-organized and launched the organization of public interest associations. The main coverage includes vocational training certification bodies for training, online learning and examinations.</span>
</p>
<p style=";;text-indent: 0;white-space: normal;padding: 0;">
<span style=" letter-spacing: 0px; ;">&nbsp;</span>
</p>
<p style="text-align: center;;text-indent: 0;white-space: normal;padding: 0;">
<img src="https://www.yjyculture.com/commonimg/base/4_3.png" word_img="file:////private/var/folders/1d/_3t9lyws27qbmz0mkbtfbbh00000gn/T/com.kingsoft.wpsoffice.mac/wps-chutingting/ksohtml/wpsupBlOs.png" style="background:url(/static/plugins/UEditor/lang/zh-cn/images/localimage.png) no-repeat center center;border:1px solid #ddd"/><span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">&nbsp;</span>
</p>
<p style=";;text-indent: 0;white-space: normal;padding: 0;">
<span style=" letter-spacing: 0px; ;">&nbsp;</span>
</p>
<p style=";;text-indent: 0;white-space: normal;padding: 0;">
<span style=";"><strong><span style=" letter-spacing: 0px; ;"><span style="; ">二、获得</span>IBIE认证有什么作用呢？</span></strong></span>
</p>
<p style=";;text-indent: 0;white-space: normal;padding: 0;">
<span style=" letter-spacing: 0px; ;">IBIE证书能够代表当今国际形象礼仪行业的职业能力一线水平，可以客观地评价从事国际形象礼仪行业人员的专家级的知识和能力水平，取得这项认可可以获得以下收益：</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" letter-spacing: 0px; ; ;">提升自身国际形象礼仪方面的系统认知</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" letter-spacing: 0px; ; ;">有利出国深造</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" letter-spacing: 0px; ; ;">扩展资源、充实人脉圈</span>
</p>
<p style=";text-align: justify;;text-indent: 0;white-space: normal;padding: 0;">
<span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">2. To obtain IBIE certification what role?</span>
</p>
<p style=";;text-indent: 0;white-space: normal;padding: 0;">
<span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">&nbsp; IBIE certification can represent today&#39;s&nbsp;</span>
</p>
<p style=";;text-indent: 0;white-space: normal;padding: 0;">
<span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">&nbsp; international image etiquette industry&nbsp;</span>
</p>
<p style=";;text-indent: 0;white-space: normal;padding: 0;">
<span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">&nbsp; professional certification level, can&nbsp;</span>
</p>
<p style=";;text-indent: 0;white-space: normal;padding: 0;">
<span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">&nbsp; objectively evaluate the international&nbsp;</span>
</p>
<p style=";;text-indent: 0;white-space: normal;padding: 0;">
<span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">&nbsp; image etiquette industry&nbsp;personnel&nbsp;</span>
</p>
<p style=";;text-indent: 0;white-space: normal;padding: 0;">
<span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">&nbsp; expert level of knowledge and ability&nbsp;</span>
</p>
<p style=";;text-indent: 0;white-space: normal;padding: 0;">
<span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">&nbsp; level, to obtain this certification can&nbsp;</span>
</p>
<p style=";;text-indent: 0;white-space: normal;padding: 0;">
<span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">&nbsp; obtain the following benefits:</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ; ;">Improve seleveling awareness of your international image etiquette</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ; ;">Favorable for further study abroad</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ; ;">Expand resources and enrich your circle</span>
</p>
<p style=";text-align: justify;;text-indent: 0;white-space: normal;padding: 0;">
<span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">&nbsp;</span>
</p>
<p style="text-align: center;;text-indent: 0;white-space: normal;padding: 0;">
<img  src="https://www.yjyculture.com/commonimg/base/4_4.png" word_img="file:////private/var/folders/1d/_3t9lyws27qbmz0mkbtfbbh00000gn/T/com.kingsoft.wpsoffice.mac/wps-chutingting/ksohtml/wpsyTNI2K.png" style="background:url(/static/plugins/UEditor/lang/zh-cn/images/localimage.png) no-repeat center center;border:1px solid #ddd"/><span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">&nbsp;</span>
</p>
<p style=";text-align: justify;;text-indent: 0;white-space: normal;padding: 0;">
<span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">&nbsp;</span>
</p>
<p style=";text-align: justify;;text-indent: 0;white-space: normal;padding: 0;">
<span style=";"><strong><span style=" letter-spacing: 0px; ;"><span style="; ">三、</span>IBIE CAMC课程的目的？</span></strong></span>
</p>
<p style=";text-align: justify;;text-indent: 0;white-space: normal;padding: 0;">
<span style=" letter-spacing: 0px; ;">是为了代表IBIE组织和行业利益，建立沟通桥梁，加强同业联系，建立互信，谋求彼此共同经营发展，促进良性竞争，互传讯息，提高经营能力，优化服务质素及专业水平。</span>
</p>
<p style=";;text-indent: 0;white-space: normal;padding: 0;">
<span style=" letter-spacing: 0px; ;">3. &nbsp;What is the purpose of the IBIE CAMC course?</span>
</p>
<p style=";;text-indent: 0;white-space: normal;padding: 0;">
<span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">It is to represent the interests of IBIE organizations and industries, build bridges of communication, strengthen inter-industry contacts, build mutual trust, seek mutual business development, promote healthy competition, communicate messages, improve operational capabilities, and optimize service quality and professional standards.</span>
</p>
<p style=";text-align: justify;;text-indent: 0;white-space: normal;padding: 0;">
<span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">&nbsp;</span>
</p>
<p style=";text-align: justify;;text-indent: 0;white-space: normal;padding: 0;">
<span style=";"><strong><span style=" letter-spacing: 0px; ;"><span style="; ">四、</span>IBIE CAMC&nbsp;会员城市联盟的作用？</span></strong></span>
</p>
<p style=";text-align: justify;;text-indent: 0;white-space: normal;padding: 0;">
<span style=" letter-spacing: 0px; ;">组团参展进行行业培训、评审，组织游学、参观、考察，推动团队协会成员和组织共同进步。制定行业公约，维护行业权威。有效地矫正问题营业，纠正形象礼仪错误的认知和理念。制定行业标准，测评统一、加强质量和效果等。</span>
</p>
<p style=";text-align: justify;;text-indent: 0;white-space: normal;padding: 0;">
<span style=" letter-spacing: 0px; ;">协调IBIE协会内企业之间的关系，协调与外部企业，甚至外地企业之间的关系与纠纷，协调成员企业与政府部门之间的关系，与相关政府部门交涉，提高协作效果。通过协会组织，及时上情下达，掌握最新信息，了解商机，让成员企业少走弯路。还可与外地行业协会加强联系，结成联盟，共谋发展。</span>
</p>
<p style=";;text-indent: 0;white-space: normal;padding: 0;">
<span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">4.&nbsp;The role of the IBIE CAMC Member Cities Alliance?</span>
</p>
<p style=";;text-indent: 0;white-space: normal;padding: 0;">
<span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">Group participation for industry training, evaluation, organization of tours, visits, inspection, to promote the team association members and organizations to make common progress. Develop industry conventions to safeguard industry authority. Effectively correct the problem of business, correct the image of etiquette error sperception and concept.</span>
</p>
<p style=";;text-indent: 0;white-space: normal;padding: 0;">
<span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">Develop industry standards, unify certification, enhance quality and effectiveness, etc. Coordinate the relationship between enterprises within the IBIE Association, coordinate relations and disputes with external enterprises and even foreign enterprises, coordinate the relationship between member enterprises and government departments, and negotiate with relevant government departments to improve the effectiveness of collaboration. Through the association organization, timely release, grasp the latest information, understand business opportunities, so that member enterprises less detours. It can also strengthen contacts with foreign trade associations, form alliances and seek common development.</span>
</p>
<p style=";text-align: justify;;text-indent: 0;white-space: normal;padding: 0;">
<span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">&nbsp;</span>
</p>
<p style="text-align: center;;text-indent: 0;white-space: normal;padding: 0;">
<img  src="https://www.yjyculture.com/commonimg/base/4_5.png" word_img="file:////private/var/folders/1d/_3t9lyws27qbmz0mkbtfbbh00000gn/T/com.kingsoft.wpsoffice.mac/wps-chutingting/ksohtml/wpsQ1Zxkk.png" style="background:url(/static/plugins/UEditor/lang/zh-cn/images/localimage.png) no-repeat center center;border:1px solid #ddd"/><span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">&nbsp;</span>
</p>
<p style=";text-align: justify;;text-indent: 0;white-space: normal;padding: 0;">
<span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">&nbsp;</span>
</p>
<p style=";;text-indent: 0;white-space: normal;padding: 0;">
<span style=";"><strong><span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;"><span style="; ">五、学习</span>IBIE CAMC 课程对我个人有什么好处？</span></strong></span>
</p>
<p style=";;text-indent: 0;white-space: normal;padding: 0;">
<span style=" letter-spacing: 0px; ;">三天课程学习，无论是讲师还是准备要自己开机构的老师，对于当今社会礼仪培训行业客户谈判流程、大纲设计、PPT制作、话术技巧、标准制定、收费模版、合作方式等都有纯干货式获益，现学即可现用。</span>
</p>
<p style=";;text-indent: 0;white-space: normal;padding: 0;">
<span style=" letter-spacing: 0px; ;">IBIE国际形象礼仪组织协会对每一位会员负责，为大家提供在本行业领域纵深发展的专业技能学术知识和本领域职业发展的规划之路。</span>
</p>
<p style=";;text-indent: 0;white-space: normal;padding: 0;">
<span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">5.&nbsp;What are the benefits to me personally from taking the IBIE CAMC course?</span>
</p>
<p style=";;text-indent: 0;white-space: normal;padding: 0;">
<span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">Three-day course learning, whether the lecturer or ready to open their own institutions of teachers, for today&#39;s social etiquette training industry customer negotiation process, outline design, PPT production, dialectic skills, standard-setting, fee template, cooperation, etc. have pure dry goods-type benefits, ready to use. The IBIE Association of International Image Courtesy Organizations is responsible for each member, providing you with academic knowledge of professional skills and career development in the field.</span>
</p>
<p style=";;text-indent: 0;white-space: normal;padding: 0;">
<span style=" letter-spacing: 0px; ;">&nbsp;</span>
</p>
<p style="text-align: center;;text-indent: 0;white-space: normal;padding: 0;">
<img src="https://www.yjyculture.com/commonimg/base/4_6.png" word_img="file:////private/var/folders/1d/_3t9lyws27qbmz0mkbtfbbh00000gn/T/com.kingsoft.wpsoffice.mac/wps-chutingting/ksohtml/wpsab5uDx.png" style="background:url(/static/plugins/UEditor/lang/zh-cn/images/localimage.png) no-repeat center center;border:1px solid #ddd"/><span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">&nbsp;</span>
</p>
<p style=";;text-indent: 0;white-space: normal;padding: 0;">
<span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">&nbsp;</span>
</p>
<p style=";;text-indent: 0;white-space: normal;padding: 0;">
<span style=";"><strong><span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;"><span style="; ">六、学习</span>IBIE CAMC&nbsp;课程对我的机构有哪些收益</span></strong></span>
</p>
<p style=";;text-indent: 0;white-space: normal;padding: 0;">
<span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">于专业的形象礼仪培训机构而言，IBIE CAMC&nbsp;顺利结业意味着该机构具备了执行国际形象礼仪培训及授课标准，该机构将获得由IBIE国际形象礼仪组织协会认证颁发的&nbsp;IBIE CAMC&nbsp;国际形象礼仪机构城市联盟标准测评机构牌匾。具备使用IBIE协会会徽的权益及提交IBIE分会审核权益。分会审核达标者可举办协会学习日活动或沙龙以及开展IBIE协会系列课程的资质。</span>
</p>
<p style=";;text-indent: 0;white-space: normal;padding: 0;">
<span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">6.&nbsp;What are the benefits to my organization from taking the IBIE CAMC course For professional image etiquette training institutions, the successful completion of IBIE CAMC means that the institution has the implementation of international image etiquette training and teaching standards, the institution will be certified by the IBIE International Image Etiquette Organization Association issued by the IBIE CAMC International Image Etiquette Body City Alliance Standard Evaluation Body plaque. Have the right to use the IBIE Association emblem and submit the IBIE chapter audit rights.</span>
</p>
<p style=";;text-indent: 0;white-space: normal;padding: 0;">
<span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">Chapter reviewers can organize Association Study Day events or salons, as well as the qualifications for IBIE Association certification courses.</span>
</p>
<p style=";text-align: justify;;text-indent: 0;white-space: normal;padding: 0;">
<span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">&nbsp;</span>
</p>
<p style=";text-align: justify;;text-indent: 0;white-space: normal;padding: 0;">
<span style=";"><strong><span style="color: rgb(51, 51, 51); letter-spacing: 0px; ; ">七、授课老师全程由天舒老师执教</span></strong></span>
</p>
<p style=";;text-indent: 0;white-space: normal;padding: 0;">
<span style=";"><strong><span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">7.&nbsp;The teacher is taught by Angelina Pang&nbsp;throughout the course</span></strong></span>
</p>
<p style=";text-align: justify;;text-indent: 0;white-space: normal;padding: 0;">
<span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">&nbsp;</span>
</p>
<p style=";;text-indent: 0;white-space: normal;padding: 0;;text-align: center;">
<img  src="https://www.yjyculture.com/commonimg/base/4_7.png" word_img="file:////private/var/folders/1d/_3t9lyws27qbmz0mkbtfbbh00000gn/T/com.kingsoft.wpsoffice.mac/wps-chutingting/ksohtml/wpsJeobAm.png" style="width:522px;background:url(/static/plugins/UEditor/lang/zh-cn/images/localimage.png) no-repeat center center;border:1px solid #ddd"/><span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">&nbsp;</span>
</p>
<p style=";text-align: justify;;text-indent: 0;white-space: normal;padding: 0;">
<span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">&nbsp;</span>
</p>
<p style=";text-align: justify;;text-indent: 0;white-space: normal;padding: 0;">
<span style=";"><strong><span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;"><span style="; ">逄天舒</span>&nbsp;Angelina Pang.&nbsp;</span></strong></span>
</p>
<p style=";text-align: justify;;text-indent: 0;white-space: normal;padding: 0;">
<span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">天舒老师在国际形象礼仪N+1课程创研中做出了巨大贡献，是国际形象礼仪文化交流项目发起人、IBIE协会理事长、国际双语讲师、美国加州Pomona大学受邀访问学者，从事多年礼仪培训及形象管理行业，一直热爱钻研形象礼仪领域，为诸多500强企业及大学提供专业的国际形象礼仪培训课程。擅长针对行业定制礼仪课程。</span>
</p>
<p style=";;text-indent: 0;white-space: normal;padding: 0;">
<span style=" letter-spacing: 0px; ;">International Image Etiquette N+1 Course founder.&nbsp;Founder of the International Image Etiquette Cultural Exchange Project.&nbsp;International bilingual lecturer. Pomona University, California, USA,&nbsp;was invited to visit scholars, engaged in many years of etiquette training and image management industry, has been keen to study the field of image etiquette, for many enterprises and universities to provide professional international image etiquette training courses. Good at customizing etiquette courses for the industry.</span>
</p>
<p style=";text-align: justify;;text-indent: 0;white-space: normal;padding: 0;">
<span style=" letter-spacing: 0px; ;">&nbsp;</span>
</p>
<p style=";;text-indent: 0;white-space: normal;padding: 0;">
<span style=";"><strong><span style=" letter-spacing: 0px; ;"><span style="; ">八、</span>IBIE CAMC课程结构组成&nbsp;IBIE CAMC curriculum structure composition</span></strong></span>
</p>
<p style=";;text-indent: 0;white-space: normal;padding: 0;">
<span style=" letter-spacing: 0px; ;">本课程共设置三天2晚&nbsp;This course is set up for 3&nbsp;days and 2 nights</span>
</p>
<p style=";;text-indent: 0;white-space: normal;padding: 0;">
<span style=";"><strong><span style=" letter-spacing: 0px; ;">DAY 1：</span></strong></span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" letter-spacing: 0px; ; ;">礼仪培训行业数据分析/未来前景预测（数据）//Etiquette Training Industry Data Analysis/Future Prospects Forecast (Data)</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
<span style=" ;">&nbsp;</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" letter-spacing: 0px; ; ;">国际形象礼仪培训机构成立条件及标准解析&nbsp;//The establishment conditions and standards of international image etiquette training institutions</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
<span style=" letter-spacing: 0px; ; ;">讲师分级管理//Instructor Rating Management</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
<span style=" letter-spacing: 0px; ; ;">行业价格标准定制分析&nbsp;//Industry Price Standard Custom Analysis</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" letter-spacing: 0px; ; ;">企业培训调研报告的构成&nbsp;//The composition of the enterprise training research report</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" letter-spacing: 0px; ; ;">谈判流程及技巧//&nbsp;Negotiation process and skills</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" letter-spacing: 0px; ; ;">企业培训大纲制作十步法&nbsp;//Ten-step approach to enterprise training outline production</span>
</p>
<p style=";;text-indent: 0;white-space: normal;padding: 0;">
<span style=" letter-spacing: 0px; ;">晚间：Night</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" letter-spacing: 0px; ; ;">方案制作及案例展示解析&nbsp;//Program production and case presentation analysis</span>
</p>
<p style=";;text-indent: 0;white-space: normal;padding: 0;">
<span style=";"><strong><span style=" letter-spacing: 0px; ;">DAY 2：</span></strong></span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
<span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ; ;">谈判话术技巧//Negotiating skills</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
<span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ; ;">收费模板及合作方式解析//Analysis of fee templates and cooperation methods</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" letter-spacing: 0px; ; ;">国际形象礼仪师培训PPT制作方法及常用模板//International image etiquette teacher training PPT production methods and common templates</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" letter-spacing: 0px; ; ;">PPT八大构成//Eight&nbsp;Compositions&nbsp;of&nbsp;PPT</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" letter-spacing: 0px; ; ;">KISS原则//KISS&nbsp;Rule</span>
</p>
<p style=";;text-indent: 0;white-space: normal;padding: 0;">
<span style=" letter-spacing: 0px; ;">晚间：Night</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" letter-spacing: 0px; ; ;">PPT制作及案例展示解析//PPT production and case presentation analysis</span>
</p>
<p style=";;text-indent: 0;white-space: normal;padding: 0;">
<span style=";"><strong><span style=" letter-spacing: 0px; ;">DAY 3：</span></strong></span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" letter-spacing: 0px; ; ;">四大类人群定向分析//Directional analysis of four categories of population</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" letter-spacing: 0px; ; ;">四大巨头行业定制课程惯性解析//The inertia analysis of customized courses in the head&nbsp;four industries</span>
</p>
<p style=";;white-space: normal;padding: 0">
<span style=" letter-spacing: 0px; ; ;">IBIE&nbsp;CAMC&nbsp;机构标准考核//IBIE CAMC Institutional Standard Assessmen</span>
</p>
<p style=";text-align: center;;text-indent: 0;white-space: normal;padding: 0;">
<span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">&nbsp;</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
<img src="https://www.yjyculture.com/commonimg/base/4_8.png" word_img="file:////private/var/folders/1d/_3t9lyws27qbmz0mkbtfbbh00000gn/T/com.kingsoft.wpsoffice.mac/wps-chutingting/ksohtml/wpsTtQDqN.png" style="background:url(/static/plugins/UEditor/lang/zh-cn/images/localimage.png) no-repeat center center;border:1px solid #ddd"/><span style=" ;">&nbsp;</span>
</p>
<p style=";text-align: center;;text-indent: 0;white-space: normal;padding: 0;">
<span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">&nbsp;</span>
</p>
<p style=";;text-indent: 0;white-space: normal;padding: 0;">
<span style=";"><strong><span style=" letter-spacing: 0px; ;"><span style="; ">十、</span>IBIE CAMC课程开课日期及费用</span></strong></span>
</p>
<table cellspacing="0" width="363" style='margin: 0 auto'>
<tbody>
    <tr class="firstRow">
        <td width="190" valign="bottom" colspan="4" style="padding: 3px 7px; border-width: 1px; background: rgb(252, 252, 252);">
            <p style=";text-align: center;">
                <span style=";"><strong><span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">2020年IBIE CAMC国际形象礼仪</span></strong></span>
            </p>
            <p style=";text-align: center;">
                <span style=";"><strong><span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ; ;">&nbsp; 机构城市联盟标准测评研修班排课表</span></strong></span>
            </p>
        </td>
    </tr>
    <tr>
        <td width="32" valign="bottom" style="padding: 3px 7px; border-left-width: 1px; border-right-width: 1px; border-top: none; border-bottom-width: 1px; background: rgb(247, 250, 255);">
            <p style=";text-align: center;;text-indent: 0">
                <span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">1月</span>
            </p>
        </td>
        <td width="45" valign="bottom" style="padding: 3px 7px; border-left: none; border-right-width: 1px; border-top-width: 1px; border-bottom-width: 1px; background: rgb(247, 250, 255);">
            <p style=";text-align: center;;text-indent: 0">
                <span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">14--16日</span>
            </p>
        </td>
        <td width="28" valign="bottom" style="padding: 3px 7px; border-left: none; border-right-width: 1px; border-top-width: 1px; border-bottom-width: 1px; background: rgb(247, 250, 255);">
            <p style=";text-align: center;;text-indent: 0">
                <span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">2月</span>
            </p>
        </td>
        <td width="51" valign="bottom" style="padding: 3px 7px; border-left: none; border-right-width: 1px; border-top-width: 1px; border-bottom-width: 1px; background: rgb(247, 250, 255);">
            <p style=";text-align: center;;text-indent: 0">
                <span style=" color: rgb(171, 25, 66); letter-spacing: 0px; ;">不开课</span>
            </p>
        </td>
    </tr>
    <tr>
        <td width="33" valign="bottom" style="padding: 3px 7px; border-left-width: 1px; border-right-width: 1px; border-top: none; border-bottom-width: 1px; background: rgb(252, 252, 252);">
            <p style=";text-align: center;;text-indent: 0">
                <span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">3月</span>
            </p>
        </td>
        <td width="45" valign="bottom" style="padding: 3px 7px; border-left: none; border-right-width: 1px; border-top: none; border-bottom-width: 1px; background: rgb(252, 252, 252);">
            <p style=";text-align: center;;text-indent: 0">
                <span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">疫情期</span>
            </p>
        </td>
        <td width="28" valign="bottom" style="padding: 3px 7px; border-left: none; border-right-width: 1px; border-top: none; border-bottom-width: 1px; background: rgb(252, 252, 252);">
            <p style=";text-align: center;;text-indent: 0">
                <span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">4月</span>
            </p>
        </td>
        <td width="51" valign="bottom" style="padding: 3px 7px; border-left: none; border-right-width: 1px; border-top: none; border-bottom-width: 1px; background: rgb(252, 252, 252);">
            <p style=";text-align: center;;text-indent: 0">
                <span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">疫情期</span>
            </p>
        </td>
    </tr>
    <tr>
        <td width="33" valign="bottom" style="padding: 3px 7px; border-left-width: 1px; border-right-width: 1px; border-top: none; border-bottom-width: 1px; background: rgb(247, 250, 255);">
            <p style=";text-align: center;;text-indent: 0">
                <span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">5月</span>
            </p>
        </td>
        <td width="45" valign="bottom" style="padding: 3px 7px; border-left: none; border-right-width: 1px; border-top: none; border-bottom-width: 1px; background: rgb(247, 250, 255);">
            <p style=";text-align: center;;text-indent: 0">
                <span style=" color: rgb(51, 51, 51); letter-spacing: 0px; background: rgb(252, 252, 252); ;">疫情期</span>
            </p>
        </td>
        <td width="28" valign="bottom" style="padding: 3px 7px; border-left: none; border-right-width: 1px; border-top: none; border-bottom-width: 1px; background: rgb(247, 250, 255);">
            <p style=";text-align: center;;text-indent: 0">
                <span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">6月</span>
            </p>
        </td>
        <td width="51" valign="bottom" style="padding: 3px 7px; border-left: none; border-right-width: 1px; border-top: none; border-bottom-width: 1px; background: rgb(247, 250, 255);">
            <p style=";text-align: center;;text-indent: 0">
                <span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">5--7日</span>
            </p>
        </td>
    </tr>
    <tr>
        <td width="33" valign="bottom" style="padding: 3px 7px; border-left-width: 1px; border-right-width: 1px; border-top: none; border-bottom-width: 1px; background: rgb(252, 252, 252);">
            <p style=";text-align: center;;text-indent: 0">
                <span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">7月</span>
            </p>
        </td>
        <td width="45" valign="bottom" style="padding: 3px 7px; border-left: none; border-right-width: 1px; border-top: none; border-bottom-width: 1px; background: rgb(252, 252, 252);">
            <p style=";text-align: center;;text-indent: 0">
                <span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">3--5日</span>
            </p>
        </td>
        <td width="28" valign="bottom" style="padding: 3px 7px; border-left: none; border-right-width: 1px; border-top: none; border-bottom-width: 1px; background: rgb(252, 252, 252);">
            <p style=";text-align: center;;text-indent: 0">
                <span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">8月</span>
            </p>
        </td>
        <td width="51" valign="bottom" style="padding: 3px 7px; border-left: none; border-right-width: 1px; border-top: none; border-bottom-width: 1px; background: rgb(252, 252, 252);">
            <p style=";text-align: center;;text-indent: 0">
                <span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">7--9日</span>
            </p>
        </td>
    </tr>
    <tr>
        <td width="12" valign="bottom" style="padding: 3px 7px; border-left-width: 1px; border-right-width: 1px; border-top: none; border-bottom-width: 1px; background: rgb(247, 250, 255);">
            <p style=";text-align: center;;text-indent: 0">
                <span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">9月</span>
            </p>
        </td>
        <td width="45" valign="bottom" style="padding: 3px 7px; border-left: none; border-right-width: 1px; border-top: none; border-bottom-width: 1px; background: rgb(247, 250, 255);">
            <p style=";text-align: center;;text-indent: 0">
                <span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">4--6日</span>
            </p>
        </td>
        <td width="28" valign="bottom" style="padding: 3px 7px; border-left: none; border-right-width: 1px; border-top: none; border-bottom-width: 1px; background: rgb(247, 250, 255);">
            <p style=";text-align: center;;text-indent: 0">
                <span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">10月</span>
            </p>
        </td>
        <td width="51" valign="bottom" style="padding: 3px 7px; border-left: none; border-right-width: 1px; border-top: none; border-bottom-width: 1px; background: rgb(247, 250, 255);">
            <p style=";text-align: center;;text-indent: 0">
                <span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">2--4日</span>
            </p>
        </td>
    </tr>
    <tr>
        <td width="12" valign="bottom" style="padding: 3px 7px; border-left-width: 1px; border-right-width: 1px; border-top: none; border-bottom-width: 1px; background: rgb(252, 252, 252);">
            <p style=";text-align: center;;text-indent: 0">
                <span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">11月</span>
            </p>
        </td>
        <td width="45" valign="bottom" style="padding: 3px 7px; border-left: none; border-right-width: 1px; border-top: none; border-bottom-width: 1px; background: rgb(252, 252, 252);">
            <p style=";text-align: center;;text-indent: 0">
                <span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">6--8日</span>
            </p>
        </td>
        <td width="28" valign="bottom" style="padding: 3px 7px; border-left: none; border-right-width: 1px; border-top: none; border-bottom-width: 1px; background: rgb(252, 252, 252);">
            <p style=";text-align: center;;text-indent: 0">
                <span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">12月</span>
            </p>
        </td>
        <td width="51" valign="bottom" style="padding: 3px 7px; border-left: none; border-right-width: 1px; border-top: none; border-bottom-width: 1px; background: rgb(252, 252, 252);">
            <p style=";text-align: center;;text-indent: 0">
                <span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">4--6日</span>
            </p>
        </td>
    </tr>
</tbody>
</table>
<p style=";;text-indent: 0;white-space: normal;padding: 0;">
<span style=" letter-spacing: 0px; ;">&nbsp;</span>
</p>
<p style=";text-align: justify;;text-indent: 0;white-space: normal;padding: 0;">
<span style=";"><span style=" letter-spacing: 0px; ;">IBIE CAMC课程费</span><strong><span style=" letter-spacing: 0px; ;">&nbsp;</span></strong><strong><span style=" letter-spacing: 0px; ;">¥15980&nbsp;/人</span></strong></span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
<span style=" letter-spacing: 0px; ; ;">优秀结业者直接签约北京瑜璟缘国际文化发展有限公司成为行业领先企业签约讲师；</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
<span style=" letter-spacing: 0px; ; ;">可获得IBIE国际形象礼仪组织协会颁发国际形象礼仪机构标准测评许可执照</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
<span style=" letter-spacing: 0px; ; ;">可获得IBIE国际形象礼仪城市联盟成员企业督导员认证</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
<span style=" letter-spacing: 0px; ; ;">可获得申请IBIE国际形象礼仪组织协会城市分会唯一资格</span>
</p>
<p style=";text-align: justify;;text-indent: 0;white-space: normal;padding: 0;">
<span style=" letter-spacing: 0px; ;">赠送：全部价值¥200000+资料包（可单独购买价格¥15980包含多年来我们给企业做的大纲、ppt、协议、合同、方案、会议纪要等）以及后续复听名额。</span>
</p>
<p style=";;text-indent: 0;white-space: normal;padding: 0;">
<span style=";"><strong><span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">IBIE CAMC Course Fee ¥15980 / person</span></strong></span>
</p>
<p style=";;text-indent: 0;white-space: normal;padding: 0;">
<span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">Excellent finishers can directly sign Beijing YJY&nbsp;International Cultural Development Co., Ltd. to become the industry&#39;s leading enterprises to sign lecturers;</span>
</p>
<p style=";;text-indent: 0;white-space: normal;padding: 0;">
<span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">IBIE International Business&nbsp;Image Etiquette Organizing Committee&nbsp;issued by the International Image Etiquette Agency Standard Assessment License</span>
</p>
<p style=";;text-indent: 0;white-space: normal;padding: 0;">
<span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">IBIE International Business&nbsp;Image Etiquette City Alliance member corporate supervisor certification Can apply for IBIE International Image Etiquette Organizing Committee City Chapter Only Qualification</span>
</p>
<p style=";;text-indent: 0;white-space: normal;padding: 0;">
<span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">Gift: All value of the 200,000 plus information package (can be purchased separately at a price of 6000 packages of letters over the years we have made to the enterprise outline, ppt, agreement, contract, program, meeting minutes, etc.) as well as follow-up listening places.</span>
</p>
<p style=";;text-indent: 0;white-space: normal;padding: 0;">
<span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">&nbsp;</span>
</p>
<p style=";text-align: center;;text-indent: 0;white-space: normal;padding: 0;">
<img  src="https://www.yjyculture.com/commonimg/base/4_9.png" word_img="file:////private/var/folders/1d/_3t9lyws27qbmz0mkbtfbbh00000gn/T/com.kingsoft.wpsoffice.mac/wps-chutingting/ksohtml/wpsMwwoc7.png" style="background:url(/static/plugins/UEditor/lang/zh-cn/images/localimage.png) no-repeat center center;border:1px solid #ddd"/><span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">&nbsp;</span>
</p>
<p style=";text-align: justify;;text-indent: 0;white-space: normal;padding: 0;">
<span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">&nbsp;</span>
</p>
<p style=";text-align: justify;;text-indent: 0;white-space: normal;padding: 0;">
<span style=";"><strong><span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;"><span style="; ">报名流程：</span>Registration process:</span></strong></span>
</p>
<p style=";text-align: justify;;text-indent: 0;white-space: normal;padding: 0;">
<span style=";"><span style="color: rgb(51, 51, 51); letter-spacing: 0px; ; ">请联系助理，加微信：</span><span style=" letter-spacing: 0px; ;">IBIE_BJ_YJYFUTURE</span></span>
</p>
<p style=";text-align: justify;;text-indent: 0;white-space: normal;padding: 0;">
<span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">提交资料为：</span>
</p>
<p style=";;text-indent: 0;white-space: normal;padding: 0;">
<span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">Please contact the Assistant&nbsp;to submit the information for:</span>
</p>
<p style=";text-align: justify;;text-indent: 0;white-space: normal;padding: 0;">
<span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">1、姓名 Name</span>
</p>
<p style=";text-align: justify;;text-indent: 0;white-space: normal;padding: 0;">
<span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">2、联系电话&nbsp;Tel</span>
</p>
<p style=";text-align: justify;;text-indent: 0;white-space: normal;padding: 0;">
<span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">3、身份证号码、常用邮箱&nbsp;ID number, common mailbox</span>
</p>
<p style=";;text-indent: 0;white-space: normal;padding: 0;">
<span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">4、两寸白底证件照&nbsp;Two-inch white-bottomed document photo</span>
</p>
<p style=";text-align: justify;;text-indent: 0;white-space: normal;padding: 0;">
<span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">&nbsp; &nbsp; &nbsp;支付定金￥5，000人民币&nbsp;</span>
</p>
<p style=";text-align: justify;;text-indent: 0;white-space: normal;padding: 0;">
<span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">&nbsp; &nbsp; &nbsp;Payment of a deposit of RMB 5,000</span>
</p>
<p style=";text-align: justify;;text-indent: 0;white-space: normal;padding: 0;">
<span style="color: rgb(51, 51, 51); letter-spacing: 0px; ; ">报名成功，助理发送电子版报名信息档案，填写回发助理老师保存入档。</span>
</p>
<p style=";text-align: justify;;text-indent: 0;white-space: normal;padding: 0;">
<span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">Successful registration, assistant&nbsp;sent an electronic version of the registration information file, fill in the return assistant&nbsp;saved into the file.</span>
</p>
<p style=";text-align: justify;;text-indent: 0;white-space: normal;padding: 0;">
<span style=";"><strong><span style="color: rgb(51, 51, 51); letter-spacing: 0px; ; ">对公账户：</span></strong></span>
</p>
<p style=";text-align: justify;;text-indent: 0;white-space: normal;padding: 0;">
<span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">账号名称：北京瑜璟缘国际文化发展有限公司</span>
</p>
<p style=";text-align: justify;;text-indent: 0;white-space: normal;padding: 0;">
<span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">开户行：中国银行股份有限公司北京机场南路支行 &nbsp;</span>
</p>
<p style=";text-align: justify;;text-indent: 0;white-space: normal;padding: 0;">
<span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">对公账号：332467469772</span>
</p>
<p style=";text-align: justify;;text-indent: 0;white-space: normal;padding: 0;">
<span style=";"><strong><span style="color: rgb(51, 51, 51); letter-spacing: 0px; ; ">私人账户：</span></strong></span>
</p>
<p style=";text-align: justify;;text-indent: 0;white-space: normal;padding: 0;">
<span style=";"><strong><span style="color: rgb(51, 51, 51); letter-spacing: 0px; ; ">支付宝账号</span></strong><span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">13121112777，用户名，逄天舒。</span></span>
</p>
<p style=";;text-indent: 0;white-space: normal;padding: 0;">
<span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">For public accounts:</span>
</p>
<p style=";;text-indent: 0;white-space: normal;padding: 0;">
<span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">Account Name: Beijing YJY&nbsp;International Cultural Development Co., Ltd. &nbsp;</span>
</p>
<p style=";;text-indent: 0;white-space: normal;padding: 0;">
<span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">Bank of China: South Road Branch of Beijing Airport, Bank of China Co., Ltd.</span>
</p>
<p style=";;text-indent: 0;white-space: normal;padding: 0;">
<span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">For public account: 332467469772</span>
</p>
<p style=";text-align: center;;text-indent: 0;white-space: normal;padding: 0;">
<span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">For private accounts: Alipay account number 1312112777, username, Pang Tianshu.</span>
</p>
<p style=";text-align: center;;text-indent: 0;white-space: normal;padding: 0;">
<span style=" letter-spacing: 0px; ;">审美、创造</span>
</p>
<p style=";text-align: center;;text-indent: 0;white-space: normal;padding: 0;">
<span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">Aesthetics.&nbsp;Creation</span>
</p>
<p style=";text-align: center;;text-indent: 0;white-space: normal;padding: 0;">
<span style=" letter-spacing: 0px; ;">&nbsp;</span>
</p>
<p style=";text-align: center;;text-indent: 0;white-space: normal;padding: 0;">
<span style=" letter-spacing: 0px; ;">形象、礼仪</span>
</p>
<p style=";text-align: center;;text-indent: 0;white-space: normal;padding: 0;">
<span style=" letter-spacing: 0px; ;">Image. Etiquette</span>
</p>
<p style=";text-align: justify;;text-indent: 0;white-space: normal;padding: 0;">
<span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">&nbsp;</span>
</p>
<p style=";text-align: center;;text-indent: 0;white-space: normal;padding: 0;">
<img  src="https://www.yjyculture.com/commonimg/base/4_10.png" word_img="file:////private/var/folders/1d/_3t9lyws27qbmz0mkbtfbbh00000gn/T/com.kingsoft.wpsoffice.mac/wps-chutingting/ksohtml/wps26Z45A.png" style="background:url(/static/plugins/UEditor/lang/zh-cn/images/localimage.png) no-repeat center center;border:1px solid #ddd"/><span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">&nbsp;</span>
</p>
<p style=";text-align: justify;;text-indent: 0;white-space: normal;padding: 0;">
<span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">&nbsp;</span>
</p>
<p style=";text-align: center;;text-indent: 0;white-space: normal;padding: 0;">
<span style=" letter-spacing: 0px; ;">期待在IBIE</span>
</p>
<p style=";text-align: center;;text-indent: 0;white-space: normal;padding: 0;">
<span style=" letter-spacing: 0px; ;">Looking forward to the IBIE&nbsp;</span>
</p>
<p style=";text-align: center;;text-indent: 0;white-space: normal;padding: 0;">
<span style=" letter-spacing: 0px; ;">&nbsp;</span>
</p>
<p style=";text-align: center;;text-indent: 0;white-space: normal;padding: 0;">
<span style=" letter-spacing: 0px; ;">与你相遇</span>
</p>
<p style=";text-align: center;;text-indent: 0;white-space: normal;padding: 0;">
<span style=" letter-spacing: 0px; ;">Meet you</span>
</p>
<p style=";text-align: center;;text-indent: 0;white-space: normal;padding: 0;">
<span style=" letter-spacing: 0px; ;">&nbsp;</span>
</p>
<p style=";text-align: center;;text-indent: 0;white-space: normal;padding: 0;">
<span style=" letter-spacing: 0px; ;">&nbsp;</span>
</p>
<p style=";text-align: center;;text-indent: 0;white-space: normal;padding: 0;">
<span style=" color: rgb(123, 12, 0); letter-spacing: 0px; ;">IBIE国际形象礼仪所有课程均已申请版权保护，文字及图片不可复制盗版。我们崇尚知识分享，但不包括抄袭掠夺。商务合作请拨打电话：+8613121112777</span>
</p>
<p style=";text-align: center;;text-indent: 0;white-space: normal;padding: 0;">
<span style=" letter-spacing: 0px; ;">&nbsp;</span>
</p>

<p style=";text-align: center;;text-indent: 0;white-space: normal;padding: 0;">
<img  src="https://www.yjyculture.com/commonimg/base/4_12.png" word_img="file:////private/var/folders/1d/_3t9lyws27qbmz0mkbtfbbh00000gn/T/com.kingsoft.wpsoffice.mac/wps-chutingting/ksohtml/wpsAW0JBM.png" style="background:url(/static/plugins/UEditor/lang/zh-cn/images/localimage.png) no-repeat center center;border:1px solid #ddd"/><span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">&nbsp;</span>
</p>
<p style=";text-align: center;;text-indent: 0;white-space: normal;padding: 0;">
<span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;"><span style="; ">微信扫一扫</span><br/><span style="; ">添加助理微信</span></span>
</p>
<p style=";text-align: center;;text-indent: 0;white-space: normal;padding: 0;">
<span style=" letter-spacing: 0px; ;">&nbsp;</span>
</p>
<p style=";text-align: center;;text-indent: 0;white-space: normal;padding: 0;">
<img  src="https://www.yjyculture.com/commonimg/base/4_11.png" word_img="file:////private/var/folders/1d/_3t9lyws27qbmz0mkbtfbbh00000gn/T/com.kingsoft.wpsoffice.mac/wps-chutingting/ksohtml/wpsC87y3x.png" style="background:url(/static/plugins/UEditor/lang/zh-cn/images/localimage.png) no-repeat center center;border:1px solid #ddd"/><span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">&nbsp;</span>
</p>
<p style=";text-align: justify;;text-indent: 0;white-space: normal;padding: 0;">
<span style=" color: rgb(51, 51, 51); letter-spacing: 0px; ;">&nbsp;</span>
</p>
<p style=";text-align: justify;;white-space: normal">
<span style=" ;">&nbsp;</span>
</p>
<p>
<br/>
</p>`
,gjsw:`<h2 style="margin: 0 0 9px;;white-space: normal;padding: 0">
<strong><span style="color: rgb(51, 51, 51);letter-spacing: 0;">IBIE EBEE 线上赋能项目认证商务礼仪精英</span></strong>
</h2>
<p style=";text-align: justify;;white-space: normal;padding: 0">
<span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">在这个世界各国文化互相交流互相融合的时代，掌握国际形象礼仪标准已成为当今社会个人职业发展的重要内容，是用人单位考核员工综合职业素养和个人素质的重要参考依据，是对无论个人或者企业培养国际化、综合性人才需求的必要手段。你是否羡慕商务宴会场合侃侃而谈举止有仪又不失风度的职场精英，是否也想想他们一样掌握人生呢？那么现在机会来了，瑜璟缘国际礼仪为大家准备了</span>IBIE 线上赋能项目，以线上自学形式开展课程，通过考试将颁发认证《国际商务礼仪精英EBEE》证书，以下是IBIE 线上赋能项目认证商务礼仪精英 具体内容：</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
<strong><span style=";color: rgb(9, 66, 143);letter-spacing: 0;;">IBIE 线上赋能项目认证商务礼仪精英</span></strong>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
<strong><span style=";color: rgb(5, 45, 100);letter-spacing: 0;;"><span style="">（</span>EBEE）</span></strong>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
<strong><span style=";color: rgb(51, 51, 51);letter-spacing: 0;">Empower Business Etiquette Elite</span></strong>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
<span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">本项目为线上自学系统，参与学习的学员将自主学习</span>IBIE国际礼仪题库系列课程。</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
<span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">在完成</span>24节课即满足12课时后，申请线上考试。</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
<span style=";color: rgb(9, 66, 143);letter-spacing: 0;">&nbsp;</span>
</p>
<p style=";text-align: center;;text-indent: 0;white-space: normal;padding: 0;">
<img  src="https://www.yjyculture.com/commonimg/base/5_1.png" word_img="file:////private/var/folders/1d/_3t9lyws27qbmz0mkbtfbbh00000gn/T/com.kingsoft.wpsoffice.mac/wps-chutingting/ksohtml/wpsr5QzGi.png" style="background:url(/static/plugins/UEditor/lang/zh-cn/images/localimage.png) no-repeat center center;border:1px solid #ddd"/><span style=";color: rgb(51, 51, 51);letter-spacing: 0;">&nbsp;</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
<span style=";color: rgb(9, 66, 143);letter-spacing: 0;"><span style="">线上考试时长为</span>30分钟，共50道题目。题型为单选题（四选一）共100分满分。</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
<span style=";color: rgb(9, 66, 143);letter-spacing: 0;">&nbsp;</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
<span style=";color: rgb(9, 66, 143);letter-spacing: 0;"><span style="">分数达</span>85分（含）及以上者，颁发(IBIE)国际商务礼仪精英认证证书。</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
<span style=";color: rgb(9, 66, 143);letter-spacing: 0;">&nbsp;</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
<span style=";color: rgb(9, 66, 143);letter-spacing: 0;"><span style="">证书样本：</span></span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
<span style=";color: rgb(9, 66, 143);letter-spacing: 0;">&nbsp;</span>
</p>
<p style=";;white-space: normal;text-align: center;">
<img src="https://www.yjyculture.com/commonimg/base/5_2.png" word_img="file:////private/var/folders/1d/_3t9lyws27qbmz0mkbtfbbh00000gn/T/com.kingsoft.wpsoffice.mac/wps-chutingting/ksohtml/wps9S0Axb.png" style="background:url(/static/plugins/UEditor/lang/zh-cn/images/localimage.png) no-repeat center center;border:1px solid #ddd"/><span style="">&nbsp;</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
<span style=";color: rgb(9, 66, 143);letter-spacing: 0;"><span style="">需学习课程名录：</span></span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
<span style=";color: rgb(9, 66, 143);letter-spacing: 0;">1.&nbsp;《如何塑造良好的第一印象----解析55387定律》</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
<span style=";color: rgb(9, 66, 143);letter-spacing: 0;">2.&nbsp;《形象的易读性----以貌取人》</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
<span style=";color: rgb(9, 66, 143);letter-spacing: 0;">3.&nbsp;《职场发挥个人魅力，您需要做到这四点》</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
<span style=";color: rgb(9, 66, 143);letter-spacing: 0;">4.&nbsp;《请着正装的社交误读》</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
<span style=";color: rgb(9, 66, 143);letter-spacing: 0;">5.&nbsp;《为成功而穿Dress&nbsp;For&nbsp;Success》</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
<span style=";color: rgb(9, 66, 143);letter-spacing: 0;">6.&nbsp;《成功人士的衬衫》</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
<span style=";color: rgb(9, 66, 143);letter-spacing: 0;">7.&nbsp;《衬衫的礼仪级别》</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
<span style=";color: rgb(9, 66, 143);letter-spacing: 0;">8.&nbsp;《突然的自我如何表达》</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
<span style=";color: rgb(9, 66, 143);letter-spacing: 0;">9.&nbsp;《全球通行的黄金造型法则》</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
<span style=";color: rgb(9, 66, 143);letter-spacing: 0;">10.&nbsp;《中产阶层普修知识----西装的三剑客》</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
<span style=";color: rgb(9, 66, 143);letter-spacing: 0;">11.&nbsp;《个人形象在商务社交中的晕轮效应》</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
<span style=";color: rgb(9, 66, 143);letter-spacing: 0;">12.&nbsp;《绅士着装到TPOR计划》</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
<span style=";color: rgb(9, 66, 143);letter-spacing: 0;">13.&nbsp;《人际关系中情绪的自我管理》</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
<span style=";color: rgb(9, 66, 143);letter-spacing: 0;">14.&nbsp;《基于高低语境下的问候礼仪》</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
<span style=";color: rgb(9, 66, 143);letter-spacing: 0;">15.&nbsp;《世界奢侈品鉴赏》</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
<span style=";color: rgb(9, 66, 143);letter-spacing: 0;">16.&nbsp;《香水礼仪》</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
<span style=";color: rgb(9, 66, 143);letter-spacing: 0;">17.&nbsp;《敬酒礼仪》</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
<span style=";color: rgb(9, 66, 143);letter-spacing: 0;">18.&nbsp;《西餐餐桌礼仪与文化》</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
<span style=";color: rgb(9, 66, 143);letter-spacing: 0;">19.&nbsp;《咖啡礼仪》</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
<span style=";color: rgb(9, 66, 143);letter-spacing: 0;">20.&nbsp;《礼品礼仪》</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
<span style=";color: rgb(9, 66, 143);letter-spacing: 0;">21.&nbsp;《日常英语速成大法》</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
<span style=";color: rgb(9, 66, 143);letter-spacing: 0;">22.&nbsp;《聚会宴请礼仪》</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
<span style=";color: rgb(9, 66, 143);letter-spacing: 0;">23.&nbsp;《好口才开挂智慧人生----第一次亲密接触篇》</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
<span style=";color: rgb(9, 66, 143);letter-spacing: 0;">24.&nbsp;《针对上司的漂亮话和口才艺术篇》</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
<span style=";color: rgb(9, 66, 143);letter-spacing: 0;">25.&nbsp;《与同事相处融洽的说话艺术篇》</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
<span style=";color: rgb(9, 66, 143);letter-spacing: 0;">26.&nbsp;《商务社交篇之一》</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
<span style=";color: rgb(9, 66, 143);letter-spacing: 0;">27.&nbsp;《得到异性青睐的说话艺术》</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
<span style=";color: rgb(9, 66, 143);letter-spacing: 0;">28.&nbsp;《有效说话的根本原则》</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
<span style=";color: rgb(9, 66, 143);letter-spacing: 0;"><span style="">考试题目将围绕以上课题展开。</span></span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
<span style=";color: rgb(9, 66, 143);letter-spacing: 0;">&nbsp;</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
<span style=";letter-spacing: 0;"><span style="">该项目为不能及时进行线下学习的朋友解决了学习路程远、费用高、时间不足、单位没假等人人都会遇到的实际问题。</span></span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
<span style=";letter-spacing: 0;"><span style="">课程设计结构饱满，涵盖国际商务知识，部分课题为</span></span><span style=";color: rgb(2, 30, 170);letter-spacing: 0;"><span style="">哈佛决策课程体系</span></span><span style=";letter-spacing: 0;"><span style="">分支以及</span></span><span style=";color: rgb(2, 30, 170);letter-spacing: 0;"><span style="">国际商务形象礼仪标准</span></span><span style=";letter-spacing: 0;"><span style="">体系课程。作为企业白领的必修课，为步入国际商务精英队列快马加鞭，为提升个人社交软实力锦上添花，更是提高个人能力的官方背书。</span></span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
<span style=";letter-spacing: 0;">&nbsp;</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
<span style=";letter-spacing: 0;"><span style="">申请证书费用：￥</span>2680/人。（课程费、报考费、证书授权费）</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
<span style=";letter-spacing: 0;"><span style="">报考方式：自主报考，登录网站</span>www.ibie.org.cn</span>
或 扫描下方工作人员二维码
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
<span style=";letter-spacing: 0;">&nbsp;</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
<span><img  src="https://www.yjyculture.com/commonimg/base/5_3.png" word_img="file:////private/var/folders/1d/_3t9lyws27qbmz0mkbtfbbh00000gn/T/com.kingsoft.wpsoffice.mac/wps-chutingting/ksohtml/wpsdPUUlb.jpg" style="background:url(/static/plugins/UEditor/lang/zh-cn/images/localimage.png) no-repeat center center;border:1px solid #ddd"/></span><span style="">&nbsp;</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
<span style=";color: rgb(9, 66, 143);letter-spacing: 0;"><span style="">获取考试资料和更多信息</span></span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
<span style=";color: rgb(51, 51, 51);letter-spacing: 0;;">IBIE「INTERNATIONAL BUSINESS IMAGE ETIQUETTE」STANDARDS &amp; EVALUATION</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
<span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">国际商务形象礼仪标准与测评。由中国北京形象和礼仪专业领域内专家及老师、北京邮电大学、对外经济贸易大学、首都师范大学、东北大学、美国加州大学洛杉矶分校社科院专家学者、美国加州州立理工</span></span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
<span style=";color: rgb(51, 51, 51);letter-spacing: 0;"><span style="">大学波莫纳分校及社会各界对该领域抱有兴趣的有识之士共同创建的</span>NPO 无盈利性质组织协会。IBIE 在中国（国际形象礼仪组织协会·&nbsp;中国北京）由北京瑜璟缘国际文化发展有限公司组织协调，在美国由加州州立理工大学延展学院联合国际形象顾问组织协会，共同组织和发起的公益性协会组织。主要覆盖范围包括培训、在线学习和考试等职业软技能测评工作。</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
<span style="">&nbsp;</span>
</p>
<p style=";text-align: center;;white-space: normal;padding: 0">
<span style=";color: rgb(9, 66, 143);letter-spacing: 0;">IBIE国际形象礼仪组织协会</span>
</p>
<p style=";text-align: justify;;white-space: normal;padding: 0">
<span style=";letter-spacing: 0;">&nbsp;</span>
</p>
<p style=";text-align: center;;white-space: normal">
<img  src="https://www.yjyculture.com/commonimg/base/5_4.png" word_img="file:////private/var/folders/1d/_3t9lyws27qbmz0mkbtfbbh00000gn/T/com.kingsoft.wpsoffice.mac/wps-chutingting/ksohtml/wpsD8tLCI.jpg" style="background:url(/static/plugins/UEditor/lang/zh-cn/images/localimage.png) no-repeat center center;border:1px solid #ddd"/><span style="">&nbsp;</span>
</p>
<p>
<br/>
</p>`,
grly:``
}